import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

import person from "../../../assets/icons/person.svg";
import { spacings } from "../../../assets/styles/sizes";
import { useAppDispatch } from "../../../store";
import { fetchBalance } from "../../../store/walletSlice";
import { Button } from "../../atoms/Button";
import { FooterButtonWide } from "../../atoms/Button/Button.style";
import { Icon } from "../../atoms/Icon";
import * as S from "./RegisterButton.style";

interface RegisterButtonProps {
  wide?: boolean;
}

const RegisterButton = ({ wide }: RegisterButtonProps) => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation("general");
  const dispatch = useAppDispatch();

  const handleLogin = () => {
    loginWithRedirect().then(() => dispatch(fetchBalance()));
  };

  return (
    <>
      {wide ? (
        <FooterButtonWide onClick={handleLogin} id="login-button">
          <Icon
            src={person}
            $height="20px"
            $width="20px"
            $marginRight={spacings.xs}
            id="login-icon-button"
          />
          <S.ButtonWrapper wide>
            <S.ButtonLabelMain>{t("register")}</S.ButtonLabelMain>
            <S.ButtonLabelSecondary wide>{t("signIn")}</S.ButtonLabelSecondary>
          </S.ButtonWrapper>
        </FooterButtonWide>
      ) : (
        <Button onClick={handleLogin} id="login-button">
          <Icon
            src={person}
            $height="20px"
            $width="20px"
            $marginRight={spacings.xs}
            id="login-icon-button"
          />
          <S.ButtonWrapper>
            <S.ButtonLabelMain>{t("register")}</S.ButtonLabelMain>
            <S.ButtonLabelSecondary>{t("signIn")}</S.ButtonLabelSecondary>
          </S.ButtonWrapper>
        </Button>
      )}
    </>
  );
};

export { RegisterButton };
