import { useState } from "react";
import { useTranslation } from "react-i18next";

import closeIcon from "../../../assets/icons/close.svg";
import { Icon } from "../../atoms/Icon";
import * as S from "./InvitationModal.style";

const InvitationModal = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}) => {
  const { t } = useTranslation("invitationModal");
  const [buttonText, setButtonText] = useState(t("copyToClipboard"));

  const copyToClipboard = (text: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setButtonText(t("copied"));
      return navigator.clipboard.writeText(text);
    }
    return Promise.reject("The Clipboard API is not available.");
  };

  return (
    <S.InvitationWrapper>
      <S.CloseModalButton
        onClick={handleCloseModal}
        id="close-invitation-modal"
      >
        <Icon src={closeIcon} />
      </S.CloseModalButton>
      <S.Heading>{t("heading")}</S.Heading>
      <S.InvitationContent>{t("invitationModalText")}</S.InvitationContent>
      <S.CopyButton
        onClick={() => copyToClipboard(t("invitationModalText"))}
        id="copy-invitation-modal-text"
      >
        {buttonText}
      </S.CopyButton>
    </S.InvitationWrapper>
  );
};

export { InvitationModal };
