import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { GameDetails } from "../../../types/games";
import { GameStatusLabel } from "../../atoms/GameStatusLabel";
import { TeamTile } from "../../atoms/Team";
import { TvStationIcon } from "../../atoms/TvStationIcon";
import { Square } from "../../molecules/Square";
import * as S from "./GameDetailsModal.style";

const CanceledGameDetailsModal = ({
  game,
  isScrolled,
}: {
  game: GameDetails;
  isScrolled: boolean;
}) => {
  const { t } = useTranslation("game");

  const teamTileSize = isScrolled ? "small" : "medium";

  return (
    <>
      <S.GameDetails>
        <GameStatusLabel
          isGameModal
          status={game.status}
          quarter={game.quarter || "1"}
          timeLeft={game.timeLeft}
        />
        {!game.isLive && (
          <S.GameDetailsHeading>
            {format(new Date(game.startTime), "E M/dd")}
          </S.GameDetailsHeading>
        )}
        <S.GameSecondaryHeadingWrapper>
          <S.GameSecondaryHeading>
            {game.isLive && `${format(new Date(game.startTime), "E M/dd")} | `}
            {format(new Date(game.startTime), "p")}
          </S.GameSecondaryHeading>
          <S.GameDetailsWrapper>
            {game.tvStation &&
              (<TvStationIcon tvStation={game.tvStation} /> || (
                <S.GameSecondaryHeading>
                  {game.tvStation}
                </S.GameSecondaryHeading>
              ))}
            <S.StadiumDetails>
              {game.stadium && game.tvStation && ` | `}
              {game.stadium}
            </S.StadiumDetails>
          </S.GameDetailsWrapper>
        </S.GameSecondaryHeadingWrapper>
      </S.GameDetails>
      <S.SectionWrapper $scoresDisplayed={false}>
        <TeamTile
          $size={teamTileSize}
          team={game.teamHome}
          $scoresDisplayed={false}
        />
        <TeamTile
          $size={teamTileSize}
          team={game.teamAway}
          $scoresDisplayed={false}
          $secondTile
        />
        <S.VersusLabel>vs</S.VersusLabel>
      </S.SectionWrapper>
      {game.hasBets && (
        <S.SquaresSection>
          <S.SquaresHeading>
            <S.SquaresTitle>
              {t("mySquares")}: {game.bets.length}
            </S.SquaresTitle>
          </S.SquaresHeading>
          {game.betsSorted.map((bet) => (
            <Square
              key={bet.id}
              id={bet.id}
              gameStatus={game.status}
              teamHome={game.teamHome}
              teamAway={game.teamAway}
              bet={bet}
              quarter={game.quarter || "1"}
            />
          ))}
        </S.SquaresSection>
      )}
    </>
  );
};

export { CanceledGameDetailsModal };
