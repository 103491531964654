import styled from "styled-components";

import { boardSquareColors, blue100 } from "../../../assets/styles/colors";
import { H3Decor, H4 } from "../../atoms/Typography";
import {
  borderRadiuses,
  elementSizes,
  spacings,
} from "../../../assets/styles/sizes";
import {
  FooterButton,
  FooterButtonWide,
} from "../../atoms/Button/Button.style";

const BoardLegend = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, min-content);
  grid-gap: ${spacings.m};
  margin-bottom: ${spacings.m};
  width: 100%;
`;

const BoardLegendItem = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  padding: ${spacings.xxs};
  align-items: center;
  justify-content: center;
  border: 1px solid ${blue100};
  border-radius: ${borderRadiuses.xs};
`;

const BoardLegendItemColor = styled.div<{ color: string }>`
  width: 2rem;
  height: 2rem;
  border-radius: ${borderRadiuses.s};
  background-color: ${({ color }) => color};
  margin-right: ${spacings.xs};
`;

const BoardLegendItemLabel = styled.div<{ color: string }>`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ color }) => color};
  text-transform: uppercase;
  text-align: center;
  padding: 0 ${spacings.xxxs};
`;

export {
  BoardLegend,
  BoardLegendItem,
  BoardLegendItemColor,
  BoardLegendItemLabel,
};
