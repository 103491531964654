import { ReactNode } from "react";

import check from "../../../assets/icons/check-circle.svg";
import { Icon } from "../Icon";
import * as S from "./Button.style";

interface ButtonProps {
  onClick: () => void;
  children: string | JSX.Element | (JSX.Element | string)[];
  id: string;
}

const Button = ({ children, onClick, id }: ButtonProps) => {
  return (
    <S.Button onClick={onClick} type="button" id={id}>
      {children}
    </S.Button>
  );
};

const OutlinedButton = ({
  active,
  onClick,
  topButton,
  children,
  id,
}: {
  active: boolean;
  onClick?: () => void;
  children?: ReactNode | ReactNode[];
  topButton?: boolean;
  id: string;
}) => {
  return (
    <S.OutlinedButton
      onClick={onClick}
      active={active}
      topButton={topButton}
      id={id}
    >
      {children}
      {active && <Icon src={check} />}
    </S.OutlinedButton>
  );
};

export { Button, OutlinedButton };
export type { ButtonProps };
