import styled, { css } from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { borderRadiuses, spacings } from "../../../assets/styles/sizes";
import { H3Decor, H5, H5Decor } from "../../atoms/Typography";

const SquareInfoWrapper = styled.div`
  min-height: 74px;
  background-color: ${palette.white};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid ${palette.grey400};
  border-top-left-radius: ${borderRadiuses.sm};
  border-top-right-radius: ${borderRadiuses.sm};
  border-bottom: 1px solid ${palette.grey400};
  border-bottom-left-radius: ${borderRadiuses.sm};
  border-bottom-right-radius: ${borderRadiuses.sm};

  &:not(:first-child) {
    margin-top: ${spacings.xxs};
  }
`;

const HidableSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const SquareSection = styled.div<{ revealed?: boolean }>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: minmax(0, 1fr);
  text-align: center;
  padding: ${spacings.s};
  border-right: 1px solid ${palette.grey400};
`;

const SquareValueSection = styled.div`
  border-right: 1px solid ${palette.grey400};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SquareLabel = styled(H5)`
  color: ${palette.grey700};
  text-transform: uppercase;
  font-weight: 400;
  margin: ${spacings.xxxs};
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 0;
  grid-row-end: 1;
`;

const SquareValue = styled(H3Decor)`
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
  min-height: 30px;
`;

const WrapperToReveal = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
`;

const SquareNotRevealed = styled.div<{ triggerReveal: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${palette.gradientBlue};
  border-left: none;
  border-bottom-left-radius: ${borderRadiuses.s};
  border-top-left-radius: ${borderRadiuses.s};
  display: grid;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 4;
  backface-visibility: hidden;

  ${(p) =>
    p.triggerReveal &&
    css`
      animation: scale-out 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      @-webkit-keyframes scale-out {
        0% {
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;
        }
      }
      @keyframes scale-out {
        0% {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;
        }
        100% {
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
          opacity: 1;
        }
      }
    `}
`;

const SquareNotRevealedLabel = styled(H5Decor)`
  width: 100%;
  color: ${palette.white};
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const SemiCircle = styled.div`
  position: absolute;
  top: 21px;
  width: 13px;
  height: 26px;
  background-color: ${palette.white};
  border-radius: 0 13px 13px 0;
`;

export {
  SquareInfoWrapper,
  SquareSection,
  SquareLabel,
  SquareValue,
  HidableSection,
  WrapperToReveal,
  SquareNotRevealed,
  SquareNotRevealedLabel,
  SemiCircle,
  SquareValueSection,
};
