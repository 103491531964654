import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router";
import { useTranslation } from "react-i18next";

import { LoadingAnimation } from "../components/atoms/LoadingAnimation";

interface ProtectedRouteProps {
  component: () => JSX.Element;
}

const PrivateRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
}) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const { t } = useTranslation("general");

  if (isLoading) {
    return <LoadingAnimation label={t("loading")} fullScreen />;
  }

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export { PrivateRoute };
