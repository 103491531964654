import { H2Decor } from "../Typography";
import * as S from "./Ticket.style";

interface TicketProps {
  amountFormatted: string;
  label: string;
  $horizontal?: boolean;
  $small?: boolean;
}

const Ticket = ({
  amountFormatted,
  label,
  $horizontal,
  $small,
}: TicketProps) => {
  return (
    <S.TicketWrapper>
      <S.Shape>
        <S.Top />
        <S.Rip />
        <S.Bottom />
      </S.Shape>

      <S.Content $horizontal={$horizontal}>
        <S.Amount $small={$small}>{amountFormatted}</S.Amount>
        <S.Label $horizontal={$horizontal}>{label}</S.Label>
      </S.Content>
    </S.TicketWrapper>
  );
};

export { Ticket };
