import { Auth0Provider } from "@auth0/auth0-react";
import { Provider } from "react-redux";

import { AppRoutes } from "./AppRoutes";
import store from "./store";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <Auth0Provider
          domain={import.meta.env.VITE_AUTH_DOMAIN}
          clientId={import.meta.env.VITE_AUTH_CLIENT_ID}
          authorizationParams={{
            redirect_uri: import.meta.env.VITE_APP_DOMAIN,
            audience: import.meta.env.VITE_AUTH_AUDIENCE,
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <AppRoutes />
        </Auth0Provider>
      </Provider>
    </>
  );
};

export default App;
