import styled from "styled-components";

import { IconColorProps } from "./IconColor";

const Icon = styled.div<IconColorProps>`
  width: ${(props) => props.$size || props.$width};
  height: ${(props) => props.$size || props.$height};
  margin-left: ${(props) => props.$marginLeft || 0};
  margin-right: ${(props) => props.$marginRight || 0};
  background-color: ${(props) => props.color};
  mask: url(${(p) => p.src});
  mask-size: 100% 100%;
`;

export { Icon };
