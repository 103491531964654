interface AppRoutes {
  readonly games: string;
  readonly login: string;
  readonly transactions: string;
  readonly buySquare: string;
  readonly myProfile: string;
}

export const ROUTES: AppRoutes = {
  games: "/",
  login: "/login",
  transactions: "/transactions",
  buySquare: "/buy-square",
  myProfile: "/my-profile",
};
