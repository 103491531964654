import styled from "styled-components";

import prize from "../../../assets/icons/prize.png";
import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  spacings,
} from "../../../assets/styles/sizes";
import { FooterButtonWide } from "../../atoms/Button/Button.style";
import { H2Decor, H3, H3Decor, H4, H4Decor } from "../../atoms/Typography";
import { Footer } from "../../molecules/Modal/Modal.style";

const backgroundPrizeSection = new URL(
  "../../../assets/icons/prize-background.png",
  import.meta.url
).href;

const ModalWrapper = styled.div<{ hasBets: boolean }>`
  background-color: ${({ hasBets }) =>
    hasBets ? palette.grey300 : palette.white};
  height: 100%;
  overflow: scroll;
  width: 100vw;
  display: grid;
  align-content: flex-start;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  margin-top: ${spacings.sm};
`;

const GameDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${palette.white};
`;

const GameDetailsHeading = styled(H4Decor)`
  font-size: ${fontSizes.l};
  margin-top: ${spacings.s};
`;

const GameSecondaryHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${spacings.s};
`;

const GameSecondaryHeading = styled(H3)`
  text-transform: capitalize;

  &:first-child {
    padding-bottom: ${spacings.xs};
  }
`;

const GameDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StadiumDetails = styled(GameSecondaryHeading)`
  margin-left: ${spacings.xxs};
`;

const HorizontalPadding = styled.div`
  background-color: ${palette.white};
  padding: ${spacings.m} ${spacings.xs} ${spacings.m} ${spacings.xs};
`;

const SectionWrapper = styled.div<{ $scoresDisplayed?: boolean }>`
  display: flex;
  position: ${(props) => (props.$scoresDisplayed ? "sticky" : "relative")};
  top: 0;
  z-index: 2;
  padding: ${spacings.m} ${spacings.xs} 0 ${spacings.xs};
  background-color: ${palette.white};
`;

const TotalPayoutWrapper = styled.div`
  padding: ${spacings.xxxs} ${spacings.s} ${spacings.m} ${spacings.s};
  background-color: ${palette.white};
`;

const VersusLabel = styled(H3Decor)`
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 40px);
  background-color: ${palette.white};
  padding: 10px 30px;
  border-radius: 30px;
`;

const ScoreWrapper = styled.div<{
  $isScrolled: boolean;
  $scoresDisplayed?: boolean;
}>`
  position: absolute;
  background-color: ${palette.white};
  border-radius: ${(props) => (props.$isScrolled ? "0px" : "10px")};
  top: ${(props) =>
    props.$isScrolled ? "calc(50% - 22px)" : "calc(50% - 20px)"};
  left: ${(props) =>
    props.$scoresDisplayed ? "calc(50% - 50px)" : "calc(50% - 40px)"};
  padding: ${spacings.s};
  width: 100px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScoreLabel = styled(H3Decor)`
  text-align: center;
`;

const Timer = styled(H4)`
  text-align: center;
`;

const PrizeSection = styled.div`
  height: 87px;
  width: 100%;
  margin-top: ${spacings.m};
  margin-bottom: ${spacings.l};
  background-image: url(${backgroundPrizeSection});
  background-size: cover;
  border-radius: ${borderRadiuses.m};
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
`;

const PrizeText = styled.div`
  text-align: center;
  grid-column: 2;
`;

const Prize = styled.img.attrs({ src: prize })`
  position: absolute;
  bottom: 0;
`;

const SquaresSection = styled.div`
  border-top: 1px solid ${palette.grey400};
  padding: ${spacings.m} ${spacings.xs};
  background-color: ${palette.grey300};
  height: calc(100% + 200px);
`;

const SquaresHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: ${spacings.s};
`;

const SquaresTitle = styled(H2Decor)`
  align-self: flex-start;
`;

const FooterButton = styled(FooterButtonWide)`
  margin: ${spacings.m} 0;
  width: 80%;
  align-self: center;
`;

const DrawingButton = styled(FooterButton)`
  width: 100%;
`;

const FooterWrapper = styled(Footer)`
  flex-direction: column;
  align-items: flex-start;
`;

const CloseModalFooterButton = styled(FooterButton)`
  background-color: ${palette.greyDark02};
  border: none;
  color: ${palette.grey600};
`;

const SquaresHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const SquaresHeader = styled(H4)`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 35% 55%;
  justify-content: center;
  align-items: center;
`;

const RegisterButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: white;
`;

export {
  ModalWrapper,
  GameDetails,
  GameSecondaryHeadingWrapper,
  GameSecondaryHeading,
  GameDetailsHeading,
  GameDetailsWrapper,
  StadiumDetails,
  HorizontalPadding,
  SectionWrapper,
  ScoreWrapper,
  ScoreLabel,
  Timer,
  VersusLabel,
  PrizeSection,
  PrizeText,
  Prize,
  SquaresSection,
  SquaresHeading,
  SquaresTitle,
  DrawingButton,
  CloseModalFooterButton,
  FooterWrapper,
  FooterButton,
  TotalPayoutWrapper,
  SquaresHeaderWrapper,
  SquaresHeader,
  ButtonWrapper,
  RegisterButtonWrapper,
};
