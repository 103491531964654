import styled from "styled-components";

import { gold, grey300 } from "../../../assets/styles/colors";
import { borderRadiuses, spacings } from "../../../assets/styles/sizes";

const SliderContainer = styled.div`
  width: 100%;

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    padding: ${spacings.xs} 0 0 ${spacings.xs};

    .slick-active button {
      background-color: ${gold};
    }

    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding-left: ${spacings.m};
      border-radius: ${borderRadiuses.m} 0 0 ${borderRadiuses.m};

      .slick-track {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        .slick-slide {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: auto;
          min-height: 1px;
          padding: 0;
        }
      }
    }
  }

  .slick-prev,
  .slick-next {
    display: none;
  }

  .slick-dots {
    width: 100%;
    display: flex;
  }

  .slick-dots li button {
    border: none;
    margin-right: ${spacings.xs};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${grey300};
    font-size: 0;
  }
`;

const DotsWrapper = styled.div`
  display: flex;
`;

const Dots = styled.div`
  display: flex;
  width: 2rem;
  height: 2rem;
  margin-top: ${spacings.s};
`;

const DotsList = styled.ul`
  display: flex;
`;

const Pagination = styled.button`
  background-color: yellow;
`;

export { SliderContainer, DotsWrapper, Dots, DotsList, Pagination };
