import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import chevronTop from "../../../assets/icons/chevron-top.svg";
import { spacings } from "../../../assets/styles/sizes";
import { ROUTES } from "../../../routes";
import { useAppDispatch } from "../../../store";
import {
  selectCartItemsCount,
  selectGamesSelectedForBetting,
  updateGamesSelectedForBetting,
} from "../../../store/cartSlice";
import { TransparentButton } from "../../atoms/Button/Button.style";
import { Icon } from "../../atoms/Icon";
import { MultipleSquaresForMultipleGamesModal } from "../../organisms/MultipleSquaresForMultipleGamesModal";
import { FULL_WIDTH_BOTTOM, Modal } from "../Modal";
import * as S from "./CartSummaryModal.style";

const CartSummaryModal = () => {
  const { t } = useTranslation("buySquare");

  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gamesId = useSelector(selectGamesSelectedForBetting);
  const cartSquareCount = useSelector(selectCartItemsCount);

  return (
    <>
      <S.ModalWrapper>
        <S.HeadingWrapper>
          <TransparentButton
            onClick={() => {
              setModalVisible(true);
              dispatch(updateGamesSelectedForBetting());
            }}
          >
            <Icon src={chevronTop} width="20px" $marginRight={spacings.s} />
            <S.Heading>
              {cartSquareCount === 1
                ? t("modalHeadingSingleBet", {
                    cartSquareCount: cartSquareCount,
                  })
                : t("modalHeadingMultipleBet", {
                    cartSquareCount: cartSquareCount,
                  })}
            </S.Heading>
          </TransparentButton>
        </S.HeadingWrapper>
        <S.ModalButton
          onClick={() => {
            navigate(ROUTES.buySquare);
          }}
          id="multiple-bets-modal-button"
        >
          {t("continue")}
        </S.ModalButton>
      </S.ModalWrapper>
      <Modal
        modalVisible={modalVisible}
        onModalClose={() => setModalVisible(false)}
        contentPlacing={FULL_WIDTH_BOTTOM}
      >
        <MultipleSquaresForMultipleGamesModal
          gameIds={gamesId}
          onModalClose={() => setModalVisible(false)}
        />
      </Modal>
    </>
  );
};

export { CartSummaryModal };
