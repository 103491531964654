import styled from "styled-components";

import * as palette from "../../assets/styles/colors";
import { fontSizes, spacings } from "../../assets/styles/sizes";

const BuySquareWrapper = styled.div`
  background-color: ${palette.white};
  height: 100%;
  overflow: scroll;
`;

const TopContainer = styled.div`
  background: linear-gradient(200deg, rgba(70, 87, 119, 0.8) 1%, #282828 65%);
`;

const StepHeader = styled.div`
  display: grid;
  align-items: center;
  padding: ${spacings.m} ${spacings.xs};
`;

const StepSelector = styled.div`
  display: grid;
  // width should fit parent
  width: 100%;
  height: 100%;
  padding: ${spacings.xs} 0;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${spacings.m};
  justify-content: space-between;
  align-items: center;
`;

const StepNumber = styled.div`
  font-size: ${fontSizes.sm};
  font-family: "Roboto";
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 ${spacings.xs} ${spacings.l} ${spacings.xs};
  height: 50px;
`;

const Title = styled.h1`
  font-family: "Space Grotesk";
  font-weight: 500;
  text-transform: uppercase;
  font-size: ${fontSizes.xxxxl};
  line-height: ${fontSizes.xxxl};
  color: ${palette.white};
`;

const StepIndicatorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacings.xs};
`;

const StepTile = styled.div<{ isActive: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 80%;
  border-radius: 5px;
  background-color: ${({ isActive }) =>
    isActive ? palette.yellowPale : palette.grey300};
  color: ${({ isActive }) => (isActive ? palette.grey900 : palette.grey600)};
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: ${spacings.xxs} 0;
`;

const StepText = styled.h2`
  font-family: "Space Grotesk";
  width: 100%;
  font-size: ${fontSizes.m};
  font-weight: 500;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
`;

const BuySquareContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100%);
`;

export {
  BuySquareWrapper,
  TopContainer,
  HeadingWrapper,
  StepHeader,
  StepSelector,
  StepNumber,
  Title,
  StepIndicatorContainer,
  StepTile,
  StepText,
  BuySquareContent,
};
