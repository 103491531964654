import { useTranslation } from "react-i18next";

import footballOnStand from "../../../assets/icons/football-on-stand.svg";
import football from "../../../assets/icons/football.svg";
import helmet from "../../../assets/icons/helmet.svg";
import shield from "../../../assets/icons/shield.svg";
import ticket from "../../../assets/icons/ticket.svg";
import whistle from "../../../assets/icons/whistle.svg";
import { ALL_GAMES, GameStatusFiltersValues } from "../../../types/filters";
import { GameStatus } from "../../../types/games";
import { Icon } from "../../atoms/Icon";
import * as S from "./StatusFilterTab.style";

interface StatusFilterTabProps {
  status: GameStatusFiltersValues;
  checked: boolean;
  onChange: () => void;
}

const StatusFilterTab = ({
  status,
  checked,
  onChange,
}: StatusFilterTabProps) => {
  const { t } = useTranslation("game");

  const iconMap = () => {
    switch (status) {
      case ALL_GAMES:
        return ticket;
      case GameStatus.OpenForBets:
        return shield;
      case GameStatus.PreDraw:
        return whistle;
      case GameStatus.PreGame:
        return helmet;
      case GameStatus.Live:
        return football;
      case GameStatus.Finished:
        return footballOnStand;
    }
  };

  return (
    <S.StatusFilterTab active={checked} status={status}>
      <Icon src={iconMap()} />
      <S.Input
        type="radio"
        key={`input-${status}`}
        id={status}
        name="statusFilter"
        checked={checked}
        onChange={onChange}
      />
      <S.InputLabel htmlFor={status} key={`label-${status}`}>
        {t(status)}
      </S.InputLabel>
    </S.StatusFilterTab>
  );
};

export { StatusFilterTab };
export type { StatusFilterTabProps };
