import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { format } from "date-fns";

import { RootState } from ".";
import { apiClient } from "../api/apiClient";
import { ENDPOINTS } from "../api/apiEndpoints";
import {
  DeserializedJsonApiResource,
  DeserializedJsonApiResponse,
} from "../api/apiSerializer";
import { getAuthHeader } from "../api/auth";
import { serializeTransaction } from "../serializers/transactionSerializer";
import { GenericState, ResourceDeserialized, StatusEnum } from "../types/api";
import { TransactionAttributes } from "../types/transactions";

interface TransactionsState
  extends GenericState<ResourceDeserialized<TransactionAttributes>> {
  fetchTransactionsStatus: StatusEnum;
}

const initialState: TransactionsState = {
  byId: {},
  allIds: [],
  fetchTransactionsStatus: StatusEnum.Idle,
};

const fetchTransactions = createAsyncThunk(
  "fundsTransfers",
  async (undefined, { rejectWithValue }) => {
    const headers = await getAuthHeader();

    try {
      const response = (await apiClient.get(ENDPOINTS.getFundsTransfers, {
        headers,
      })) as AxiosResponse<
        DeserializedJsonApiResponse<TransactionAttributes, {}>
      >;

      return response.data;
    } catch (error) {
      console.error;

      return rejectWithValue(error);
    }
  }
);

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransactions.pending, (state) => {
      state.fetchTransactionsStatus = StatusEnum.Pending;
    });
    builder.addCase(fetchTransactions.fulfilled, (state, { payload }) => {
      state.fetchTransactionsStatus = StatusEnum.Fulfilled;
      const transactions = payload.entities.fundsTransfers as {
        [id: string]: DeserializedJsonApiResource<TransactionAttributes>;
      };
      state.byId = transactions;
      state.allIds = payload.order;
    });
    builder.addCase(fetchTransactions.rejected, (state) => {
      state.fetchTransactionsStatus = StatusEnum.Rejected;
    });
  },
});

const selectTransactions = (
  state: RootState
): {
  [dateFormatted: string]: ResourceDeserialized<TransactionAttributes>[];
} => {
  const transactions = state.transactions.allIds.map(
    (transactionId) => state.transactions.byId[transactionId]
  );

  const transactionsGrouped = transactions.reduce((hash, object) => {
    const date = format(new Date(object.attributes.createdAt), "MMM d yyyy");
    const transactionGroup = hash[date] || [];
    transactionGroup.push(serializeTransaction(object));
    return { ...hash, [date]: transactionGroup };
  }, {});

  return transactionsGrouped;
};

const selectIsFetchTransactionsPending = (state: RootState): boolean =>
  state.transactions.fetchTransactionsStatus === StatusEnum.Pending;

export const transactionsReducer = transactionsSlice.reducer;

export {
  fetchTransactions,
  selectTransactions,
  selectIsFetchTransactionsPending,
};
