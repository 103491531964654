import { SlideForm } from "../SlideForm";
import * as S from "./CarouselSlide.style";

interface CarouselSlideProps {
  imgSrc?: string;
  backgroundColor?: string;
  headerText?: string;
  buttonText?: string;
  buttonUrl?: string;
  isLink?: boolean;
  buttonCTA?: () => void;
  isForm?: boolean;
  id: string;
}

const CarouselSlide = ({
  imgSrc,
  backgroundColor,
  headerText,
  buttonText,
  buttonUrl,
  isLink,
  buttonCTA,
  isForm,
  id,
}: CarouselSlideProps) => {
  return (
    <S.SlideWrapper imgSrc={imgSrc} backgroundColor={backgroundColor} id={id}>
      <S.SlideContent>
        {headerText.length > 70 ? (
          <S.HeaderSmallFont>{headerText}</S.HeaderSmallFont>
        ) : (
          <S.Header>{headerText}</S.Header>
        )}

        {buttonText &&
          (isLink ? (
            <S.SlideButton to={buttonUrl} target="_blank" id="slide-button">
              {buttonText}
            </S.SlideButton>
          ) : (
            <S.SlideButton as="button" onClick={buttonCTA} id="slide-button">
              {buttonText}
            </S.SlideButton>
          ))}
        {isForm && <SlideForm />}
      </S.SlideContent>
    </S.SlideWrapper>
  );
};

export { CarouselSlide };
