import styled from "styled-components";

import { grey500 } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H1, H2 } from "../../atoms/Typography";

const ModalContentWrapper = styled.div`
  margin: ${spacings.l};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PaymentSummary = styled.div`
  margin-bottom: ${spacings.xl};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const PaymentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:last-of-type {
    border-top: 1px solid ${grey500};
    margin-top: ${spacings.m};
    padding-top: ${spacings.m};
  }
`;

const PaymentInfoLabel = styled(H2)`
  font-weight: 400;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const TotalAmountLabel = styled(H1)`
  &:first-letter {
    text-transform: capitalize;
  }
`;

export {
  ModalContentWrapper,
  PaymentSummary,
  PaymentInfo,
  PaymentInfoLabel,
  TotalAmountLabel,
};
