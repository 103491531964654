import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import wallet from "../../../assets/icons/wallet.svg";
import { black, white } from "../../../assets/styles/colors";
import { selectBalanceFormatted } from "../../../store/walletSlice";
import { IconColor } from "../../atoms/IconColor";
import { H3Decor, H4 } from "../../atoms/Typography";
import * as S from "./WalletFunds.style";

interface WalletFundsProps {
  dark?: boolean;
}

const WalletFunds = ({ dark }: WalletFundsProps) => {
  const { t } = useTranslation("buySquare");
  const balance = useSelector(selectBalanceFormatted);

  return (
    <S.WalletFunds dark={dark} id="wallet-funds">
      <S.WalletLabelWrapper>
        <IconColor src={wallet} color={dark ? white : black} $size="20px" />
        <S.WalletLabel>
          <H4>{t("myWallet")}</H4>
          <H4>{t("available")}</H4>
        </S.WalletLabel>
      </S.WalletLabelWrapper>
      <H3Decor>{balance}</H3Decor>
    </S.WalletFunds>
  );
};

export { WalletFunds };
