const dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

const dollarUSCents = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const formatCurrency = (amount: number): string => dollarUS.format(amount);

const formatCurrencyWithCents = (amount: number): string =>
  dollarUSCents.format(amount);

export { formatCurrency, formatCurrencyWithCents };
