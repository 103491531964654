import { zodResolver } from "@hookform/resolvers/zod";
import { FormEvent } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StyledComponent } from "styled-components";

import checkMark from "../../../assets/icons/check.svg";
import { phoneFormSchema } from "../../../schemas/phoneForm";
import { useAppDispatch } from "../../../store";
import {
  selectIsUpdateUserPhoneFulfilled,
  selectIsUpdateUserPhonePending,
  selectUserName,
  updateUserPhone,
} from "../../../store/userSlice";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { InputWrapper } from "../../organisms/TransactionForm/TransactionForm.style";

export interface FormField {
  firstName: string;
  phone: string;
}

interface FormProps {
  FormWrapper: StyledComponent<"div", any, {}, never>;
  InputWrapper?: StyledComponent<"div", any, {}, never>;
  InputLabel: StyledComponent<"label", any, {}, never>;
  Input: StyledComponent<"input", any, {}, never>;
  ErrorMessage: StyledComponent<"span", any, {}, never>;
  SubscribeButton: StyledComponent<"button", any, {}, never>;
  CheckMarkIcon: StyledComponent<"img", any, {}, never>;
}

const Form = ({
  FormWrapper,
  InputLabel,
  Input,
  ErrorMessage,
  SubscribeButton,
  CheckMarkIcon,
}: FormProps) => {
  const { t } = useTranslation("phoneModal");
  const firstName = useSelector(selectUserName);
  const isPending = useSelector(selectIsUpdateUserPhonePending);
  const isFulfilled = useSelector(selectIsUpdateUserPhoneFulfilled);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormField>({ resolver: zodResolver(phoneFormSchema) });

  const onSubmit = ({ firstName, phone }: FormField) => {
    dispatch(updateUserPhone({ firstName, phone }));
  };

  const handlePhoneChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    const numbers = value.replace(/[^\d-]/g, "");

    let formattedValue = "";
    for (let i = 0; i < numbers.length; i++) {
      if (i === 3 && numbers.charAt(i) !== "-") {
        formattedValue += "-";
      } else if (i === 7 && numbers.charAt(i) !== "-") {
        formattedValue += "-";
      }
      formattedValue += numbers.charAt(i);
    }

    e.currentTarget.value = formattedValue.slice(0, 12);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormWrapper>
        <InputWrapper>
          {!firstName && (
            <>
              <InputLabel htmlFor="first-name">{t("firstName")}</InputLabel>
              <Input
                type="text"
                id="first-name"
                placeholder={t("namePlaceholder")}
                {...register("firstName")}
              />
              {errors.firstName && (
                <ErrorMessage>{t(errors.firstName.message)}</ErrorMessage>
              )}
            </>
          )}

          <InputLabel htmlFor="phone">{t("providePhone")}</InputLabel>
          <Input
            type="tel"
            id="phone"
            placeholder={t("phonePlaceholder")}
            onInput={handlePhoneChange}
            {...register("phone")}
          />
          {errors.phone && (
            <ErrorMessage>{t(errors.phone.message)}</ErrorMessage>
          )}
        </InputWrapper>
        <SubscribeButton type="submit" id="subscribe-button">
          <span>{isFulfilled ? t("subscribed") : t("subscribe")}</span>
          {isPending && <LoadingAnimation spinner />}
          {isFulfilled && <CheckMarkIcon src={checkMark} />}
        </SubscribeButton>
      </FormWrapper>
    </form>
  );
};

export { Form };
