import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { CartItem, CartState } from "../types/cart";

const initialState: CartState = {
  cartItems: [],
  gamesSelectedForBetting: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCartItem: (state, action: PayloadAction<CartItem>) => {
      state.cartItems.push(action.payload);
      state.gamesSelectedForBetting.indexOf(action.payload.gameId) === -1 &&
        state.gamesSelectedForBetting.push(action.payload.gameId);
    },
    removeCartItem: (state, action: PayloadAction<CartItem>) => {
      const itemToRemove = state.cartItems.findIndex(
        (el) => el.gameId === action.payload.gameId
      );
      state.cartItems = state.cartItems.filter(
        (el, index) => index !== itemToRemove
      );
    },
    removeGameFromCart: (state, action: PayloadAction<CartItem>) => {
      state.cartItems = state.cartItems.filter(
        (el) => el.gameId !== action.payload.gameId
      );
      state.gamesSelectedForBetting = state.gamesSelectedForBetting.filter(
        (el) => el !== action.payload.gameId
      );
    },
    updateWagerAmount: (
      state,
      action: PayloadAction<{ wagerAmount: number }>
    ) => {
      state.cartItems.forEach(
        (el) => (el.wagerAmount = action.payload.wagerAmount)
      );
    },
    resetCart: (state) => (state = initialState),
    updateGamesSelectedForBetting: (state) => {
      const gamesIds = state.cartItems.map((el) => el.gameId);
      state.gamesSelectedForBetting = gamesIds.filter(
        (el, index) => gamesIds.indexOf(el) === index
      );
    },
  },
});

export const cartReducer = cartSlice.reducer;
export const {
  addCartItem,
  removeCartItem,
  removeGameFromCart,
  updateWagerAmount,
  resetCart,
  updateGamesSelectedForBetting,
} = cartSlice.actions;

export const selectCart = (state: RootState) => state.cart.cartItems;
export const selectCartItemsCount = (state: RootState) =>
  state.cart.cartItems.length;
export const selectIsGameInCart = (state: RootState, gameId: string): boolean =>
  !!state.cart.cartItems.find((el) => el.gameId === gameId);
export const selectBetsCountForGameId = (
  state: RootState,
  gameId: string
): number => state.cart.cartItems.filter((el) => el.gameId === gameId).length;
export const selectGamesSelectedForBetting = (state: RootState): string[] =>
  state.cart.gamesSelectedForBetting;
