import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";

import minus from "../../../assets/icons/minus.svg";
import plus from "../../../assets/icons/plus.svg";
import { blue400, grey600 } from "../../../assets/styles/colors";
import { useAppDispatch } from "../../../store";
import { addCartItem, removeCartItem } from "../../../store/cartSlice";
import { TeamAttributes } from "../../../types/teams";
import { TransparentButton } from "../../atoms/Button/Button.style";
import { IconColor } from "../../atoms/IconColor";
import { TeamLabel } from "../../atoms/Team";
import * as S from "./GameRow.style";

interface GameRowProps {
  id: string;
  betsCount: number;
  startTime: string;
  teamHome: TeamAttributes;
  teamAway: TeamAttributes;
}

const GameRow = ({
  id,
  betsCount,
  startTime,
  teamHome,
  teamAway,
}: GameRowProps) => {
  const dispatch = useAppDispatch();
  const { control } = useForm();

  return (
    <S.GameRow>
      <Controller
        name={id}
        control={control}
        defaultValue={betsCount}
        render={({ field }) => (
          <>
            <S.GameDate>
              {format(new Date(startTime), "EEEE M/dd")}
              <S.Separator>|</S.Separator>
              {format(new Date(startTime), "p")}
            </S.GameDate>
            <S.GameDetails>
              <S.TeamsWrapper>
                <TeamLabel team={teamHome} abbreviated />
                <S.VersusLabel>vs</S.VersusLabel>
                <TeamLabel team={teamAway} abbreviated />
              </S.TeamsWrapper>
              <S.FormWrapper>
                <TransparentButton
                  id="decrease-bet-count-button"
                  onClick={(e) => {
                    e.preventDefault();
                    field.onChange(field.value - 1);
                    dispatch(removeCartItem({ gameId: id, wagerAmount: 0 }));
                  }}
                  disabled={field.value <= 0}
                >
                  <IconColor
                    src={minus}
                    color={field.value <= 0 ? grey600 : blue400}
                    $size="20px"
                  />
                </TransparentButton>
                <S.Input type="number" id="count" {...field} readOnly />
                <TransparentButton
                  id="increase-bet-count-button"
                  onClick={(e) => {
                    e.preventDefault();
                    field.onChange(field.value + 1);
                    dispatch(addCartItem({ gameId: id, wagerAmount: 0 }));
                  }}
                >
                  <IconColor src={plus} color={blue400} $size="20px" />
                </TransparentButton>
              </S.FormWrapper>
            </S.GameDetails>
          </>
        )}
      />
    </S.GameRow>
  );
};
export { GameRow };
