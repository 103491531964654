import cart from "../../../assets/icons/shopping-cart.svg";
import { white } from "../../../assets/styles/colors";
import { IconColor } from "../../atoms/IconColor";
import * as S from "./CartStatus.style";

interface CartStatusProps {
  cartSquareCount: number;
}

const CartStatus = ({ cartSquareCount }: CartStatusProps) => {
  return (
    <S.CartStatusButton>
      <IconColor src={cart} color={white} $size="35px" />
      {cartSquareCount > 0 && (
        <S.SquareNumberWrapper>
          <S.SquareNumber>{cartSquareCount}</S.SquareNumber>
        </S.SquareNumberWrapper>
      )}
    </S.CartStatusButton>
  );
};

export { CartStatus };
