import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../store";
import { selectBucketBetters } from "../../../store/bucketBettersSlice";
import {
  selectIsFilteredBucketByIndexFulfilled,
  selectIsFilteredBucketByIndexRejected,
  selectIsFilteredBucketByScoreFulfilled,
} from "../../../store/bucketsSlice";
import { BettersAttributesCamelCase } from "../../../types/buckets";
import { formatCurrency } from "../../../utils/currency";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import * as S from "./BettersTable.style";

interface BettersTableProps {
  homeScore?: number;
  awayScore?: number;
  isSlidingOut?: boolean;
  isGameFinished?: boolean;
}

const BettersTable = ({
  homeScore,
  awayScore,
  isSlidingOut,
  isGameFinished,
}: BettersTableProps) => {
  const [visibleRows, setVisibleRows] = useState(-1);
  const { t } = useTranslation("bucketBettersTable");

  const bucketBetters = useSelector<RootState>(
    selectBucketBetters
  ) as BettersAttributesCamelCase[];
  const isFilteredBucketByScoreFulfilled = useSelector(
    selectIsFilteredBucketByScoreFulfilled
  );
  const isFilteredBucketByIndexFulfilled = useSelector(
    selectIsFilteredBucketByIndexFulfilled
  );
  const isFilteredBucketByIndexRejected = useSelector(
    selectIsFilteredBucketByIndexRejected
  );

  const revealRows = () => {
    setVisibleRows((prevVisibleRows) => prevVisibleRows + 1);
  };

  useEffect(() => {
    const revealInterval = setInterval(() => {
      revealRows();
    }, 20);

    if (visibleRows === bucketBetters.length + 1) {
      clearInterval(revealInterval);
    }

    return () => clearInterval(revealInterval);
  }, [visibleRows, bucketBetters.length]);

  const columnsForFinishedGame = useMemo(
    () => [
      {
        header: t("name"),
      },
      {
        header: t("betAmount"),
      },
      {
        header: t("totalWinnings"),
      },
    ],
    [t]
  );

  const columnsForNotFinishedGame = useMemo(
    () => [
      {
        header: t("name"),
      },
      {
        header: t("betAmount"),
      },
    ],
    [t]
  );

  const columns = isGameFinished
    ? columnsForFinishedGame
    : columnsForNotFinishedGame;

  const totalBetAmount = bucketBetters.reduce(
    (sum, better) => sum + parseFloat(better.betAmount),
    0
  );
  const totalWinnings = bucketBetters.reduce(
    (sum, better) => sum + parseFloat(better.totalWinnings),
    0
  );

  const sortTableRows = (
    a: BettersAttributesCamelCase,
    b: BettersAttributesCamelCase
  ) => {
    const customOrder = ["SquareShare", null];

    const firstNameAIndex = customOrder.indexOf(a.firstName);
    const firstNameBIndex = customOrder.indexOf(b.firstName);

    if (firstNameAIndex < firstNameBIndex) {
      return -1;
    }
    if (firstNameAIndex > firstNameBIndex) {
      return 1;
    }

    return parseFloat(b.betAmount) - parseFloat(a.betAmount);
  };

  const sortedBetters = [...bucketBetters].sort(sortTableRows);

  const getFirstName = (better: BettersAttributesCamelCase) => {
    if (better.firstName === "SquareShare" || better.firstName === null) {
      return t(better.firstName || "Private");
    } else {
      return better.firstName;
    }
  };

  return (
    <S.TableSectionWrapper isSlidingOut={isSlidingOut}>
      <S.SectionHeader>
        <span>{t("squareBets")}</span>
        {homeScore && awayScore && <span>{`${homeScore} - ${awayScore}`}</span>}
      </S.SectionHeader>
      {isFilteredBucketByIndexRejected ? (
        <S.NoBettersInfo>{t("noBettersDrawn")}</S.NoBettersInfo>
      ) : (
        <S.BettersTable>
          <S.TableHeader>
            <S.TableHeaderRow>
              {columns.map((column) => (
                <S.TableHeaderCell key={column.header}>
                  {column.header}
                </S.TableHeaderCell>
              ))}
            </S.TableHeaderRow>
          </S.TableHeader>
          <S.TableBody>
            {isFilteredBucketByScoreFulfilled ||
            isFilteredBucketByIndexFulfilled ? (
              <>
                {Object.entries(sortedBetters).map(([index, better]) => (
                  <S.TableRow
                    key={index}
                    isVisible={parseInt(index) <= visibleRows}
                  >
                    <S.TableCell>{getFirstName(better)}</S.TableCell>
                    <S.TableCell>
                      {formatCurrency(parseFloat(better.betAmount))}
                    </S.TableCell>
                    {isGameFinished && (
                      <S.TableCell>
                        {formatCurrency(parseFloat(better.totalWinnings))}
                      </S.TableCell>
                    )}
                  </S.TableRow>
                ))}
                <S.TotalRow isVisible={visibleRows >= sortedBetters.length}>
                  <S.TotalCell>{t("total")}</S.TotalCell>
                  <S.TotalCell>{formatCurrency(totalBetAmount)}</S.TotalCell>
                  {isGameFinished && (
                    <S.TotalCell>{formatCurrency(totalWinnings)}</S.TotalCell>
                  )}
                </S.TotalRow>
              </>
            ) : (
              <tr>
                <td>
                  <S.LoadingWrapper>
                    <LoadingAnimation
                      label={t("general:loading")}
                      lightBackground
                    />
                  </S.LoadingWrapper>
                </td>
              </tr>
            )}
          </S.TableBody>
        </S.BettersTable>
      )}
    </S.TableSectionWrapper>
  );
};

export default BettersTable;
