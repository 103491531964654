import { User } from "@auth0/auth0-react";
import { useSelector } from "react-redux";

import { selectUserName } from "../store/userSlice";

const initialsForTwoNames = (givenName: string, familyName: string): string => {
  return `${givenName[0]}${familyName[0]}`;
};

const getInitials = (user: User) => {
  const firstName = useSelector(selectUserName);

  return firstName
    ? firstName[0]
    : (user.given_name &&
        user.family_name &&
        initialsForTwoNames(user.given_name, user.family_name)) ||
        (user.name && user.name[0]);
};

export { getInitials };
