import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useGameContext } from "../../../context/GameContext";
import { selectCart } from "../../../store/cartSlice";
import {
  selectFilteredGames,
  selectGamesByPhaseAndWeek,
} from "../../../store/gameFiltersSlice";
import {
  selectIsCurrentSeasonGamesFetchingFulfilled,
  selectIsCurrentSeasonGamesFetchingPending,
  selectIsCurrentWeekGamesFetchingFulfilled,
} from "../../../store/gamesSlice";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { MyGamesTabBar } from "../../atoms/MyGamesTabBar";
import { DayFilter } from "../../organisms/DayFilter";
import { GameDetailsModal } from "../../organisms/GameDetailsModal";
import { GameTile } from "../../organisms/GameTile";
import { CartSummaryModal } from "../CartSummaryModal";
import { FULL_WIDTH_BOTTOM_FULL_HEIGHT, Modal } from "../Modal";
import * as S from "./GamesList.style";

const GamesList = () => {
  const {
    modalVisible,
    gameId,
    handleViewGameDetails,
    handleGameDetailsClose,
  } = useGameContext();
  const { t } = useTranslation("games");

  const isPending = useSelector(selectIsCurrentSeasonGamesFetchingPending);
  const isFulfilled = useSelector(selectIsCurrentSeasonGamesFetchingFulfilled);
  const isCurrentWeekFulfilled = useSelector(
    selectIsCurrentWeekGamesFetchingFulfilled
  );

  const { isAuthenticated } = useAuth0();
  const wholeWeekGames = useSelector(selectGamesByPhaseAndWeek);
  const filteredGames = useSelector(selectFilteredGames);

  const betsModalVisible = useSelector(selectCart).length > 0;

  return (
    <>
      <DayFilter />
      <S.GameListWrapper>
        {isAuthenticated && isCurrentWeekFulfilled && <MyGamesTabBar />}
        {isPending && wholeWeekGames.length === 0 && (
          <LoadingAnimation label={t("loadingGames")} />
        )}
        {isFulfilled &&
        wholeWeekGames.length === 0 &&
        isCurrentWeekFulfilled ? (
          <S.NoGamesMessage>
            {t("noGamesMessage")}
            <br />
            {t("noGamesSubmessage")}
          </S.NoGamesMessage>
        ) : (
          filteredGames.map((game) => (
            <GameTile
              displayTimer={game.displayTimer}
              key={game.id}
              id={`game-tile-${game.id}`}
              {...game}
              onViewGameDetails={(gameId: string) =>
                handleViewGameDetails(gameId)
              }
            />
          ))
        )}
      </S.GameListWrapper>
      <Modal
        contentPlacing={FULL_WIDTH_BOTTOM_FULL_HEIGHT}
        modalVisible={modalVisible}
        onModalClose={() => handleGameDetailsClose()}
      >
        <GameDetailsModal
          onModalClose={() => handleGameDetailsClose()}
          gameId={gameId}
        />
      </Modal>
      {betsModalVisible && <CartSummaryModal />}
    </>
  );
};

export { GamesList };
