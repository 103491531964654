import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { RootState } from "../../../store";
import { selectIsPlacingBetStatusPending } from "../../../store/betsSlice";
import {
  selectBetsCountForGameId,
  selectCartItemsCount,
} from "../../../store/cartSlice";
import {
  applyWeekFilter,
  selectLastSelectedWeek,
  selectSeasonPhaseFilter,
} from "../../../store/gameFiltersSlice";
import { selectGameDetails } from "../../../store/gamesSlice";
import { selectBalance } from "../../../store/walletSlice";
import { GameDetails } from "../../../types/games";
import { formatCurrency } from "../../../utils/currency";
import { Ticket } from "../../atoms/Ticket";
import { WarningTile } from "../../atoms/WarningTile";
import { GameRow } from "../../molecules/GameRow";
import { TransactionForm } from "../TransactionForm";
import * as S from "./BuySquareWager.style";

const FormChildrenContent = ({
  amount,
  averageBucketSize,
}: {
  amount: number;
  averageBucketSize: number;
}) => {
  const { t } = useTranslation("buySquare");
  const wagerAmountExceeds = averageBucketSize < amount;

  return wagerAmountExceeds ? (
    <WarningTile title={t("warningTitle")} infoText={t("warningText1")} />
  ) : null;
};

interface BuySquareWagerProps {
  gamesIds: string[];
  singleWagerAmount: string;
  onSummaryOpen: (amount: string) => void;
  onWagerAmountChange: (newAmount: string) => void;
  totalWagerAmount?: string;
}

const BuySquareWager = ({
  gamesIds,
  singleWagerAmount,
  onSummaryOpen,
  onWagerAmountChange,
  totalWagerAmount,
}: BuySquareWagerProps) => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState(singleWagerAmount);
  const [totalAmount, setTotalAmount] = useState(totalWagerAmount);
  const isPending = useSelector(selectIsPlacingBetStatusPending);
  const balance = useSelector(selectBalance);

  const { t } = useTranslation("buySquare");

  const selectedWeek = useSelector(selectLastSelectedWeek);
  const selectedSeasonPhase = useSelector(selectSeasonPhaseFilter);
  const selectedWeekInLocalStorage = localStorage.getItem("lastSelectedWeek");

  const squaresSum = useSelector(selectCartItemsCount);

  const handleSummaryOpen = (selectedAmount: string) => {
    setAmount(selectedAmount);
    onSummaryOpen(selectedAmount);
  };

  const handleOnInputChange = (newAmount: string) => {
    setTotalAmount((parseInt(newAmount) * squaresSum).toString());
    setAmount(newAmount);
    onWagerAmountChange && onWagerAmountChange(newAmount);
  };

  const handleCloseForm = () => {
    navigate("/");
    applyWeekFilter({
      week: selectedWeek || Number(selectedWeekInLocalStorage),
      seasonPhase: selectedSeasonPhase,
    });
  };

  const averageBucketSizesArray = gamesIds.map((id) => {
    const { averageBucketSize } = useSelector<RootState>((state) =>
      selectGameDetails(state, id)
    ) as GameDetails;
    return parseInt(averageBucketSize);
  });

  const averageBucketSizeSum =
    averageBucketSizesArray.length > 1
      ? averageBucketSizesArray.reduce((prev, curr) => prev + curr)
      : averageBucketSizesArray[0];

  const totalWagerAmountFormattedTicket =
    parseInt(totalAmount) >= 0 ? formatCurrency(parseInt(totalAmount)) : "$";
  const totalWagerAmountFormattedButton =
    parseInt(totalAmount) > 0 ? formatCurrency(parseInt(totalAmount)) : "";
  const tooLittleFunds = parseInt(totalAmount) > balance;

  useEffect(() => {
    squaresSum === 0
      ? setTotalAmount(totalWagerAmount)
      : setTotalAmount((parseInt(amount) * squaresSum).toString());
  }, [squaresSum]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.WagerContainer>
      {gamesIds.map((id) => {
        const { startTime, teamAway, teamHome } = useSelector<RootState>(
          (state) => selectGameDetails(state, id)
        ) as GameDetails;
        const betsCount = useSelector<RootState>((state) =>
          selectBetsCountForGameId(state, id)
        ) as number;
        return (
          <GameRow
            key={id}
            id={id}
            betsCount={betsCount}
            startTime={startTime}
            teamHome={teamHome}
            teamAway={teamAway}
          />
        );
      })}
      <TransactionForm
        onSubmit={(newAmount) => handleSummaryOpen(newAmount)}
        onCloseForm={handleCloseForm}
        isFormProcessing={isPending}
        inputLabel={t("transactionFormLabel")}
        submitButtonLabel={t("payButtonTotal", {
          totalAmount: totalWagerAmountFormattedButton,
        })}
        validateFunds={false}
        balance={balance}
        onInputChange={(newAmount) => handleOnInputChange(newAmount)}
        externalValue={amount}
      >
        {(newAmount) => (
          <FormChildrenContent
            amount={newAmount}
            averageBucketSize={averageBucketSizeSum}
          />
        )}
      </TransactionForm>
      <S.PaddingWrapper>
        <Ticket
          amountFormatted={totalWagerAmountFormattedTicket}
          label={t("myWager")}
          $horizontal
        />
        {tooLittleFunds && (
          <S.Error>{t("general:formAmountError-fundsAvailable")}</S.Error>
        )}
      </S.PaddingWrapper>
    </S.WagerContainer>
  );
};

export { BuySquareWager };
