import styled from "styled-components";

import { spacings } from "../../../assets/styles/sizes";
import { buttonGeneralStyles } from "../../atoms/Button/Button.style";
import { H4 } from "../../atoms/Typography";

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  overflow: scroll;
`;

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${spacings.xl} ${spacings.m} ${spacings.xl};
`;

const ApplyButton = styled.button`
  ${buttonGeneralStyles}
  min-width: 90%;
  padding: ${spacings.m};
  margin-bottom: ${spacings.xxs};
`;

const SeasonPhaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SeasonTitle = styled(H4)`
  margin-top: ${spacings.m};
  margin-bottom: ${spacings.xxs};
`;

export {
  ModalBody,
  ApplyButton,
  SectionWrapper,
  ModalWrapper,
  SeasonPhaseWrapper,
  SeasonTitle,
};
