import styled from "styled-components";

import { grey300, grey400, grey800, white } from "../../assets/styles/colors";
import { spacings } from "../../assets/styles/sizes";
import { elementSizes } from "../../assets/styles/sizes";
import { FooterButton } from "../../components/atoms/Button/Button.style";
import { H3, H4 } from "../../components/atoms/Typography";

const Wrapper = styled.div`
  background-color: ${grey300};
  min-height: calc(100vh - ${elementSizes.topMenu});
`;

const ButtonsWrapper = styled.div`
  padding: ${spacings.l} ${spacings.s};
  display: flex;
`;

const PrimaryButton = styled(FooterButton)`
  padding: ${spacings.sm} ${spacings.s};
  flex-grow: 2;
`;

const SecondaryButton = styled(PrimaryButton)`
  background-color: ${grey400};
  flex-grow: 1;
  margin-left: ${spacings.xs};
`;

const TransactionsWrapper = styled.div`
  min-height: calc(100vh - ${elementSizes.topMenu});
  position: relative;
`;

const TransactionsHeadings = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: ${spacings.xxs} ${spacings.s};
  background-color: ${grey800};
`;

const HeadingLabel = styled(H4)`
  color: ${white};
  font-weight: 500;
  text-transform: uppercase;
  text-align: start;

  &:nth-child(3) {
    text-align: end;
  }
`;

const TransactionRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 ${spacings.s};
  padding: ${spacings.sm} 0;
  border-bottom: 1px solid ${grey400};
`;

const TransactionLabel = styled(H3)`
  text-align: start;

  &::first-letter {
    text-transform: capitalize;
  }

  &:nth-child(3) {
    text-align: end;
  }
`;

export {
  Wrapper,
  ButtonsWrapper,
  PrimaryButton,
  SecondaryButton,
  TransactionsWrapper,
  TransactionsHeadings,
  HeadingLabel,
  TransactionRow,
  TransactionLabel,
};
