import * as S from "./LoadingAnimation.style";

const SQUARES_NUMBER = 9;

interface LoadingAnimationProps {
  label?: string;
  spinner?: boolean;
  fullScreen?: boolean;
  lightBackground?: boolean;
}

const LoadingAnimation = ({
  label,
  spinner,
  fullScreen,
  lightBackground,
}: LoadingAnimationProps) => {
  const squaresNumberArray = Array.from(Array(SQUARES_NUMBER).keys());

  return spinner ? (
    <S.LoadingSpinner />
  ) : (
    <S.LoadingAnimationBackground
      fullScreen={fullScreen}
      lightBackground={lightBackground}
    >
      <S.LoadingAnimationWrapper fullScreen={fullScreen}>
        <S.LoadingAnimation>
          {squaresNumberArray.map((el) => {
            return <S.Square key={el} order={el} />;
          })}
        </S.LoadingAnimation>
        <S.Label lightBackground={lightBackground}>{label}</S.Label>
      </S.LoadingAnimationWrapper>
    </S.LoadingAnimationBackground>
  );
};

export { LoadingAnimation };
