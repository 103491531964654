import styled from "styled-components";

import {
  black,
  blue100,
  blue400,
  grey900,
  greyDark02,
  white,
} from "../../../assets/styles/colors";
import {
  borderRadiuses,
  elementSizes,
  spacings,
} from "../../../assets/styles/sizes";
import {
  FooterButton,
  FooterButtonWide,
} from "../../atoms/Button/Button.style";
import { H3Decor, H4 } from "../../atoms/Typography";

const ModalContent = styled.div`
  padding: ${spacings.s} ${spacings.s} ${elementSizes.bottomArea} ${spacings.s};
  overflow: scroll;
`;

const Subtitle = styled(H4)`
  overflow: hidden;
  text-align: center;
  margin-bottom: ${spacings.sm};

  &:before,
  &:after {
    background-color: ${greyDark02};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 2rem;
    margin-left: -50%;
  }
  &:after {
    left: 2rem;
    margin-right: -50%;
  }
`;

const SquareRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 5fr 1fr;
  height: 5rem;
  margin-bottom: ${spacings.s};
`;

const SquareCountWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SquareWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: 100%;
  position: relative;
  border: 1px solid ${blue100};
  border-radius: ${borderRadiuses.s};
`;

const SquareDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const Team = styled.div<{ backgroundSrc: string }>`
  display: flex;
  align-items: center;
  background: ${(p) => p.backgroundSrc};
  padding: 0 ${spacings.m};

  &:nth-child(2) {
    flex-direction: row-reverse;
    border-radius: 0 ${borderRadiuses.s} ${borderRadiuses.s} 0;
  }
`;

const TeamIcon = styled.img`
  height: 40px;
`;

const ScoresWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 85%;
  background-color: ${white};
  border-radius: 30px;
  position: absolute;
  color: ${black};
  top: 8%;
  left: 40%;
`;

const Score = styled(H3Decor)<{ squaresRevealed: boolean }>`
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation-delay: ${(p) => (p.squaresRevealed ? "0" : "10s")};

  @-webkit-keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
`;

const Colon = styled(H3Decor)`
  margin: 0 ${spacings.xs};
`;

const WagerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WagerLabel = styled(H4)`
  text-transform: uppercase;
  margin-bottom: ${spacings.xxxs};
`;

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 40% 70%;
  grid-gap: 5%;
  width: 80%;
  height: 70px;
  align-items: center;
  justify-content: center;
  margin: 0 ${spacings.s};
`;

const ViewGameButton = styled(FooterButton)`
  color: ${grey900};
`;

const Button = styled(FooterButtonWide)<{ squaresNotRevealed?: boolean }>`
  background-color: ${(p) => p.squaresNotRevealed && blue400};
  color: ${(p) => p.squaresNotRevealed && white};
`;

const ShowBoardButton = styled(FooterButton)`
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  background-color: ${blue400};
  color: ${white};
  text-transform: uppercase;
`;

export {
  ModalContent,
  Subtitle,
  SquareWrapper,
  SquareRow,
  SquareCountWrapper,
  SquareDetailsWrapper,
  Team,
  TeamIcon,
  ScoresWrapper,
  Score,
  Colon,
  WagerWrapper,
  WagerLabel,
  ButtonsWrapper,
  Button,
  ShowBoardButton,
  ViewGameButton,
};
