import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { BetWithId } from "../../../types/bets";
import { selectIsLiveGameUpdatePending } from "../../../store/gamesSlice";
import { GamePartsForPayout, GamePart } from "../../../types/games";
import check from "../../../assets/icons/check.svg";
import live from "../../../assets/icons/live.svg";
import { TeamAttributes } from "../../../types/teams";
import { H4, H5Decor } from "../../atoms/Typography";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { RevealedSquare } from "../RevealedSquare";
import * as S from "./SquareWithDetailsTable.style";

const ScoringScenarioWrapper = ({
  scoringScenarioDescription,
  teamHome,
  teamAway,
}: {
  scoringScenarioDescription: string;
  teamHome: string;
  teamAway: string;
}) => {
  const { t } = useTranslation("game");

  return (
    <S.ScoringScenarioWrapper>
      <H4>{t("payoutLabel")}</H4>
      <H5Decor>
        {/* TODO temporary default description before season opens */}
        {scoringScenarioDescription ||
          t("scoringScenarioTemporaryLabel", {
            teamHome,
            teamAway,
          })}
      </H5Decor>
    </S.ScoringScenarioWrapper>
  );
};

const TableRow = ({
  quarter,
  potentialPayout,
  isCurrentQuarter,
  scoringScenarioDescription,
  teamHome,
  teamAway,
  winning,
}: {
  quarter: GamePartsForPayout;
  potentialPayout: string | null;
  isCurrentQuarter: boolean;
  $final?: boolean;
  scoringScenarioDescription?: string;
  teamHome?: string;
  teamAway?: string;
  winning: string | null;
}) => {
  const { t } = useTranslation("game");

  const isLiveUpdatePending = useSelector(selectIsLiveGameUpdatePending);
  const isWinning = winning === potentialPayout;

  return (
    <>
      <S.QuartersTableRow isCurrentQuarter={isCurrentQuarter}>
        <S.QuartersTableCell
          $align="start"
          liveIconUrl={isCurrentQuarter && live}
        >
          {quarter === "final" ? quarter : t("q", { quarter: quarter })}
        </S.QuartersTableCell>
        {isLiveUpdatePending ? (
          <S.SpinnerWrapper>
            <LoadingAnimation spinner />
          </S.SpinnerWrapper>
        ) : (
          <S.QuartersTableCell $align="start">
            {potentialPayout}
          </S.QuartersTableCell>
        )}
        <S.QuartersTableCell isWinningIcon={isWinning}>
          {winning}
          <S.IconWrapper iconUrl={isWinning && check} />
        </S.QuartersTableCell>
      </S.QuartersTableRow>
      {isCurrentQuarter && (
        <ScoringScenarioWrapper
          scoringScenarioDescription={scoringScenarioDescription}
          teamHome={teamHome}
          teamAway={teamAway}
        />
      )}
    </>
  );
};

const QuartersTableHead = () => {
  const { t } = useTranslation("game");
  return (
    <S.QuartersTableRow>
      <S.QuartersTableHeading>{t("quarter")}</S.QuartersTableHeading>
      <S.QuartersTableHeading>{t("potentialPayout")}</S.QuartersTableHeading>
      <S.QuartersTableHeading $align="end">
        {t("winnings")}
      </S.QuartersTableHeading>
    </S.QuartersTableRow>
  );
};

const SquareWithDetailsTable = ({
  teamHome,
  teamAway,
  bet: {
    amountFormatted,
    quarter1PotentialWinningFormatted,
    quarter2PotentialWinningFormatted,
    quarter3PotentialWinningFormatted,
    finalQuarterPotentialWinningFormatted,
    awayScore,
    homeScore,
    totalPotentialWinningsFormatted,
    scoringScenarioDescription,
    scoringScenarioProbability,
    quarter1WinningFormatted,
    quarter2WinningFormatted,
    quarter3WinningFormatted,
    finalQuarterWinningFormatted,
    winningQuarters,
  },
  quarter,
}: {
  bet: BetWithId;
  teamHome: TeamAttributes;
  teamAway: TeamAttributes;
  quarter?: GamePart;
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const ref = useRef(null);

  const { t } = useTranslation(["game", "general"]);

  const gameParts: {
    name: GamePartsForPayout;
    potentialPayout: string;
    winning: string;
  }[] = [
    {
      name: "1",
      potentialPayout: quarter1PotentialWinningFormatted,
      winning: quarter1WinningFormatted,
    },
    {
      name: "2",
      potentialPayout: quarter2PotentialWinningFormatted,
      winning: quarter2WinningFormatted,
    },
    {
      name: "3",
      potentialPayout: quarter3PotentialWinningFormatted,
      winning: quarter3WinningFormatted,
    },
    {
      name: "final",
      potentialPayout: finalQuarterPotentialWinningFormatted,
      winning: finalQuarterWinningFormatted,
    },
  ];

  const isCurrentQuarter = (quarterName: GamePartsForPayout) => {
    return (
      quarter === quarterName ||
      (quarterName === "2" && (quarter === "2" || quarter === "Half")) ||
      (quarterName === "final" && (quarter === "4" || quarter === "OT"))
    );
  };

  useEffect(() => {
    !collapsed && ref.current?.scrollIntoView({ behavior: "smooth" });
  }, [collapsed]);

  return (
    <S.SquareWrapper ref={ref} collapsed={collapsed}>
      <RevealedSquare
        expandable={true}
        teamAway={teamAway}
        teamHome={teamHome}
        awayScore={awayScore}
        homeScore={homeScore}
        amountFormatted={amountFormatted}
        potentialPayoutFormatted={totalPotentialWinningsFormatted}
        // TODO temporary default probability before season opens
        scoringScenarioProbability={scoringScenarioProbability || 4}
        winningQuarters={winningQuarters}
      />
      <>
        <S.QuartersDetails collapsed={collapsed}>
          <S.QuartersTable collapsed={collapsed}>
            <QuartersTableHead />
            {gameParts.map((quarterElement) => (
              <TableRow
                key={quarterElement.name}
                quarter={quarterElement.name}
                potentialPayout={quarterElement.potentialPayout}
                isCurrentQuarter={isCurrentQuarter(quarterElement.name)}
                scoringScenarioDescription={scoringScenarioDescription}
                teamHome={teamHome.teamName}
                teamAway={teamAway.teamName}
                winning={quarterElement.winning}
              />
            ))}
          </S.QuartersTable>
        </S.QuartersDetails>
        <S.ExpandableBar
          collapsed={collapsed}
          onClick={() => setCollapsed((prevState) => !prevState)}
        >
          {collapsed ? t("general:more") : t("general:less")}
        </S.ExpandableBar>
      </>
    </S.SquareWrapper>
  );
};

export { SquareWithDetailsTable };
