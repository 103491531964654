import { useTranslation } from "react-i18next";

import { H5Decor } from "../Typography";
import * as S from "./Banner.style";

const Banner = () => {
  const { t } = useTranslation("banner");

  return (
    <S.Banner>
      <S.BannerText>{t("bannerText")}</S.BannerText>
    </S.Banner>
  );
};

export { Banner };
