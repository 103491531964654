// auth0Client.js
import axios from "axios";

const auth0Client = axios.create({
  baseURL: `${import.meta.env.VITE_AUTH_DOMAIN}/dbconnections`, // Replace with your Auth0 domain
});

export const changePassword = async (userEmail: string) => {
  try {
    const response = await auth0Client.post(
      "/change_password",
      {
        email: userEmail,
        connection: "Username-Password-Authentication", // Replace with your connection
        client_id: import.meta.env.VITE_AUTH_CLIENT_ID,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
