import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LoadingAnimation } from "../../components/atoms/LoadingAnimation";
import { Carousel } from "../../components/molecules/Carousel";
import { GamesList } from "../../components/molecules/GamesList";
import { BottomMenu } from "../../components/organisms/BottomMenu";
import { StatusFilter } from "../../components/organisms/StatusFilter";
import { WeekFilter } from "../../components/organisms/WeekFilter";
import { GameContext } from "../../context/GameContext";
import { useLiveUpdates } from "../../hooks/useLiveUpdates";
import { useAppDispatch } from "../../store";
import {
  applyWeekFilter,
  selectSeasonPhaseFilter,
} from "../../store/gameFiltersSlice";
import {
  fetchCurrentWeekGames,
  selectIsCurrentWeekGamesFetchingPending,
  selectIsCurrentWeekGamesFetchingRejected,
} from "../../store/gamesSlice";
import * as S from "./Games.style";

const Games = () => {
  const { t } = useTranslation("games");
  const { isAuthenticated } = useAuth0();
  const dispatch = useAppDispatch();
  const isPending = useSelector(selectIsCurrentWeekGamesFetchingPending);
  const isRejected = useSelector(selectIsCurrentWeekGamesFetchingRejected);
  const selectedSeasonPhase = useSelector(selectSeasonPhaseFilter);
  const selectedWeekInLocalStorage = localStorage.getItem("lastSelectedWeek");
  const { gameId } = useContext(GameContext);

  useEffect(() => {
    (!selectedWeekInLocalStorage && gameId === "") || !isAuthenticated
      ? dispatch(fetchCurrentWeekGames())
      : dispatch(
          applyWeekFilter({
            week: Number(selectedWeekInLocalStorage),
            seasonPhase: selectedSeasonPhase,
          })
        );
  }, []);

  useLiveUpdates();

  return (
    <S.GamesWrapper>
      <S.TopContainer>
        <Carousel />
        <S.HeadingWrapper>
          <S.Title>{t("title")}</S.Title>
          <WeekFilter />
        </S.HeadingWrapper>
      </S.TopContainer>
      <StatusFilter />
      <S.GamesListContainer>
        {isPending ? (
          <LoadingAnimation label={t("loadingGames")} />
        ) : isRejected ? (
          <S.GamesErrorMessage>
            <S.GamesErrorMessageTitle>
              {t("gamesFetchingRejectedTitle")}
            </S.GamesErrorMessageTitle>
            <S.GamesErrorMessageText>
              {t("gamesFetchingRejectedText")}
            </S.GamesErrorMessageText>
          </S.GamesErrorMessage>
        ) : (
          <GamesList />
        )}
      </S.GamesListContainer>
      {/* For now it's commented out, as there is no need to display that menu */}
      {/* <BottomMenu /> */}
    </S.GamesWrapper>
  );
};

export { Games };
