import styled from "styled-components";

import { grey800, white } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { buttonGeneralStyles } from "../../atoms/Button/Button.style";
import { H2 } from "../../atoms/Typography";

const ModalWrapper = styled.div`
  height: fit-content;
  position: fixed;
  height: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${spacings.xxs} ${spacings.xxs} ${spacings.xxs} ${spacings.s};
  z-index: 2;
  background-color: ${grey800};
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Heading = styled(H2)`
  color: ${white};
  font-weight: 400;
  text-transform: none;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const ModalButton = styled.button`
  ${buttonGeneralStyles}
  padding: 0 ${spacings.xl};
  height: 100%;
`;

export { ModalWrapper, HeadingWrapper, Heading, ModalButton };
