import styled from "styled-components";

import { grey900 } from "../../../assets/styles/colors";
import { fontSizes, lineHeights, spacings } from "../../../assets/styles/sizes";
import {
  ButtonLabel,
  ButtonValue,
} from "../../organisms/WeekFilter/WeekFilter.style";

const ButtonWrapper = styled.div<{ wide?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.wide ? "row" : "column")};
  align-items: ${(props) => (props.wide ? "center" : "flex-start")};
  margin-right: ${spacings.xxs};
`;

const ButtonLabelMain = styled(ButtonValue)`
  color: ${grey900};
`;

const ButtonLabelSecondary = styled(ButtonLabel)<{ wide?: boolean }>`
  margin-left: ${(props) => (props.wide ? spacings.xxs : 0)};
  font-size: ${(props) => (props.wide ? fontSizes.sm : fontSizes.xs)};
  line-height: ${lineHeights.xs};
  font-weight: 400;
  color: ${grey900};
`;

export { ButtonWrapper, ButtonLabelMain, ButtonLabelSecondary };
