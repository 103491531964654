import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";

const StatusFilterWrapper = styled.div`
  display: flex;
  height: 5.5rem;
  background-color: ${palette.grey800};
  overflow-x: scroll;
`;

const StatusFilterTabsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export { StatusFilterWrapper, StatusFilterTabsWrapper };
