import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { apiClient } from "../api/apiClient";
import { ENDPOINTS } from "../api/apiEndpoints";
import {
  DeserializedJsonApiResource,
  SingleResourcePayloadSerialized,
  serializeSingleResource,
} from "../api/apiSerializer";
import { getAuthHeader } from "../api/auth";
import { StatusEnum } from "../types/api";
import { NotificationPosition, NotificationType } from "../types/notification";
import { formatCurrency, formatCurrencyWithCents } from "../utils/currency";
import { triggerNotification } from "./notificationSlice";

interface PaymentsData {
  paymentSessionId: string;
  paymentAmount: string;
  paymentsProviderFee: string;
  singleUseCustomerToken: string | null;
}

interface WalletState extends PaymentsData {
  initiatePaymentStatus: StatusEnum;
  confirmPaymentStatus: StatusEnum;
  fetchBalanceStatus: StatusEnum;
  balance: string;
}

const initialState: WalletState = {
  initiatePaymentStatus: StatusEnum.Idle,
  confirmPaymentStatus: StatusEnum.Idle,
  fetchBalanceStatus: StatusEnum.Idle,
  balance: "",
  paymentSessionId: "",
  paymentAmount: "",
  paymentsProviderFee: "",
  singleUseCustomerToken: null,
};

interface InitiatePaymentPayloadAttributes {
  amount: string;
}

interface ConfirmPaymentPayloadAttributes {
  paymentSessionId: string;
  handleToken: string;
  amount: string;
  paymentsProviderFee: string;
}

interface CancelPaymentPayloadAttributes {
  paymentSessionId: string;
}

interface InitiatePaymentResponseAttributes
  extends InitiatePaymentPayloadAttributes,
    PaymentsData {
  status: string;
}

const initiatePayment = createAsyncThunk(
  "wallet/initiatePayment",
  async ({ amount }: InitiatePaymentPayloadAttributes, { rejectWithValue }) => {
    try {
      const headers = await getAuthHeader();
      const payload = serializeSingleResource({
        type: "payment",
        attributes: {
          amount,
        },
      });

      const response = await apiClient.post<{
        entities: {
          [
            id: string
          ]: DeserializedJsonApiResource<InitiatePaymentResponseAttributes>;
        };
      }>(ENDPOINTS.createPayment, payload, { headers });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.code);
    }
  }
);

const confirmPayment = createAsyncThunk(
  "wallet/confirmPayment",
  async (
    {
      paymentSessionId,
      handleToken,
      amount,
      paymentsProviderFee,
    }: ConfirmPaymentPayloadAttributes,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const headers = await getAuthHeader();
      const payload = serializeSingleResource({
        type: "payment",
        attributes: {
          paymentSessionId,
          handleToken,
          amount,
          paymentsProviderFee,
        },
      });

      const response = await apiClient.post<
        SingleResourcePayloadSerialized<ConfirmPaymentPayloadAttributes>
      >(ENDPOINTS.confirmPayment, payload, { headers });

      if (response.status === 204) {
        dispatch(
          triggerNotification({
            type: NotificationType.SUCCESS,
            message: "payment_success",
            position: NotificationPosition.BOTTOM,
          })
        );

        dispatch(fetchBalance());

        return response.status;
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: NotificationType.ERROR,
          message: error.response.data.errors[0].code,
          position: NotificationPosition.BOTTOM,
        })
      );

      return rejectWithValue(error);
    }
  }
);

const cancelPayment = createAsyncThunk(
  "wallet/cancelPayment",
  async (
    { paymentSessionId }: CancelPaymentPayloadAttributes,
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      triggerNotification({
        type: NotificationType.ERROR,
        message: "paymentNotCreated",
        position: NotificationPosition.BOTTOM,
      })
    );

    try {
      const payload = serializeSingleResource({
        type: "payment",
        attributes: {
          paymentSessionId,
        },
      });

      await apiClient.post<
        SingleResourcePayloadSerialized<ConfirmPaymentPayloadAttributes>
      >(ENDPOINTS.cancelPayment, payload);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const fetchBalance = createAsyncThunk("wallet/fetchBalance", async () => {
  const headers = await getAuthHeader();

  const response = await apiClient.get<{
    entities: {
      [id: string]: DeserializedJsonApiResource<{ balance: string }>;
    };
  }>(ENDPOINTS.getWalletBalance, { headers });

  return response.data;
});

const walletSlice = createSlice({
  name: "wallet",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(initiatePayment.pending, (state) => {
      state.initiatePaymentStatus = StatusEnum.Pending;
    });
    builder.addCase(initiatePayment.fulfilled, (state, { payload }) => {
      state.initiatePaymentStatus = StatusEnum.Fulfilled;

      const paymentResponseAttrs = Object.values(payload.entities.payments)[0]
        .attributes as InitiatePaymentResponseAttributes;

      state.paymentSessionId = paymentResponseAttrs.paymentSessionId;
      state.paymentAmount = paymentResponseAttrs.amount;
      state.paymentsProviderFee = paymentResponseAttrs.paymentsProviderFee;
      state.singleUseCustomerToken =
        paymentResponseAttrs.singleUseCustomerToken;
    });
    builder.addCase(initiatePayment.rejected, (state) => {
      state.initiatePaymentStatus = StatusEnum.Rejected;
    });
    builder.addCase(confirmPayment.pending, (state) => {
      state.confirmPaymentStatus = StatusEnum.Pending;
    });
    builder.addCase(confirmPayment.fulfilled, (state) => {
      state.confirmPaymentStatus = StatusEnum.Fulfilled;
      state.paymentSessionId = "";
      state.paymentAmount = "";
      state.paymentsProviderFee = "";
      state.singleUseCustomerToken = null;
    });
    builder.addCase(confirmPayment.rejected, (state) => {
      state.confirmPaymentStatus = StatusEnum.Rejected;
    });
    builder.addCase(cancelPayment.fulfilled, (state) => {
      state.confirmPaymentStatus = StatusEnum.Fulfilled;
      state.paymentSessionId = "";
      state.paymentAmount = "";
      state.paymentsProviderFee = "";
      state.singleUseCustomerToken = null;
    });
    builder.addCase(fetchBalance.pending, (state) => {
      state.fetchBalanceStatus = StatusEnum.Pending;
    });
    builder.addCase(
      fetchBalance.fulfilled,
      (
        state,
        action: PayloadAction<{
          entities: {
            [id: string]: DeserializedJsonApiResource<{ balance: string }>;
          };
        }>
      ) => {
        state.fetchBalanceStatus = StatusEnum.Pending;
        const balanceAttrs = Object.values(action.payload.entities.wallets)[0]
          .attributes as { balance: string };
        state.balance = balanceAttrs.balance;
      }
    );
  },
});

const selectBalanceFormatted = (state: RootState): string =>
  (state.wallet.balance && formatCurrency(parseInt(state.wallet.balance))) ||
  "$";

const selectBalance = (state: RootState): number =>
  parseFloat(state.wallet.balance);

const selectPaymentDetails = (
  state: RootState
): {
  paymentSessionId: string;
  paymentAmountFormatted: string;
  paymentAmountInDollars: string;
  paymentTotalAmountFormatted: string;
  paymentTotalAmountCents: number;
  paymentsProviderFeeFormatted: string;
  paymentsProviderFee: string;
  singleUseCustomerToken: string;
} => {
  const {
    paymentSessionId,
    paymentAmount,
    paymentsProviderFee,
    singleUseCustomerToken,
  } = state.wallet;
  const paymentTotalAmount =
    parseFloat(paymentAmount) + parseFloat(paymentsProviderFee);

  const paymentTotalAmountCents = Math.round(paymentTotalAmount * 100);

  return {
    paymentSessionId: paymentSessionId,
    singleUseCustomerToken: singleUseCustomerToken,
    paymentAmountFormatted: formatCurrencyWithCents(parseFloat(paymentAmount)),
    paymentAmountInDollars: paymentAmount,
    paymentsProviderFeeFormatted: formatCurrencyWithCents(
      parseFloat(paymentsProviderFee)
    ),
    paymentTotalAmountFormatted: formatCurrencyWithCents(paymentTotalAmount),
    paymentTotalAmountCents: paymentTotalAmountCents,
    paymentsProviderFee: `${paymentsProviderFee}`,
  };
};

const selectInitiatePaymentPending = (state: RootState): boolean =>
  state.wallet.initiatePaymentStatus === StatusEnum.Pending;

const selectConfirmPaymentPending = (state: RootState): boolean =>
  state.wallet.confirmPaymentStatus === StatusEnum.Pending;

export const walletReducer = walletSlice.reducer;
export {
  initiatePayment,
  confirmPayment,
  cancelPayment,
  fetchBalance,
  selectBalance,
  selectBalanceFormatted,
  selectPaymentDetails,
  selectInitiatePaymentPending,
  selectConfirmPaymentPending,
};
