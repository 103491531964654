import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { nameFormSchema } from "../../../schemas/nameForm";
import { useAppDispatch } from "../../../store";
import {
  selectIsUpdateUserNamePending,
  updateUserName,
} from "../../../store/userSlice";
import { FooterButtonSmall } from "../../atoms/Button/Button.style";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { CloseModalButton, Footer } from "../../molecules/Modal/Modal.style";
import * as S from "./PersonalDataModal.style";

export interface FormField {
  firstName: string;
}
interface PersonalDataModalProps {
  userName: string;
  onModalClose: () => void;
}

const PersonalDataModal = ({
  onModalClose,
  userName,
}: PersonalDataModalProps) => {
  const { t } = useTranslation("myProfile");
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormField>({ resolver: zodResolver(nameFormSchema) });

  const isPending = useSelector(selectIsUpdateUserNamePending);

  const onSubmit = ({ firstName }: FormField) => {
    dispatch(updateUserName({ firstName }));
  };

  return (
    <S.ModalWrapper>
      <CloseModalButton
        aria-label={t("general:closeModal")}
        onClick={onModalClose}
        id="close-game-details-modal-button"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.ModalContent>
          <S.NameWraper>
            <S.NameLabel htmlFor="first-name">{t("newName")}</S.NameLabel>
            <S.NameInput
              type="text"
              placeholder={userName}
              id="first-name"
              {...register("firstName")}
            />
            {errors.firstName && (
              <S.ErrorMessage>{t(errors.firstName.message)}</S.ErrorMessage>
            )}
          </S.NameWraper>
        </S.ModalContent>
        <Footer>
          <FooterButtonSmall
            secondary
            onClick={onModalClose}
            id="close-payment-modal-button"
          >
            {t("general:close")}
          </FooterButtonSmall>
          <FooterButtonSmall
            type="submit"
            id="save-username-button"
            disabled={isPending}
          >
            {t("general:save")}
            {isPending && <LoadingAnimation spinner />}
          </FooterButtonSmall>
        </Footer>
      </form>
    </S.ModalWrapper>
  );
};

export { PersonalDataModal };
