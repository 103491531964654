import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "../../../store";
import { selectBucketBetters } from "../../../store/bucketBettersSlice";
import {
  revealScoresBoard,
  selectGameDetails,
} from "../../../store/gamesSlice";
import { BetWithOrderIndex } from "../../../types/bets";
import { BettersAttributesCamelCase } from "../../../types/buckets";
import { GameDetails, GameStatus } from "../../../types/games";
import { getIcon, getTeamColor } from "../../atoms/Team";
import { H4Decor, H5Decor } from "../../atoms/Typography";
import {
  CloseModalButton,
  Footer,
  ModalHeader,
  ModalHeading,
} from "../../molecules/Modal/Modal.style";
import BettersTable from "../BettersTable/BettersTable";
import { Board } from "../Board/Board";
import { BoardLegend } from "../BoardLegend";
import * as S from "./BoardDrawingModal.style";

const BOARD_DRAWING_DURATION_MS = 20000;

interface BoardDrawingModalProps {
  gameId: string;
  revealed: boolean;
  onModalClose: () => void;
}

const BoardDrawingModal = ({
  gameId,
  revealed,
  onModalClose,
}: BoardDrawingModalProps) => {
  const { t } = useTranslation("drawing");
  const { isAuthenticated } = useAuth0();
  const dispatch = useAppDispatch();

  const [selectedBucket, setSelectedBucket] = useState({
    selectedHomeScore: 0,
    selectedAwayScore: 0,
  });
  const [displayBettersTable, setDisplayBettersTable] = useState(false);
  const [swipeStartX, setSwipeStartX] = useState(null);
  const [animationClass, setAnimationClass] = useState("");
  const modalContentRef = useRef(null);

  const handleBucketSelection = (selectedHomeScore, selectedAwayScore) => {
    setSelectedBucket({ selectedHomeScore, selectedAwayScore });
    setDisplayBettersTable(true);
  };

  const switchToBoardView = () => {
    setAnimationClass("slide-out");
    setTimeout(() => {
      setDisplayBettersTable(false);
      setAnimationClass("");
    }, 500);
  };

  const { selectedHomeScore, selectedAwayScore } = selectedBucket;

  const game = useSelector<RootState>((state) =>
    selectGameDetails(state, gameId)
  ) as GameDetails;

  const isGameFinished = game.status === GameStatus.Finished;
  const isScoresOrderDrawn = game.homeScoresOrder && game.awayScoresOrder;

  const betsWithOrderIndex: BetWithOrderIndex[] = game.betsSorted.map(
    (bet, i) => {
      return { ...bet, orderIndex: `${i + 1}` };
    }
  );

  const bucketBetters = useSelector<RootState>(
    selectBucketBetters
  ) as BettersAttributesCamelCase[];

  const handleRevealScoresBoard = () => {
    dispatch(revealScoresBoard({ id: gameId }));
  };

  useEffect(() => {
    if (!revealed) {
      const timeout = setTimeout(
        handleRevealScoresBoard,
        BOARD_DRAWING_DURATION_MS
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [revealed]);

  // Swipe logic
  useEffect(() => {
    const modalContentElement = modalContentRef.current;

    const handleTouchStart = (e) => {
      setSwipeStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e) => {
      if (swipeStartX !== null) {
        const deltaX = e.changedTouches[0].clientX - swipeStartX;
        const swipeThreshold = 50;

        if (Math.abs(deltaX) > swipeThreshold) {
          if (deltaX > 0) {
            switchToBoardView();
          }
        }
        setSwipeStartX(null);
      }
    };

    modalContentElement.addEventListener("touchstart", handleTouchStart);
    modalContentElement.addEventListener("touchend", handleTouchEnd);

    return () => {
      modalContentElement.removeEventListener("touchstart", handleTouchStart);
      modalContentElement.removeEventListener("touchend", handleTouchEnd);
    };
  }, [swipeStartX]);

  return (
    <div>
      <CloseModalButton
        aria-label={t("general:closeModal")}
        onClick={onModalClose}
        id="close-board-drawing-modal-button"
      />
      <ModalHeader>
        <ModalHeading>{t("title")}</ModalHeading>
      </ModalHeader>
      <S.ModalContent ref={modalContentRef}>
        {betsWithOrderIndex.length > 0 && (
          <S.Subtitle>{t("game:mySquares")}</S.Subtitle>
        )}
        {betsWithOrderIndex.map((bet) => {
          return (
            <S.SquareRow key={bet.id}>
              <S.SquareWrapper>
                <S.SquareCountWrapper>
                  <H5Decor>#{bet.orderIndex}</H5Decor>
                </S.SquareCountWrapper>
                <S.SquareDetailsWrapper>
                  <S.Team backgroundSrc={getTeamColor(game.teamHome)}>
                    <S.TeamIcon src={getIcon(game.teamHome)} />
                  </S.Team>
                  <S.Team backgroundSrc={getTeamColor(game.teamAway)}>
                    <S.TeamIcon src={getIcon(game.teamAway)} />
                  </S.Team>
                  <S.ScoresWrapper>
                    <S.Score squaresRevealed={revealed}>
                      {bet.homeScore}
                    </S.Score>
                    <S.Colon>:</S.Colon>
                    <S.Score squaresRevealed={revealed}>
                      {bet.awayScore}
                    </S.Score>
                  </S.ScoresWrapper>
                </S.SquareDetailsWrapper>
              </S.SquareWrapper>
              <S.WagerWrapper>
                <S.WagerLabel>{t("wager")}</S.WagerLabel>
                <H4Decor>{bet.amountFormatted}</H4Decor>
              </S.WagerWrapper>
            </S.SquareRow>
          );
        })}
        {displayBettersTable ? (
          <BettersTable
            homeScore={isScoresOrderDrawn && selectedHomeScore}
            awayScore={isScoresOrderDrawn && selectedAwayScore}
            isSlidingOut={animationClass === "slide-out"}
            isGameFinished={game.status === GameStatus.Finished}
          />
        ) : (
          <>
            <Board
              bets={betsWithOrderIndex}
              game={game}
              revealed={revealed}
              onBucketSelect={handleBucketSelection}
            />
            {isGameFinished && <BoardLegend />}
          </>
        )}
      </S.ModalContent>
      <Footer>
        {isAuthenticated ? (
          revealed ? (
            displayBettersTable ? (
              <S.ButtonsWrapper>
                <S.ViewGameButton onClick={onModalClose} id="view-game-button">
                  {t("game:viewGame")}
                </S.ViewGameButton>
                <S.ShowBoardButton
                  onClick={switchToBoardView}
                  id="show-board-button"
                >
                  {t("bucketBettersTable:showBoardButton")}
                </S.ShowBoardButton>
              </S.ButtonsWrapper>
            ) : (
              <S.Button onClick={onModalClose} id="view-game-button">
                {t("game:viewGame")}
              </S.Button>
            )
          ) : game.status === GameStatus.PreDraw ? (
            <S.Button onClick={onModalClose} id="view-game-button">
              {t("game:viewGame")}
            </S.Button>
          ) : (
            <S.Button
              onClick={() => handleRevealScoresBoard()}
              squaresNotRevealed
              id="reveal-scores-button"
            >
              {t("reveal")}
            </S.Button>
          )
        ) : (
          <S.Button onClick={onModalClose} id="view-game-button">
            {t("game:viewGame")}
          </S.Button>
        )}
      </Footer>
    </div>
  );
};

export { BoardDrawingModal };
