import { createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { BettersAttributesCamelCase } from "../types/buckets";

interface BucketBettersState {
  bucketBetters: BettersAttributesCamelCase[];
}

const bucketBettersInitialState: BucketBettersState = {
  bucketBetters: [],
};

const bucketBettersSlice = createSlice({
  name: "bucketBetters",
  initialState: bucketBettersInitialState,
  reducers: {
    setBettersArray: (state, action) => {
      state.bucketBetters = action.payload;
    },
  },
});

export const bucketBettersReducer = bucketBettersSlice.reducer;
export const { setBettersArray } = bucketBettersSlice.actions;
export const selectBucketBetters = (state: RootState) =>
  state.bucketBetters.bucketBetters;
