import styled from "styled-components";

import closeIcon from "../../../assets/icons/close.svg";
import { spacings } from "../../../assets/styles/sizes";
import {
  OutlinedButton,
  buttonGeneralStyles,
} from "../../atoms/Button/Button.style";
import { FlexWrapper } from "../../atoms/Containers";

const AuthenticatedUserButton = styled.button`
  ${buttonGeneralStyles}
  border: none;
  border-radius: 50%;
  padding: 0;
`;

const ModalHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const CloseModalButton = styled.button.attrs({ src: closeIcon })`
  cursor: pointer;
  border: none;
  background-color: transparent;
  grid-column: 4 / span 1;
  display: inline-grid;
  justify-self: end;
  padding: ${spacings.xxs};
`;

const ModalButton = styled(OutlinedButton)`
  text-transform: none;
  margin-bottom: ${spacings.s};
`;

const ButtonContent = styled(FlexWrapper)`
  width: 100%;
`;

const ButtonLabel = styled.span`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export {
  AuthenticatedUserButton,
  CloseModalButton,
  ModalHeader,
  ModalButton,
  ButtonContent,
  ButtonLabel,
};
