import { useTranslation } from "react-i18next";

import { BetWithId } from "../../../types/bets";
import {
  calculateQuarterPotentialWinning,
  quartersWithPercentage,
  totalMyPoolValue,
} from "../../../utils/winningsCalculation";
import { formatCurrency } from "../../../utils/currency";
import { SquareValue } from "../RevealedSquare/RevealedSquare.style";
import * as S from "./PreDrawSquare.style";

interface PreDrawSquare {
  bet: BetWithId;
}

const PreDrawSquare = ({ bet }: PreDrawSquare) => {
  const { t } = useTranslation("game");

  const poolValue = formatCurrency(totalMyPoolValue(bet.amount));

  return (
    <S.SquareInfoWrapper>
      <S.SquareNotRevealed>
        <S.SquareNotRevealedLabel>{t("waitForDraw")}</S.SquareNotRevealedLabel>
      </S.SquareNotRevealed>
      <S.SquareValueSection>
        <SquareValue>{bet.amountFormatted}</SquareValue>
      </S.SquareValueSection>
      <S.SquarePoolSection>
        <SquareValue>
          {bet.quarter1PotentialWinningFormatted
            ? bet.totalPotentialWinningsFormatted
            : poolValue}
        </SquareValue>
      </S.SquarePoolSection>
      <S.SquarePrizesSection>
        {Object.keys(quartersWithPercentage).map((quarter) => {
          return (
            <S.PrizeLabel key={quarter}>
              {quarter !== "final" ? t(`q${quarter}`) : t("final")}:{" "}
              {bet.quarter1PotentialWinningFormatted
                ? quarter === "final"
                  ? bet[`${quarter}QuarterPotentialWinningFormatted`]
                  : bet[`quarter${quarter}PotentialWinningFormatted`]
                : calculateQuarterPotentialWinning(bet.amount, quarter)
                    .potentialWinningFormated}
            </S.PrizeLabel>
          );
        })}
      </S.SquarePrizesSection>
    </S.SquareInfoWrapper>
  );
};

export { PreDrawSquare };
