import styled from "styled-components";

import { IconProps } from "./Icon";

const Icon = styled.img<IconProps>`
  width: ${(props) => props.$size || props.$width};
  height: ${(props) => props.$size || props.$height};
  margin-left: ${(props) => props.$marginLeft || 0};
  margin-right: ${(props) => props.$marginRight || 0};
`;

export { Icon };
