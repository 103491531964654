import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H2, H3, H4 } from "../../atoms/Typography";

const WagerContainer = styled.div`
  background-color: ${palette.white};
  min-height: 100vh;
  height: calc(100%);
  overflow: scroll;
`;

const PaddingWrapper = styled.div`
  padding: ${spacings.xs};
  height: max-content;
`;

const GameDate = styled(H2)`
  display: inline;
  font-weight: 300;
`;

const Teams = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${spacings.l} 0;
`;

const PoolRow = styled.div`
  padding: ${spacings.xs} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled(H4)`
  text-transform: uppercase;
`;

const PayoutWrapper = styled.div`
  padding: ${spacings.xs};
`;

const QuarterPayouts = styled.div`
  display: flex;
`;

const Error = styled(H3)`
  color: ${palette.red};
  text-align: center;
`;

export {
  WagerContainer,
  PaddingWrapper,
  GameDate,
  Teams,
  PoolRow,
  Label,
  PayoutWrapper,
  QuarterPayouts,
  Error,
};
