import styled, { css } from "styled-components";

interface FlexStyleProps {
  column?: boolean;
  justifyContent?: string;
  alignItems?: string;
}

interface FlexWrapperStyleProps extends FlexStyleProps {
  padding?: string;
  margin?: string;
}

const flexStyles = css<FlexStyleProps>`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
`;

const FlexWrapper = styled.div<FlexWrapperStyleProps>`
  ${flexStyles};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};
`;

export { FlexWrapper, flexStyles };
