import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { apiClient } from "../api/apiClient";
import { ENDPOINTS } from "../api/apiEndpoints";
import {
  DeserializedJsonApiResource,
  SingleResourcePayloadSerialized,
  serializeSingleResource,
} from "../api/apiSerializer";
import { auth, getAuthHeader } from "../api/auth";
import { GenericState, ResourceDeserialized, StatusEnum } from "../types/api";
import { BetAttributes } from "../types/bets";
import { CartItem } from "../types/cart";
import { GameLiveUpdate } from "../types/games";
import { NotificationPosition, NotificationType } from "../types/notification";
import { calculateWinningsForGameParts } from "../utils/winningsCalculation";
import { resetCart } from "./cartSlice";
import { triggerNotification } from "./notificationSlice";
import { fetchBalance } from "./walletSlice";

interface BetsState
  extends Exclude<GenericState<ResourceDeserialized<BetAttributes>>, "status"> {
  placingBetStatus: StatusEnum;
  fetchBets: StatusEnum;
}

const initialState: BetsState = {
  byId: {},
  allIds: [],
  placingBetStatus: StatusEnum.Idle,
  fetchBets: StatusEnum.Idle,
};

interface RevealBetPayload {
  id: string;
}

interface CreateBetPayload {
  data: CartItem[];
}

const placeBet = createAsyncThunk(
  "bets/placeBet",
  async ({ data }: CreateBetPayload, { dispatch, rejectWithValue }) => {
    try {
      const payload = {
        "atomic:operations": data.map((el) => {
          return {
            op: "add",
            data: {
              type: "bets",
              attributes: {
                amount: el.wagerAmount,
              },
              relationships: {
                game: {
                  data: {
                    type: "games",
                    id: parseInt(el.gameId),
                  },
                },
              },
            },
          };
        }),
      };

      const response = await attemptBetPlacement(payload, dispatch, true);

      if (response) {
        return response.data;
      } else {
        return rejectWithValue({ error: "Bet placement failed" });
      }
    } catch (error) {
      dispatch(
        triggerNotification({
          type: NotificationType.ERROR,
          message: error.response.data.errors[0].code,
          position: NotificationPosition.BOTTOM,
        })
      );
      return rejectWithValue(error.response.data.errors[0]);
    }
  }
);

const attemptBetPlacement = async (
  payload: any,
  dispatch: any,
  retry = true
) => {
  try {
    const headers = await getAuthHeader();
    const response = await apiClient.post(ENDPOINTS.createBet, payload, {
      headers,
    });

    if (response.status === 200) {
      dispatch(fetchBalance());
      dispatch(resetCart());
      dispatch(
        triggerNotification({
          type: NotificationType.SUCCESS,
          message: "placing_bet_success",
          position: NotificationPosition.TOP,
        })
      );
    }

    return response;
  } catch (error) {
    if (error.response.status === 401 && retry) {
      await refreshTokenSilently();
      return await attemptBetPlacement(payload, dispatch, false);
    } else {
      throw error;
    }
  }
};

const revealBet = createAsyncThunk(
  "/bets/revealBet",
  async ({ id }: RevealBetPayload, { rejectWithValue }) => {
    try {
      const payload = serializeSingleResource({
        id: id,
        type: "bets",
        attributes: { revealed: true },
      });

      await apiClient.patch<SingleResourcePayloadSerialized<BetAttributes>>(
        ENDPOINTS.revealBet(id),
        payload
      );

      return { id };
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

const betsSlice = createSlice({
  name: "bets",
  initialState: initialState,
  reducers: {
    fetchBets: (
      state,
      action: PayloadAction<{
        [id: string]: DeserializedJsonApiResource<BetAttributes>;
      }>
    ) => {
      const bets = action.payload;

      state.byId = { ...state.byId, ...action.payload };
      state.allIds = Object.keys(bets);
    },
    resetPlacingBetStatus: (state) => {
      return { ...state, placingBetStatus: initialState.placingBetStatus };
    },
    updateBetsProbabilityAndWinning: (
      state,
      {
        payload: {
          betsIds,
          scoringScenariosMap,
          teamHome,
          teamAway,
          liveUpdate,
        },
      }: PayloadAction<{
        betsIds: string[];
        scoringScenariosMap: GameLiveUpdate["scoringScenarios"];
        teamHome: string;
        teamAway: string;
        liveUpdate: GameLiveUpdate;
      }>
    ) => {
      betsIds.map((id) => {
        const bet = state.byId[id];

        if (bet) {
          const betScoreKey = `${bet.attributes.homeScore}${bet.attributes.awayScore}`;
          const newProbability = scoringScenariosMap[betScoreKey];

          const parsedDescription = newProbability.description
            .replace("teamHome", teamHome)
            .replace("teamAway", teamAway);

          state.byId[id].attributes.scoringScenarioProbability =
            newProbability.probability;
          state.byId[id].attributes.scoringScenarioDescription =
            parsedDescription;

          const winnings = calculateWinningsForGameParts(liveUpdate, bet);
          Object.entries(winnings).forEach(([gamePartKey, winningAmount]) => {
            state.byId[id].attributes[gamePartKey] = winningAmount;
          });
        }
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(revealBet.pending, (state) => {
      state.placingBetStatus = StatusEnum.Pending;
    });
    builder.addCase(revealBet.fulfilled, (state, { payload }) => {
      state.placingBetStatus = StatusEnum.Fulfilled;
      state.byId[payload.id].attributes.revealed = true;
    });
    builder.addCase(revealBet.rejected, (state) => {
      state.placingBetStatus = StatusEnum.Rejected;
    });
    builder.addCase(placeBet.pending, (state) => {
      state.placingBetStatus = StatusEnum.Pending;
    });
    builder.addCase(placeBet.fulfilled, (state, { payload }) => {
      state.placingBetStatus = StatusEnum.Fulfilled;

      const newBets = Object.entries(
        payload.entities.bets as {
          [id: string]: DeserializedJsonApiResource<BetAttributes>;
        }
      );

      newBets.forEach((bet) => {
        const [id, betData] = bet;
        state.byId[id] = betData;
        state.allIds.push(id);
      });
    });
    builder.addCase(placeBet.rejected, (state) => {
      state.placingBetStatus = StatusEnum.Rejected;
    });
  },
});

const selectIsPlacingBetStatusPending = (state: RootState): boolean => {
  return state.bets.placingBetStatus === StatusEnum.Pending;
};

const selectIsPlacingBetStatusFulfilled = (state: RootState): boolean => {
  return state.bets.placingBetStatus === StatusEnum.Fulfilled;
};

const selectIsPlacingBetStatusRejected = (state: RootState): boolean => {
  return state.bets.placingBetStatus === StatusEnum.Rejected;
};

const refreshTokenSilently = async () => {
  try {
    // Use Auth0's getAccessTokenSilently function to refresh the token
    await auth.getAccessTokenSilently()();
  } catch (error) {
    // Handle any errors during token refresh here
    console.error("Token refresh failed", error);
  }
};

export const betsReducer = betsSlice.reducer;

export const {
  fetchBets,
  resetPlacingBetStatus,
  updateBetsProbabilityAndWinning,
} = betsSlice.actions;

export {
  placeBet,
  revealBet,
  selectIsPlacingBetStatusPending,
  selectIsPlacingBetStatusFulfilled,
  selectIsPlacingBetStatusRejected,
};
