import styled from "styled-components";

import { grey700 } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H1, H2, H3, H4 } from "../../atoms/Typography";

const SummaryWrapper = styled.div`
  margin: 0 ${spacings.l};
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  padding: ${spacings.l} 0;
`;

const Heading = styled(H2)`
  margin-bottom: ${spacings.m};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const GameDateWrapper = styled.div`
  display: flex;
`;

const GameDate = styled(H3)`
  font-weight: 700;
  margin-bottom: ${spacings.s};
`;

const Separator = styled.span`
  margin: 0 ${spacings.s};
`;

const Teams = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: ${spacings.xl};
`;

const TotalAmountLabel = styled(H1)`
  &:first-letter {
    text-transform: capitalize;
  }
`;

const QuarterPayouts = styled.div`
  display: flex;
`;

const TeamsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VersusLabel = styled(H4)`
  color: ${grey700};
  margin: 0 ${spacings.m};
  text-transform: uppercase;
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const WagerDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const WagerDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: ${spacings.l};
  margin-bottom: ${spacings.m};

  &:nth-child(2) {
    margin-bottom: 0;
  }
`;

const Label = styled.div`
  width: 50%;
  margin-right: ${spacings.m};
`;

const LabelText = styled(H3)`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const GameWrapper = styled.div`
  margin-bottom: ${spacings.l};
`;

export {
  SummaryWrapper,
  Wrapper,
  Heading,
  GameDateWrapper,
  TotalAmountLabel,
  GameDate,
  Teams,
  QuarterPayouts,
  Separator,
  TeamsWrapper,
  VersusLabel,
  DetailsWrapper,
  WagerDetails,
  WagerDetailsRow,
  Label,
  LabelText,
  GameWrapper,
};
