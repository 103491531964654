import { camelizeKeys } from "humps";
import { useEffect } from "react";

import { GameLiveUpdate } from "../types/games";
import { useAppDispatch } from "../store";
import { liveUpdateState, liveGamesUpdateReceived } from "../store/gamesSlice";

const useLiveUpdates = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const sse = new EventSource(import.meta.env.VITE_LIVE_SERVER_URL, {
      withCredentials: false,
    });

    dispatch(liveUpdateState({ liveUpdateState: sse.readyState }));

    sse.onmessage = (ev: MessageEvent<string>) => {
      const games: GameLiveUpdate[] = JSON.parse(ev.data).games;
      const camelizedGames = games.map((game) =>
        camelizeKeys<GameLiveUpdate>(game)
      );

      dispatch(
        liveGamesUpdateReceived({
          games: camelizedGames,
          liveUpdateState: sse.readyState,
        })
      );
    };

    sse.onerror = (error) => {
      console.log("error", error);
    };

    return () => {
      sse.close();
    };
  }, []);
};

export { useLiveUpdates };
