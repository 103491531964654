import { User, useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import arrowRight from "../../../assets/icons/arrow-right-blue.svg";
import closeIcon from "../../../assets/icons/close.svg";
import outIcon from "../../../assets/icons/out.svg";
import { ROUTES } from "../../../routes";
import { selectUserName } from "../../../store/userSlice";
import { Avatar } from "../../atoms/Avatar";
import { OutlinedButton } from "../../atoms/Button/Button.style";
import { FlexWrapper } from "../../atoms/Containers";
import { Icon } from "../../atoms/Icon";
import { H3Decor, H5Decor } from "../../atoms/Typography";
import { Modal, RELATIVE_BELOW } from "../Modal";
import * as S from "./AuthenticatedUserModal.style";

const ModalContent = ({
  handleCloseModal,
  user,
}: {
  handleCloseModal: () => void;
  user: User;
}) => {
  const { logout } = useAuth0();
  const { t } = useTranslation("general");
  const navigate = useNavigate();
  const firstName = useSelector(selectUserName);

  const handleNavigate = (route: string) => {
    navigate(route);
    handleCloseModal();
  };
  return (
    <FlexWrapper column alignItems="stretch">
      <S.ModalHeader>
        <FlexWrapper justifyContent="flex-start" padding="1rem 0 2rem 0">
          <Avatar size="m" user={user} />
          <FlexWrapper column alignItems="flex-start" padding="0 0 0 2rem">
            {firstName ? (
              <H3Decor>{firstName}</H3Decor>
            ) : user.given_name && user.family_name ? (
              <>
                <H3Decor>{user.given_name}</H3Decor>
                <H3Decor>{user.family_name}</H3Decor>
              </>
            ) : user.name && user.name.length > 20 ? (
              <H5Decor>{user.nickname}</H5Decor>
            ) : (
              <H5Decor>{user.name}</H5Decor>
            )}
          </FlexWrapper>
        </FlexWrapper>
        <S.CloseModalButton onClick={handleCloseModal} id="close-modal-button">
          <Icon src={closeIcon} id="close-modal-icon" />
        </S.CloseModalButton>
      </S.ModalHeader>
      <S.ModalButton
        onClick={() => handleNavigate(ROUTES.myProfile)}
        id="personal-information-button"
      >
        <S.ButtonContent>
          <S.ButtonLabel>{t("myProfile:myProfile")}</S.ButtonLabel>
          <Icon src={arrowRight} $height="1.45rem" $width="1.65rem" />
        </S.ButtonContent>
      </S.ModalButton>
      <S.ModalButton
        onClick={() => handleNavigate(ROUTES.transactions)}
        id="transaction-history-button"
      >
        <S.ButtonContent>
          <S.ButtonLabel>{t("transactions:transactionsHistory")}</S.ButtonLabel>
          <Icon src={arrowRight} $height="1.45rem" $width="1.65rem" />
        </S.ButtonContent>
      </S.ModalButton>
      <OutlinedButton onClick={() => logout()} id="logout-button">
        <S.ButtonContent>
          <span>{t("logout")}</span>
          <Icon src={outIcon} $height="1.45rem" $width="1.65rem" />
        </S.ButtonContent>
      </OutlinedButton>
    </FlexWrapper>
  );
};

const AuthenticatedUserModal = ({ user }: { user: User }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <S.AuthenticatedUserButton
        onClick={() => setModalVisible(true)}
        type="button"
      >
        <Avatar size="s" user={user} />
      </S.AuthenticatedUserButton>
      <Modal
        contentPlacing={RELATIVE_BELOW}
        modalVisible={modalVisible}
        onModalClose={() => setModalVisible(false)}
      >
        <ModalContent
          user={user}
          handleCloseModal={() => setModalVisible(false)}
        />
      </Modal>
    </>
  );
};

export { AuthenticatedUserModal };
