import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { fontSizes, spacings } from "../../../assets/styles/sizes";
import { GameStatus } from "../../../types/games";
import { H4 } from "../Typography/Typography.style";

const backgroundMap = {
  scheduled: palette.white,
  pre_draw: palette.green,
  pre_game: palette.blue300,
  live: palette.goldDark,
  finished: palette.grey700,
  open_for_bets: palette.purple,
  canceled: palette.redPale,
};

const GameStatusLabelWrapper = styled.div<{
  status: GameStatus;
  isGameModal?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    props.status === GameStatus.Scheduled
      ? `1px solid ${palette.grey400}`
      : "none"};
  padding: ${(p) =>
    p.isGameModal ? `${spacings.xs} ${spacings.xl}` : `${spacings.xxs}`};
  margin-left: ${(p) => (p.isGameModal ? "0" : spacings.xxs)};
  background-color: ${(props) => backgroundMap[props.status]};
  white-space: nowrap;
`;

const LabelText = styled.span<{ isGameModal?: boolean }>`
  font-size: ${(p) => (p.isGameModal ? fontSizes.m : fontSizes.sm)};
  text-transform: uppercase;
  font-weight: 500;
  color: ${palette.blue500};
`;

export { GameStatusLabelWrapper, LabelText };
