import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import circlePlus from "../../../assets/icons/circle-plus.svg";
import wallet from "../../../assets/icons/wallet.svg";
import { selectBalanceFormatted } from "../../../store/walletSlice";
import { Icon } from "../../atoms/Icon";
import * as S from "./WalletTopOffButton.style";

interface WalletTopOffButtonProps {
  onClick: () => void;
}

const WalletTopOffButton = ({ onClick }: WalletTopOffButtonProps) => {
  const { t } = useTranslation("wallet");
  const balance = useSelector(selectBalanceFormatted);

  return (
    <S.TopOffButton onClick={onClick} id="wallet-top-off">
      <Icon src={wallet} />
      <S.AmountWrapper>
        <S.Label>{t("myWallet")}</S.Label>
        <S.AmountLabel>{balance}</S.AmountLabel>
      </S.AmountWrapper>
      <Icon src={circlePlus} id="wallet-top-off-icon" />
    </S.TopOffButton>
  );
};

export { WalletTopOffButton };
