import styled from "styled-components";

import { grey500, grey700 } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H4 } from "../../atoms/Typography";

const GameRow = styled.div`
  padding: ${spacings.s};
  border-bottom: 1px solid ${grey500};
`;

const GameDate = styled(H4)`
  text-transform: capitalize;
`;

const Separator = styled.span`
  margin: 0 ${spacings.s};
`;

const GameDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TeamsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacings.m};
`;

const VersusLabel = styled(H4)`
  color: ${grey700};
  margin: 0 ${spacings.l};
  text-transform: uppercase;
`;

const FormWrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  font-size: 24px;
  font-family: "Space Grotesk";
  font-weight: 700;
  border: none;
  width: 70px;
  text-align: center;
`;

export {
  GameRow,
  GameDate,
  Separator,
  GameDetails,
  TeamsWrapper,
  VersusLabel,
  FormWrapper,
  Input,
};
