import cardinals from "../../../assets/icons/Arizona-Cardinals.svg";
import falcons from "../../../assets/icons/Atlanta-Falcons.svg";
import ravens from "../../../assets/icons/Baltimore-Ravens.svg";
import bills from "../../../assets/icons/Buffalo-Bills.svg";
import panthers from "../../../assets/icons/Carolina-Panthers.svg";
import bears from "../../../assets/icons/Chicago-Bears.svg";
import bengals from "../../../assets/icons/Cincinnati-Bengals.svg";
import browns from "../../../assets/icons/Cleveland-Browns.svg";
import cowboys from "../../../assets/icons/Dallas-Cowboys.svg";
import broncos from "../../../assets/icons/Denver-Broncos.svg";
import lions from "../../../assets/icons/Detroit-Lions.svg";
import packers from "../../../assets/icons/GreenBay-Packers.svg";
import texans from "../../../assets/icons/Houston-Texans.svg";
import colts from "../../../assets/icons/Indianapolis-Colts.svg";
import jaguars from "../../../assets/icons/Jacksonville-Jaguars.svg";
import chiefs from "../../../assets/icons/KansasCity-Chiefs.svg";
import raiders from "../../../assets/icons/LasVegas-Raiders.svg";
import chargers from "../../../assets/icons/LosAngeles-Chargers.svg";
import rams from "../../../assets/icons/LosAngeles-Rams.svg";
import dolphins from "../../../assets/icons/Miami-Dolphins.svg";
import vikings from "../../../assets/icons/Minnesota-Vikings.svg";
import patriots from "../../../assets/icons/NewEngland-Patriots.svg";
import saints from "../../../assets/icons/NewOrleans-Saints.svg";
import giants from "../../../assets/icons/NewYork-Giants.svg";
import jets from "../../../assets/icons/NewYork-Jets.svg";
import eagles from "../../../assets/icons/Philadelphia-Eagles.svg";
import steelers from "../../../assets/icons/Pittsburgh-Steelers.svg";
import sf49ers from "../../../assets/icons/SanFrancisco-49.svg";
import seahawks from "../../../assets/icons/Seattle-Seahawks.svg";
import buccaneers from "../../../assets/icons/Tampa-Buccaneers.svg";
import titans from "../../../assets/icons/Tennessee-Titans.svg";
import commanders from "../../../assets/icons/Washington-Commanders.svg";
import { teamColors } from "../../../assets/styles/colors";
import { TeamAttributes } from "../../../types/teams";
import { Icon } from "../Icon";
import { H3 } from "../Typography";
import * as S from "./Team.style";

const teamIcons = {
  cardinals: cardinals,
  falcons: falcons,
  ravens: ravens,
  bills: bills,
  panthers: panthers,
  bears: bears,
  bengals: bengals,
  browns: browns,
  cowboys: cowboys,
  broncos: broncos,
  lions: lions,
  packers: packers,
  texans: texans,
  colts: colts,
  jaguars: jaguars,
  chiefs: chiefs,
  raiders: raiders,
  chargers: chargers,
  rams: rams,
  dolphins: dolphins,
  vikings: vikings,
  patriots: patriots,
  giants: giants,
  jets: jets,
  saints: saints,
  eagles: eagles,
  steelers: steelers,
  "49ers": sf49ers,
  seahawks: seahawks,
  buccaneers: buccaneers,
  titans: titans,
  commanders: commanders,
};

const getIcon = (team: TeamAttributes): string =>
  teamIcons[team.teamName.toLocaleLowerCase()];

const getTeamColor = (team: TeamAttributes): string =>
  teamColors[team.teamName.toLocaleLowerCase()];

const TeamTile = ({
  team,
  $scoresDisplayed,
  $secondTile,
  $size,
}: {
  team: TeamAttributes;
  $scoresDisplayed: boolean;
  $secondTile?: boolean;
  $size?: "large" | "medium" | "small";
}) => {
  return (
    <S.TeamTileWrapper $size={$size} $bg={getTeamColor(team)}>
      <S.TeamGradientLinear
        $scoresDisplayed={$scoresDisplayed}
        $secondTile={$secondTile}
      >
        {$size !== "small" && (
          <S.TeamGradientRadial
            $size={$size}
            $scoresDisplayed={$scoresDisplayed}
          >
            <Icon
              $size={$size === "large" ? "103px" : $size === "medium" && "70px"}
              src={getIcon(team)}
              id={`team-tile-icon-${team.teamName}`}
            />
          </S.TeamGradientRadial>
        )}
        <S.TeamLocation>{team.cityName}</S.TeamLocation>
        <S.TeamName>{team.teamName}</S.TeamName>
      </S.TeamGradientLinear>
    </S.TeamTileWrapper>
  );
};

const TeamLabel = ({
  team,
  abbreviated,
  bigFont,
  isLiveGame,
}: {
  team: TeamAttributes;
  abbreviated?: boolean;
  bigFont?: boolean;
  isLiveGame?: boolean;
}) => {
  return (
    <S.TeamLabelWrapper abbreviated={abbreviated} isLiveGame={isLiveGame}>
      <S.TeamLabelLogo src={getIcon(team)} $bg={getTeamColor(team)} />
      {abbreviated ? (
        <S.AbbreviatedLabel>{team.abbreviation}</S.AbbreviatedLabel>
      ) : (
        <S.ColumnWrapper>
          <S.TeamLabelLocation bigFont={bigFont}>
            {team.cityName}
          </S.TeamLabelLocation>
          {bigFont ? (
            <S.TeamLabelName>{team.teamName}</S.TeamLabelName>
          ) : (
            <H3>{team.teamName}</H3>
          )}
        </S.ColumnWrapper>
      )}
    </S.TeamLabelWrapper>
  );
};

export { TeamTile, TeamLabel, teamIcons, getIcon, getTeamColor };
