import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { GameDetails } from "../../../types/games";
import { GameStatusLabel } from "../../atoms/GameStatusLabel";
import { TeamTile, getTeamColor } from "../../atoms/Team";
import { Ticket } from "../../atoms/Ticket";
import { TvStationIcon } from "../../atoms/TvStationIcon";
import { QuarterScoresTable } from "../../molecules/QuarterScoresTable";
import { Square } from "../../molecules/Square";
import * as S from "./GameDetailsModal.style";

const LiveGameDetailsModal = ({
  game,
  isScrolled,
}: {
  game: GameDetails;
  isScrolled: boolean;
}) => {
  const { t } = useTranslation(["game", "general", "buySquare"]);

  const withQuarterInfo = game.isLive && game.quarter !== "F" && game.timeLeft;
  const timerText =
    game.isLive &&
    ((game.quarter === "Half" && `${t("timer:halftime")}`) ||
      (game.quarter === "OT" &&
        `${t("timer:timeLeftInOvertime", {
          timeLeft: game.timeLeft,
        })}`) ||
      `${t("timer:timeLeftInQuarter", {
        quarter: game.quarter,
        timeLeft: game.timeLeft,
      })}`);
  const teamTileSize = isScrolled ? "small" : "medium";

  const homeTeamColor = getTeamColor(game.teamHome);
  const awayTeamColor = getTeamColor(game.teamAway);

  return (
    <>
      <S.GameDetails>
        <GameStatusLabel
          isGameModal
          status={game.status}
          quarter={game.quarter || "1"}
          timeLeft={game.timeLeft}
        />
        <S.GameSecondaryHeadingWrapper>
          <S.GameSecondaryHeading>
            {`${format(new Date(game.startTime), "E M/dd")} | `}
            {format(new Date(game.startTime), "p")}
          </S.GameSecondaryHeading>
          <S.GameDetailsWrapper>
            {game.tvStation &&
              (<TvStationIcon tvStation={game.tvStation} /> || (
                <S.GameSecondaryHeading>
                  {game.tvStation}
                </S.GameSecondaryHeading>
              ))}
            <S.StadiumDetails>
              {game.stadium && game.tvStation && ` | `}
              {game.stadium}
            </S.StadiumDetails>
          </S.GameDetailsWrapper>
        </S.GameSecondaryHeadingWrapper>
      </S.GameDetails>
      <S.SectionWrapper $scoresDisplayed>
        <TeamTile $size={teamTileSize} team={game.teamHome} $scoresDisplayed />
        <TeamTile
          $size={teamTileSize}
          team={game.teamAway}
          $scoresDisplayed
          $secondTile
        />
        <S.ScoreWrapper $isScrolled={isScrolled} $scoresDisplayed>
          <S.ScoreLabel>{`${game.homeScore} - ${game.awayScore}`}</S.ScoreLabel>
          {withQuarterInfo && <S.Timer>{timerText}</S.Timer>}
        </S.ScoreWrapper>
      </S.SectionWrapper>
      <S.HorizontalPadding>
        <QuarterScoresTable
          homeTeamColor={homeTeamColor}
          awayTeamColor={awayTeamColor}
          quarter1HomeScore={game.quarter1HomeScore}
          quarter1AwayScore={game.quarter1AwayScore}
          quarter2HomeScore={game.quarter2HomeScore}
          quarter2AwayScore={game.quarter2AwayScore}
          quarter3HomeScore={game.quarter3HomeScore}
          quarter3AwayScore={game.quarter3AwayScore}
          finalQuarterHomeScore={game.finalQuarterHomeScore}
          finalQuarterAwayScore={game.finalQuarterAwayScore}
        />
        <Ticket
          $horizontal
          amountFormatted={game.totalPoolValueFormatted}
          label={t("totalPoolLabel")}
        />
        {game.hasBets && (
          <Ticket
            $horizontal
            amountFormatted={game.maxPotentialPayoutFormatted}
            label={t("maxPayout")}
          />
        )}
      </S.HorizontalPadding>
      {game.hasBets && (
        <S.SquaresSection>
          <S.SquaresHeading>
            <S.SquaresTitle>
              {t("mySquares")}: {game.bets.length}
            </S.SquaresTitle>
          </S.SquaresHeading>
          {game.betsSorted.map((bet) => (
            <Square
              key={bet.id}
              id={bet.id}
              gameStatus={game.status}
              teamHome={game.teamHome}
              teamAway={game.teamAway}
              bet={bet}
              quarter={game.quarter || "1"}
            />
          ))}
        </S.SquaresSection>
      )}
    </>
  );
};

export { LiveGameDetailsModal };
