import axios, { AxiosResponse } from "axios";

import { deserialize } from "./apiSerializer";

const apiClient = axios.create({
  baseURL: `${import.meta.env.VITE_APP_API_ENDPOINT}`,
});

apiClient.interceptors.response.use((response: AxiosResponse) => {
  if (response.data.message) {
    return response;
  } else {
    const deserializedData = deserialize(response.data);
    return { ...response, data: deserializedData };
  }
});

export { apiClient };
