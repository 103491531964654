import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import {
  elementSizes,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { buttonGeneralStyles } from "../../atoms/Button/Button.style";
import { H2Decor, H4Decor } from "../../atoms/Typography";

const GameWrapper = styled.div<{
  finished: boolean;
  scoreChanged?: boolean;
  addedToCart?: boolean;
}>`
  font-size: ${fontSizes.s};
  background-color: ${(p) =>
    p.finished
      ? palette.greyLight07
      : p.addedToCart
      ? palette.lightPurple02
      : palette.white};
  border: 1px solid ${palette.grey400};
  border-radius: ${spacings.xxs};
  box-shadow: 0px 1px 2px 1px ${palette.greyLight07};
  margin-bottom: ${spacings.s};
  animation: ${(p) => (p.scoreChanged ? "pulse 1.5s infinite" : "none")};

  &:last-of-type {
    margin-bottom: ${elementSizes.bottomArea};
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0;
    }

    50% {
      box-shadow: 0 0 0 2px ${palette.gold};
    }

    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }
  }
`;

const GameHeader = styled.div`
  padding: ${spacings.xxs} ${spacings.s};
  border-bottom: 1px solid ${palette.grey400};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const GameHeaderDetailWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.sm};
`;

const GameHeaderDetail = styled.span`
  padding-right: ${spacings.xxs};

  &:last-child {
    padding: 0;
  }
`;

const BetsCountInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacings.xxs};
  margin-left: ${spacings.xxs};
  background-color: ${palette.blue200};
`;

const BetsCountValue = styled.span`
  font-size: ${fontSizes.sm};
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  margin-right: ${spacings.xxs};
  color: ${palette.black};
`;

const GameContent = styled.div<{ isLiveGame?: boolean }>`
  margin: ${spacings.m} ${spacings.sm};
  display: grid;
  grid-template-columns: ${(p) =>
    p.isLiveGame ? "repeat(8, 1fr)" : "repeat(7, 1fr)"};
`;

const PoolValue = styled.span`
  font-weight: 700;
  font-size: ${fontSizes.xxl};
  line-height: ${lineHeights.xxl};
`;

const PoolValueLabel = styled.span`
  color: ${palette.grey700};
  text-transform: uppercase;
  line-height: ${lineHeights.s};
`;

const PoolWrapper = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  grid-column-start: 7;
  grid-column-end: 8;
  text-align: end;
`;

const BottomSectionWrapper = styled.div<{ isAdditionalInfo?: boolean }>`
  display: flex;
  flex-direction: ${(p) => (p.isAdditionalInfo ? "row" : "row-reverse")};
  justify-content: space-between;
  align-items: center;
  margin: ${spacings.s} ${spacings.sm};
`;

const ScoresWrapper = styled.div`
  grid-column-start: 4;
  grid-column-end: 6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScoreLabel = styled(H2Decor)<{ scoreChanged?: boolean }>`
  white-space: nowrap;
  animation: ${(p) => (p.scoreChanged ? "highlight 1.5s infinite" : "none")};

  @keyframes highlight {
    50% {
      color: ${palette.goldDark};
      transform: scale(1.3);
    }
  }
`;

const TotalWinningsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TotalWinningsLabel = styled(H4Decor)`
  margin-right: ${spacings.xs};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const WinningsAmount = styled(H4Decor)<{ hasWinnings?: boolean }>`
  color: ${(props) => props.hasWinnings && palette.goldDark};
  font-size: ${(props) => props.hasWinnings && fontSizes.l};
`;

const RefreshButton = styled.button`
  ${buttonGeneralStyles}
  background-color:transparent;
  color: ${palette.darkGreen};
  padding: ${spacings.s};
`;

const IconButtonsWrapper = styled.div`
  display: flex;
`;

const IconButton = styled.button`
  ${buttonGeneralStyles}
  padding: ${spacings.xs} ${spacings.sm};
  display: flex;
  align-items: center;
`;

const CartButton = styled(IconButton)<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected ? palette.gold : palette.grey400};
  margin-right: ${spacings.xs};
  padding: ${spacings.xs} ${spacings.s};
`;

export {
  GameWrapper,
  GameHeader,
  GameHeaderDetailWrapper,
  GameHeaderDetail,
  BetsCountInfoWrapper,
  BetsCountValue,
  PoolWrapper,
  PoolValue,
  PoolValueLabel,
  GameContent,
  BottomSectionWrapper,
  ScoresWrapper,
  ScoreLabel,
  TotalWinningsWrapper,
  TotalWinningsLabel,
  WinningsAmount,
  RefreshButton,
  IconButtonsWrapper,
  IconButton,
  CartButton,
};
