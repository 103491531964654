import { User } from "@auth0/auth0-react";

import { getInitials } from "../../../utils/userInitials";
import * as S from "./Avatar.style";

interface AvatarProps {
  user: User;
  size?: "s" | "m" | "l";
}

const Avatar = ({ user, size }: AvatarProps) => {
  const initials = getInitials(user);

  return (
    <S.AvatarWrapper size={size}>
      <S.AvatarText size={size}>{initials}</S.AvatarText>
    </S.AvatarWrapper>
  );
};

export { Avatar };
