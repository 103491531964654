import close from "../../../assets/icons/close.svg";
import { blue400, white } from "../../../assets/styles/colors";
import {
  NotificationState,
  NotificationType,
} from "../../../types/notification";
import { IconColor } from "../IconColor";
import { H5Decor } from "../Typography";
import * as S from "./Notification.style";

interface NotificationProps extends NotificationState {
  onClose: () => void;
  id: string;
  isPageError?: boolean;
}

const Notification = ({
  type,
  message,
  position,
  onClose,
  id,
}: NotificationProps) => {
  const isErrror = type === NotificationType.ERROR;

  return (
    <S.NotificationWrapper type={type} id={id} position={position}>
      <H5Decor>{message}</H5Decor>
      <S.CloseButton onClick={onClose} id="notification-close-button">
        <IconColor
          src={close}
          color={isErrror ? white : blue400}
          $size="15px"
        />
      </S.CloseButton>
    </S.NotificationWrapper>
  );
};

export { Notification };
