import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from ".";
import { NotificationState } from "../types/notification";

const initialState: NotificationState = {
  type: undefined,
  message: undefined,
  position: undefined,
};

const notificationSlice = createSlice({
  name: "notifiation",
  initialState,
  reducers: {
    triggerNotification: (state, action: PayloadAction<NotificationState>) => {
      state.type = action.payload.type;
      state.message = action.payload.message;
      state.position = action.payload.position;
    },
    clearNotification: (state) => (state = initialState),
  },
});

export const notificationReducer = notificationSlice.reducer;
export const { triggerNotification, clearNotification } =
  notificationSlice.actions;

export const selectNotification = (state: RootState) => state.notification;
