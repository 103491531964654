import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { GameDetails, GameStatus } from "../../../types/games";
import { GameStatusLabel } from "../../atoms/GameStatusLabel";
import { TeamTile } from "../../atoms/Team";
import { Ticket } from "../../atoms/Ticket";
import { TvStationIcon } from "../../atoms/TvStationIcon";
import { Square } from "../../molecules/Square";
import * as S from "./GameDetailsModal.style";

const BeforeGameDetailsModal = ({ game }: { game: GameDetails }) => {
  const { t } = useTranslation("game");

  const scoresDisplayed = game.isLive || game.status === GameStatus.Finished;
  const potentialPayoutDisplayed =
    game.hasBets &&
    (game.status === GameStatus.OpenForBets ||
      game.status === GameStatus.PreDraw ||
      game.status === GameStatus.PreGame);
  const squaresHeaderDisplayed =
    game.status === GameStatus.OpenForBets ||
    game.status === GameStatus.PreDraw ||
    game.status === GameStatus.PreGame;

  return (
    <>
      <S.GameDetails>
        <GameStatusLabel
          isGameModal
          status={game.status}
          quarter={game.quarter || "1"}
          timeLeft={game.timeLeft}
        />
        <S.GameDetailsHeading>
          {format(new Date(game.startTime), "E M/dd")}
        </S.GameDetailsHeading>
        <S.GameSecondaryHeadingWrapper>
          <S.GameSecondaryHeading>
            {format(new Date(game.startTime), "p")}
          </S.GameSecondaryHeading>
          <S.GameDetailsWrapper>
            {game.tvStation &&
              (<TvStationIcon tvStation={game.tvStation} /> || (
                <S.GameSecondaryHeading>
                  {game.tvStation}
                </S.GameSecondaryHeading>
              ))}
            <S.StadiumDetails>
              {game.stadium && game.tvStation && ` | `}
              {game.stadium}
            </S.StadiumDetails>
          </S.GameDetailsWrapper>
        </S.GameSecondaryHeadingWrapper>
      </S.GameDetails>
      <S.SectionWrapper $scoresDisplayed={scoresDisplayed}>
        <TeamTile
          $size="large"
          team={game.teamHome}
          $scoresDisplayed={scoresDisplayed}
        />
        <TeamTile
          $size="large"
          team={game.teamAway}
          $scoresDisplayed={scoresDisplayed}
          $secondTile
        />
        <S.VersusLabel>vs</S.VersusLabel>
      </S.SectionWrapper>

      <S.SectionWrapper>
        <Ticket
          amountFormatted={game.totalPoolValueFormatted}
          label={t("totalPoolLabel")}
        />
        <Ticket
          amountFormatted={game.averageBucketSizeFormatted}
          label={t("maxBet")}
        />
      </S.SectionWrapper>
      {potentialPayoutDisplayed && (
        <S.TotalPayoutWrapper>
          <Ticket
            $horizontal
            amountFormatted={game.maxPotentialPayoutFormatted}
            label={t("maxPayout")}
          />
        </S.TotalPayoutWrapper>
      )}
      {game.hasBets && (
        <S.SquaresSection>
          <S.SquaresHeading>
            <S.SquaresTitle>
              {t("mySquares")}: {game.bets.length}
            </S.SquaresTitle>
          </S.SquaresHeading>
          {squaresHeaderDisplayed && (
            <S.SquaresHeaderWrapper>
              <S.SquaresHeader>{t("myNumbers")}</S.SquaresHeader>
              <S.SquaresHeader>{t("squareValue")}</S.SquaresHeader>
              <S.SquaresHeader>{t("myPool")}</S.SquaresHeader>
              <S.SquaresHeader>{t("prizes")}</S.SquaresHeader>
            </S.SquaresHeaderWrapper>
          )}
          {game.betsSorted.map((bet) => (
            <Square
              key={bet.id}
              id={bet.id}
              gameStatus={game.status}
              teamHome={game.teamHome}
              teamAway={game.teamAway}
              bet={bet}
              quarter={game.quarter || "1"}
            />
          ))}
        </S.SquaresSection>
      )}
    </>
  );
};

export { BeforeGameDetailsModal };
