import { GameStatus } from "./games";

const ALL_GAMES = "all";
const ALL_DAYS = "wholeWeek";

const filtersMap = {
  week: "week",
  status: "status",
  seasonPhase: "seasonPhase",
  myGames: "myGames",
};

const gameStatusFilters: (
  | Exclude<GameStatus, GameStatus.Scheduled | GameStatus.Canceled>
  | typeof ALL_GAMES
)[] = [
  ALL_GAMES,
  GameStatus.OpenForBets,
  GameStatus.PreDraw,
  GameStatus.PreGame,
  GameStatus.Live,
  GameStatus.Finished,
];

type GameStatusFiltersValues =
  | (typeof gameStatusFilters)[number]
  | typeof ALL_GAMES;

export { gameStatusFilters, filtersMap, ALL_GAMES, ALL_DAYS };
export type { GameStatusFiltersValues };
