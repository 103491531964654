import styled from "styled-components";

import { grey300 } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";

const GameBalanceSection = styled.div`
  display: grid;
  padding: ${spacings.m} ${spacings.s};
  background-color: ${grey300};
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: ${spacings.m};
  margin-bottom: 150px;
`;

export { GameBalanceSection };
