import { createContext, useContext, useState } from "react";

export const GameContext = createContext({
  modalVisible: false,
  gameId: "",
  handleViewGameDetails: (gameId: string) => {},
  handleGameDetailsClose: () => {},
});

export const useGameContext = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [gameId, setGameId] = useState("");

  const handleViewGameDetails = (gameId: string) => {
    setModalVisible(true);
    setGameId(gameId);
  };

  const handleGameDetailsClose = () => {
    setModalVisible(false);
    setGameId("");
  };

  return (
    <GameContext.Provider
      value={{
        modalVisible,
        gameId,
        handleViewGameDetails,
        handleGameDetailsClose,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};
