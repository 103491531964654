import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  clearNotification,
  selectNotification,
} from "../../../store/notificationSlice";
import { Notification } from "./Notification";

const NotificationRenderer = () => {
  const { t } = useTranslation("notification");
  const dispatch = useDispatch();
  const notification = useSelector(selectNotification);

  const onCloseNotification = () => {
    dispatch(clearNotification());
  };

  useEffect(() => {
    notification.message && setTimeout(onCloseNotification, 6500);
  }, [notification.message]);

  return (
    notification.type && (
      <Notification
        type={notification.type}
        message={t(notification.message)}
        position={notification.position}
        onClose={onCloseNotification}
        id={`notification-${notification.type}`}
      />
    )
  );
};

export default NotificationRenderer;
