import styled from "styled-components";

import { white } from "../../../assets/styles/colors";
import { H5Decor } from "../Typography";

const QuarterScore = styled(H5Decor)<{ background: string }>`
  text-align: center;
  color: ${white};
  background-color: ${(p) => p.background};
  flex-grow: 1;
  height: 20px;
  margin: 1px;
`;

export { QuarterScore };
