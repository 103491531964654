import { useTranslation } from "react-i18next";

import { boardSquareColors as colors } from "../../../assets/styles/colors";
import * as S from "./BoardLegend.style";

const BoardLegend = () => {
  const { t } = useTranslation("boardLegend");

  const quarters = ["q1", "q2", "q3", "final"];

  return (
    <S.BoardLegend>
      {quarters.map((quarter) => (
        <S.BoardLegendItem key={quarter} id={`board-legend-${quarter}`}>
          <S.BoardLegendItemColor color={colors[quarter]} />
          <S.BoardLegendItemLabel color={colors[quarter]}>
            {t(quarter)}
          </S.BoardLegendItemLabel>
        </S.BoardLegendItem>
      ))}
    </S.BoardLegend>
  );
};

export { BoardLegend };
