export interface NotificationState {
  type?: NotificationType;
  message?: string;
  position?: NotificationPosition;
}

export enum NotificationType {
  SUCCESS = "success",
  ERROR = "error",
}

export enum NotificationPosition {
  TOP = "top",
  BOTTOM = "bottom",
}
