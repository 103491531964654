import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { LoadingAnimation } from "../../components/atoms/LoadingAnimation";
import {
  FULL_WIDTH_BOTTOM_FULL_HEIGHT,
  Modal,
} from "../../components/molecules/Modal";
import { WalletFunds } from "../../components/molecules/WalletFunds";
import { TopOffWalletModal } from "../../components/organisms/TopOffWalletModal";
import { useAppDispatch } from "../../store";
import {
  fetchTransactions,
  selectIsFetchTransactionsPending,
  selectTransactions,
} from "../../store/transactionsSlice";
import * as S from "./Transactions.style";

const Transactions = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const { t } = useTranslation("transactions");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const transactions = useSelector(selectTransactions);
  const isFetchTransactionsPending = useSelector(
    selectIsFetchTransactionsPending
  );

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  return (
    <S.Wrapper>
      <WalletFunds dark />
      <S.ButtonsWrapper>
        <S.PrimaryButton onClick={() => setModalVisible(true)}>
          {t("wallet:topOffWallet")}
        </S.PrimaryButton>
        <S.SecondaryButton onClick={() => navigate("/")}>
          {t("general:allGames")}
        </S.SecondaryButton>
      </S.ButtonsWrapper>
      <S.TransactionsWrapper>
        {isFetchTransactionsPending ? (
          <LoadingAnimation label={t("general:loading")} />
        ) : (
          <>
            {Object.entries(transactions).map(
              ([dateFormatted, transacationsForDate]) => {
                return (
                  <div key={dateFormatted}>
                    <S.TransactionsHeadings>
                      <S.HeadingLabel>{dateFormatted}</S.HeadingLabel>
                      <S.HeadingLabel>
                        {t("transactions:transactionType")}
                      </S.HeadingLabel>
                      <S.HeadingLabel>{t("general:amount")}</S.HeadingLabel>
                    </S.TransactionsHeadings>
                    {transacationsForDate.map((el) => {
                      return (
                        <S.TransactionRow key={el.id}>
                          <S.TransactionLabel>
                            {el.attributes.createdAt}
                          </S.TransactionLabel>
                          <S.TransactionLabel>
                            {t(el.attributes.event)}
                          </S.TransactionLabel>
                          <S.TransactionLabel>
                            {el.attributes.walletBalanceChange}
                          </S.TransactionLabel>
                        </S.TransactionRow>
                      );
                    })}
                  </div>
                );
              }
            )}
          </>
        )}
      </S.TransactionsWrapper>
      <Modal
        contentPlacing={FULL_WIDTH_BOTTOM_FULL_HEIGHT}
        modalVisible={modalVisible}
        onModalClose={() => setModalVisible(false)}
      >
        <TopOffWalletModal onModalClose={() => setModalVisible(false)} />
      </Modal>
    </S.Wrapper>
  );
};

export { Transactions };
