import styled, { keyframes } from "styled-components";

import {
  grey300,
  grey400,
  grey600,
  grey700,
  grey900,
  white,
} from "../../../assets/styles/colors";
import { fontSizes, spacings } from "../../../assets/styles/sizes";
import { H3 } from "../../atoms/Typography";

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

const TableSectionWrapper = styled.div<{
  isSlidingOut: boolean;
}>`
  display: grid;
  grid-gap: ${spacings.l};
  margin-top: ${spacings.m};
  color: ${grey900};
  animation: ${({ isSlidingOut }) => (isSlidingOut ? slideOut : slideIn)} 1s
    ease forwards;
`;

const SectionHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${spacings.m};
  align-items: center;
  justify-content: space-between;
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-transform: uppercase;
`;

const BettersTable = styled.table`
  width: 100%;
  padding: 0 10px;
  border-radius: 10px !important;
  border: 1px solid ${grey400};
  background-color: ${grey400};
  display: table;
  overflow: hidden !important;
`;

const TableHeader = styled.thead`
  height: 3rem;
`;

const TableHeaderRow = styled.tr`
  height: 3rem;
`;

const TableHeaderCell = styled.th<{ order?: number }>`
  position: relative;
  font-size: ${fontSizes.sm};
  font-family: "Space Grotesk";
  font-weight: 700;
  vertical-align: middle;
  padding: 0 16px;
  width: 3rem;
  background-color: ${grey300};
  text-transform: uppercase;
  text-align: left;
  &:not(:first-child) {
    text-align: right;
  }
`;

const TableBody = styled.tbody`
  height: 3rem;
  background-color: ${white};
  position: relative;
`;

const TableRow = styled.tr<{ isVisible: boolean }>`
  height: 3rem;
  border-bottom: 1px solid ${grey400};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 2s ease-in-out;
`;

const TableCell = styled.td`
  vertical-align: middle;
  text-align: right;
  padding: 0 16px;
  background-color: ${white};
  font-size: ${fontSizes.sm};
  font-family: "Space Grotesk";
  font-weight: 500;
  height: 4rem;
  width: 3rem;
  text-align: left;
  &:not(:first-child) {
    text-align: right;
  }
`;

const TotalRow = styled.tr<{ isVisible: boolean }>`
  height: 3rem;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 2s ease-in-out;
`;

const TotalCell = styled.td`
  vertical-align: middle;
  text-align: right;
  padding: 0 16px;
  font-size: ${fontSizes.sm};
  font-family: "Space Grotesk";
  font-weight: 700;
  height: 3rem;
  width: 3rem;
  background-color: ${white};
  text-transform: uppercase;
  border-top: 2px solid ${grey600};
  &:first-child {
    font-family: "Roboto";
    font-weight: 500;
    color: ${grey700};
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 20rem;
`;

const NoBettersInfo = styled(H3)`
  padding: ${spacings.l} ${spacings.m};
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export {
  slideIn,
  slideOut,
  TableSectionWrapper,
  SectionHeader,
  BettersTable,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TotalRow,
  TotalCell,
  LoadingWrapper,
  NoBettersInfo,
};
