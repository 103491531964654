import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

import { RootState } from ".";
import { apiClient } from "../api/apiClient";
import { ENDPOINTS } from "../api/apiEndpoints";
import {
  DeserializedJsonApiResource,
  DeserializedJsonApiResponse,
} from "../api/apiSerializer";
import { getAuthHeader } from "../api/auth";
import { GenericState, ResourceDeserialized, StatusEnum } from "../types/api";
import { BucketAttributes, IncludedBetters } from "../types/buckets";
import { setBettersArray } from "./bucketBettersSlice";

interface BucketState
  extends Exclude<
    GenericState<ResourceDeserialized<BucketAttributes>>,
    "status"
  > {
  fetchBucketsStatus: StatusEnum;
  fetchFilteredBucketByScoreStatus: StatusEnum;
  fetchFilteredBucketByIndexStatus: StatusEnum;
  filteredBucket: DeserializedJsonApiResource<BucketAttributes>[];
}

const bucketInitialState: BucketState = {
  byId: {},
  allIds: [],
  fetchBucketsStatus: StatusEnum.Idle,
  fetchFilteredBucketByScoreStatus: StatusEnum.Idle,
  fetchFilteredBucketByIndexStatus: StatusEnum.Idle,
  error: null,
  filteredBucket: [],
};

interface BucketFilters {
  gameId: string;
  homeScore?: number;
  awayScore?: number;
  homeIndex?: number;
  awayIndex?: number;
}

const fetchBuckets = createAsyncThunk(
  "fetchAllBuckets",
  async ({ gameId }: BucketFilters, { dispatch, rejectWithValue }) => {
    try {
      const headers = await getAuthHeader();
      const response = (await apiClient.get(ENDPOINTS.getBuckets(gameId), {
        headers,
      })) as AxiosResponse<
        DeserializedJsonApiResponse<BucketAttributes, IncludedBetters>
      >;
      return response.data;
    } catch (error) {
      console.error;
      return rejectWithValue(error.response.data.error);
    }
  }
);

const fetchFilteredBucketByScore = createAsyncThunk(
  "fetchFilteredBucketByScore",
  async (
    { gameId, homeScore, awayScore }: BucketFilters,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const headers = await getAuthHeader();
      const response = await apiClient.get(
        ENDPOINTS.getFilteredBucketByScore(gameId, homeScore, awayScore),
        { headers }
      );

      const betters = response.data.entities.betters as {
        [id: string]: DeserializedJsonApiResource<IncludedBetters>;
      };

      const bettersArray = Object.values(betters).map(
        (better) => better.attributes
      );

      dispatch(setBettersArray(bettersArray));

      return response.data;
    } catch (error) {
      console.error;
      return rejectWithValue(error.response.data.error);
    }
  }
);

const fetchFilteredBucketByIndex = createAsyncThunk(
  "fetchFilteredBucketByIndex",
  async (
    { gameId, homeIndex, awayIndex }: BucketFilters,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const headers = await getAuthHeader();
      const response = await apiClient.get(
        ENDPOINTS.getFilteredBucketByIndex(gameId, homeIndex, awayIndex),
        { headers }
      );
      const betters = response.data.entities.betters as {
        [id: string]: DeserializedJsonApiResource<IncludedBetters>;
      };

      const bettersArray = Object.values(betters).map(
        (better) => better.attributes
      );

      dispatch(setBettersArray(bettersArray));

      return response.data;
    } catch (error) {
      console.error;
      return rejectWithValue(error.response.data.error);
    }
  }
);

const bucketsSlice = createSlice({
  name: "buckets",
  initialState: bucketInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuckets.pending, (state) => {
        state.fetchBucketsStatus = StatusEnum.Pending;
      })
      .addCase(fetchBuckets.fulfilled, (state, { payload }) => {
        state.fetchBucketsStatus = StatusEnum.Fulfilled;
        const buckets = payload.entities.buckets as {
          [id: string]: DeserializedJsonApiResource<BucketAttributes>;
        };
        state.byId = buckets;
        state.allIds = payload.order;
      })
      .addCase(fetchBuckets.rejected, (state) => {
        state.fetchBucketsStatus = StatusEnum.Rejected;
      })
      .addCase(fetchFilteredBucketByScore.pending, (state) => {
        state.fetchFilteredBucketByScoreStatus = StatusEnum.Pending;
      })
      .addCase(fetchFilteredBucketByScore.fulfilled, (state, { payload }) => {
        state.fetchFilteredBucketByScoreStatus = StatusEnum.Fulfilled;
        const filteredBucket = payload.entities.buckets as {
          [id: string]: DeserializedJsonApiResource<BucketAttributes>;
        };
        state.filteredBucket.push(...Object.values(filteredBucket));
        state.allIds = payload.order;
      })
      .addCase(fetchFilteredBucketByScore.rejected, (state) => {
        state.fetchFilteredBucketByScoreStatus = StatusEnum.Rejected;
      })
      .addCase(fetchFilteredBucketByIndex.pending, (state) => {
        state.fetchFilteredBucketByIndexStatus = StatusEnum.Pending;
      })
      .addCase(fetchFilteredBucketByIndex.fulfilled, (state, { payload }) => {
        state.fetchFilteredBucketByIndexStatus = StatusEnum.Fulfilled;
        const filteredBucket = payload.entities.buckets as {
          [id: string]: DeserializedJsonApiResource<BucketAttributes>;
        };
        state.filteredBucket.push(...Object.values(filteredBucket));
        state.allIds = payload.order;
      })
      .addCase(fetchFilteredBucketByIndex.rejected, (state) => {
        state.fetchFilteredBucketByIndexStatus = StatusEnum.Rejected;
      });
  },
});

const selectIsFilteredBucketByScoreFulfilled = (state: RootState) =>
  state.buckets.fetchFilteredBucketByScoreStatus === StatusEnum.Fulfilled;

const selectIsFilteredBucketByIndexFulfilled = (state: RootState) =>
  state.buckets.fetchFilteredBucketByIndexStatus === StatusEnum.Fulfilled;

const selectIsFilteredBucketByIndexRejected = (state: RootState) =>
  state.buckets.fetchFilteredBucketByIndexStatus === StatusEnum.Rejected;

export const bucketsReducer = bucketsSlice.reducer;

export {
  fetchBuckets,
  fetchFilteredBucketByScore,
  fetchFilteredBucketByIndex,
  selectIsFilteredBucketByScoreFulfilled,
  selectIsFilteredBucketByIndexFulfilled,
  selectIsFilteredBucketByIndexRejected,
};
