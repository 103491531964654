import styled from "styled-components";

import {
  borderRadiuses,
  elementSizes,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { grey400, grey500, grey700, red } from "../../../assets/styles/colors";
import { buttonGeneralStyles } from "../../atoms/Button/Button.style";
import { H2, H3, H3Decor, H4, H5 } from "../../atoms/Typography";
import { Icon } from "../../atoms/Icon";

const PhoneModalWrapper = styled.div`
  position: absolute;
  top: ${elementSizes.topMenu};
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${spacings.l};
  border-radius: ${borderRadiuses.m};
  width: 70vw;
  height: 100wh;
  background-color: ${grey400};
  animation: appear 1s;
  z-index: 4;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const CloseModalButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  align-self: end;
`;

const ModalHeading = styled(H3Decor)`
  margin-top: ${spacings.m};
  text-align: center;
`;

const ModalText = styled.p`
  margin: ${spacings.m} 0;
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.l};
  text-align: justify;
  font-weight: 400;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputLabel = styled.label`
  font-size: ${fontSizes.m};
  margin-top: ${spacings.xs};

  &::first-letter {
    text-transform: uppercase;
  }
`;

const Input = styled.input`
  border-radius: ${borderRadiuses.sm};
  border: 1px solid ${grey700};
  padding: ${spacings.s};
  margin: ${spacings.s} 0 ${spacings.xs} 0;
  font-family: "Space Grotesk";
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.m};
  font-weight: 400;
  text-align: center;

  &::placeholder {
    color: ${grey500};
  }
`;

const SubscribeButton = styled.button`
  ${buttonGeneralStyles}
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spacings.l};
  width: 200px;
  height: 40px;
`;

const CheckMarkIcon = styled(Icon)`
  width: 20px;
  margin-left: ${spacings.xs};
`;

const ErrorMessage = styled(H3)`
  color: ${red};
  text-align: center;
  min-height: ${lineHeights.sm};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export {
  PhoneModalWrapper,
  CloseModalButton,
  FormWrapper,
  InputLabel,
  Input,
  ModalHeading,
  ModalText,
  SubscribeButton,
  CheckMarkIcon,
  ErrorMessage,
};
