import styled from "styled-components";

import {
  borderRadiuses,
  elementSizes,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { grey400, grey500, grey700, red, white } from "../../../assets/styles/colors";
import { buttonGeneralStyles } from "../../atoms/Button/Button.style";
import { H2, H3, H3Decor, H4, H5 } from "../../atoms/Typography";
import { Icon } from "../../atoms/Icon";

const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: ${spacings.s};
  align-items: flex-end;
`;

const InputLabel = styled.label`
  display: none;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const Input = styled.input`
  display: flex;
  margin-top: ${spacings.xxs};
  border-radius: ${borderRadiuses.sm};
  border: 1px solid ${grey700};
  padding: ${spacings.s};
  max-width:  140px;
  width: 100%;
  font-family: "Space Grotesk";
  font-size: ${fontSizes.sm};
  line-height: ${lineHeights.m};
  font-weight: 400;
  text-align: left;

  &::placeholder {
    color: ${grey700};
  }
`;

const SubscribeButton = styled.button`
  ${buttonGeneralStyles}
  display:flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: 40px;
`;

const CheckMarkIcon = styled(Icon)`
  width: 20px;
  margin-left: ${spacings.xs};
`;

const ErrorMessage = styled(H3)`
  color: ${red};
  text-align: center;
  min-height: ${lineHeights.sm};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export {
  FormWrapper,
  InputWrapper,
  InputLabel,
  Input,
  SubscribeButton,
  CheckMarkIcon,
  ErrorMessage,
};
