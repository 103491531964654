import styled from "styled-components";

import { grey300, red } from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  spacings,
} from "../../../assets/styles/sizes";

const ModalWrapper = styled.div`
  padding: ${spacings.xxl} ${spacings.s} ${spacings.s} ${spacings.s};
`;

const ModalContent = styled.div`
  padding: ${spacings.xl} ${spacings.s};
`;

const NameWraper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameInput = styled.input`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: ${spacings.s};
  border-radius: ${borderRadiuses.m};
  border: none;
  background-color: ${grey300};
`;

const NameLabel = styled.label`
  font-size: ${fontSizes.m};
  margin-bottom: ${spacings.s};

  &::first-letter {
    text-transform: uppercase;
  }
`;

const ErrorMessage = styled.span`
  font-size: ${fontSizes.m};
  color: ${red};
  margin-top: ${spacings.s};
`;

export {
  ModalWrapper,
  ModalContent,
  NameWraper,
  NameInput,
  NameLabel,
  ErrorMessage,
};
