import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { fontSizes, spacings } from "../../../assets/styles/sizes";
import { borderRadiuses } from "../../../assets/styles/sizes";
import { H5 } from "../../atoms/Typography";

const TopOffButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: ${palette.gold};
  padding: ${spacings.xxs} ${spacings.s};
  border: 1px solid ${palette.greyDark02};
  border-radius: ${borderRadiuses.sm};
`;

const AmountWrapper = styled.div`
  margin: 0 ${spacings.l} 0 ${spacings.xxs};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled(H5)`
  text-transform: uppercase;
  font-weight: 500;
  color: ${palette.black};
`;

const AmountLabel = styled.span`
  font-size: ${fontSizes.m};
  font-weight: 400;
  color: ${palette.black};
`;

export { TopOffButton, AmountWrapper, Label, AmountLabel };
