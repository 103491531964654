import { createSlice } from "@reduxjs/toolkit";
import { format, isToday, isTomorrow, isYesterday } from "date-fns";

import { RootState } from ".";
import { serializeGame } from "../serializers/gameSerializer";
import { ResourceDeserialized } from "../types/api";
import { ALL_DAYS, ALL_GAMES } from "../types/filters";
import {
  GameAttributes,
  GameAttrsWithTeamsAndBets,
  SeasonPhase,
} from "../types/games";
import { selectAllGames } from "./gamesSlice";

// TODO Think about array structure of filters
// interface Filter {
//   name: string;
//   value: string;
// }
export interface GameFilterState {
  status: string;
  week: number | null;
  seasonPhase: SeasonPhase;
  myGames: boolean;
  weekDay: string;
  lastSelectedWeek: number;
}

const initialState: GameFilterState = {
  status: ALL_GAMES,
  week: null,
  seasonPhase: "unset",
  myGames: false,
  weekDay: ALL_DAYS,
  lastSelectedWeek: 0,
};

const gameFiltersSlice = createSlice({
  name: "filters",
  initialState: initialState,
  reducers: {
    removeStatusFilter: (state) => {
      state.status = "";
    },
    removeWeekFilter: (state) => {
      state.week = null;
    },
    applyWeekFilter: (state, action) => {
      state.week = action.payload.week;
      state.seasonPhase = action.payload.seasonPhase;
      state.status = ALL_GAMES;
      state.weekDay = ALL_DAYS;
    },
    applyStatusFilter: (state, action) => {
      state.status = action.payload;
    },
    setMyGamesFilter: (state, { payload }) => {
      state.myGames = payload;
    },
    applyWeekDayFilter: (state, action) => {
      state.weekDay = action.payload;
    },
    setLastSelectedWeek: (state, action) => {
      localStorage.setItem("lastSelectedWeek", JSON.stringify(action.payload));
      state.lastSelectedWeek = action.payload;
    },
  },
});

const selectWeekFilter = (state: RootState): number => state.gameFilters.week;

const selectLastSelectedWeek = (state: RootState): number | null =>
  state.gameFilters.lastSelectedWeek;

const selectSeasonPhaseFilter = (state: RootState): SeasonPhase =>
  state.gameFilters.seasonPhase;

const selectStatusFilter = (state: RootState): string =>
  state.gameFilters.status;

const selectMyGamesFilterValue = (state: RootState): boolean =>
  state.gameFilters.myGames;

const selectWeekdDayFilter = (state: RootState): string =>
  state.gameFilters.weekDay;

const selectMyFilteredGames = <GameAttrs,>(
  filteredGames: ResourceDeserialized<GameAttrs>[]
): ResourceDeserialized<GameAttrs>[] => {
  return filteredGames.filter((game) => {
    const myBets = game.relationships?.bets;
    return myBets && myBets.length > 0;
  });
};

const selectGamesByPhaseAndWeek = (
  state: RootState
): ResourceDeserialized<GameAttributes>[] => {
  const games = selectAllGames(state);
  const week = selectWeekFilter(state);
  const seasonPhase = selectSeasonPhaseFilter(state);

  const filters: { key: string; value: number | string }[] = [];
  if (week != null) {
    filters.push({ key: "week", value: week });
  }
  if (seasonPhase && seasonPhase.length > 0) {
    filters.push({ key: "seasonPhase", value: seasonPhase });
  }
  const gamesFilteredByPhaseAndWeek = games.filter((game) => {
    return filters.every((filter) => {
      return game.attributes[filter.key] === filter.value;
    });
  });

  return gamesFilteredByPhaseAndWeek;
};

const selectFilteredGames = (
  state: RootState
): GameAttrsWithTeamsAndBets<{
  totalPoolValueFormatted: string;
  displayBoardDrawing: boolean;
  displayTimer: boolean;
}>[] => {
  const games = selectGamesByPhaseAndWeek(state);
  const status = selectStatusFilter(state);
  const weekDay = selectWeekdDayFilter(state);

  const filters: { key: string; value: string }[] = [];

  if (status && status !== ALL_GAMES && status.length > 0) {
    filters.push({ key: "status", value: status });
  }

  if (weekDay && weekDay !== ALL_DAYS) {
    filters.push({ key: "startTime", value: weekDay });
  }

  const gamesFilteredByWeekAndStatus =
    filters.length === 0
      ? games
      : games.filter((game) => {
          return filters.every((filter) => {
            const gameAttributeToCompare =
              filter.key === "startTime"
                ? format(new Date(game.attributes[filter.key]), "d MMM")
                : game.attributes[filter.key];

            const includes = `${gameAttributeToCompare}`.includes(filter.value);

            return includes;
          });
        });

  const filteredGames = state.gameFilters.myGames
    ? selectMyFilteredGames(gamesFilteredByWeekAndStatus)
    : gamesFilteredByWeekAndStatus;

  const serializedFilteredGames = filteredGames.map((game) =>
    serializeGame(game, state)
  );

  return serializedFilteredGames;
};

const selectGamesWeekdaysUnique = (
  state: RootState
): { weekDay: string; date: string }[] => {
  const games = selectGamesByPhaseAndWeek(state);

  const gamesSorted = games.sort(
    (a, b) =>
      Number(new Date(a.attributes.startTime)) -
      Number(new Date(b.attributes.startTime))
  );

  const gamesWeekdays = gamesSorted.map((game) => {
    if (isToday(new Date(game.attributes.startTime))) {
      return {
        weekDay: "today",
        date: format(new Date(game.attributes.startTime), "d MMM"),
      };
    } else if (isTomorrow(new Date(game.attributes.startTime))) {
      return {
        weekDay: "tomorrow",
        date: format(new Date(game.attributes.startTime), "d MMM"),
      };
    } else if (isYesterday(new Date(game.attributes.startTime))) {
      return {
        weekDay: "yesterday",
        date: format(new Date(game.attributes.startTime), "d MMM"),
      };
    } else {
      return {
        weekDay: format(new Date(game.attributes.startTime), "E"),
        date: format(new Date(game.attributes.startTime), "d MMM"),
      };
    }
  });

  const gamesWeekdaysUnique = gamesWeekdays.filter(
    (day, index) =>
      gamesWeekdays.findIndex((item) => item.date === day.date) === index
  );

  const allGamesWeekdaysOption =
    gamesWeekdays.length > 0
      ? [{ weekDay: ALL_DAYS, date: "" }, ...gamesWeekdaysUnique]
      : [];

  return allGamesWeekdaysOption;
};

export const gameFiltersReducer = gameFiltersSlice.reducer;
export const {
  removeStatusFilter,
  removeWeekFilter,
  applyWeekFilter,
  applyStatusFilter,
  setMyGamesFilter,
  applyWeekDayFilter,
  setLastSelectedWeek,
} = gameFiltersSlice.actions;
export {
  selectWeekFilter,
  selectStatusFilter,
  selectSeasonPhaseFilter,
  selectMyGamesFilterValue,
  selectWeekdDayFilter,
  selectGamesByPhaseAndWeek,
  selectFilteredGames,
  selectGamesWeekdaysUnique,
  selectLastSelectedWeek,
};
