import styled from "styled-components";

import { fontSizes, spacings } from "../../../assets/styles/sizes";

const GameListWrapper = styled.section`
  padding: ${spacings.xs};
`;

const NoGamesMessage = styled.div`
  padding: ${spacings.l} ${spacings.xs};
  font-family: "Roboto";
  font-size: ${fontSizes.sm};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: ${spacings.l};
`;

export { GameListWrapper, NoGamesMessage };
