import { SeasonPhase } from "../types/games";

const weekOptions: { [key in SeasonPhase]: number[] } = {
  unset: [],
  pre: [0, 1, 2, 3],
  regular: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  post: [1, 2, 3, 4],
};

export { weekOptions };
