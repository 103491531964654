import { createSlice } from "@reduxjs/toolkit";

import { DeserializedJsonApiResource } from "../api/apiSerializer";
import { GenericState, ResourceDeserialized, StatusEnum } from "../types/api";
import { TeamAttributes } from "../types/teams";
import { fetchCurrentSeasonGames, fetchCurrentWeekGames } from "./gamesSlice";
import { RootState } from ".";

interface TeamsState
  extends GenericState<ResourceDeserialized<TeamAttributes>> {}

const initialState: TeamsState = {
  byId: {},
  allIds: [],
  status: StatusEnum.Idle,
};

const teamsSlice = createSlice({
  name: "teams",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentWeekGames.fulfilled, (state, { payload }) => {
      state.status = StatusEnum.Fulfilled;
      const teams = payload.entities.teams as {
        [id: string]: DeserializedJsonApiResource<TeamAttributes>;
      };
      state.byId = { ...state.byId, ...teams };
      state.allIds = payload.order;
    });
    builder.addCase(fetchCurrentSeasonGames.fulfilled, (state, { payload }) => {
      state.status = StatusEnum.Fulfilled;
      const teams = payload.entities.teams as {
        [id: string]: DeserializedJsonApiResource<TeamAttributes>;
      };
      state.byId = { ...state.byId, ...teams };
      state.allIds = payload.order;
    });
  },
});

const selectTeams = (state: RootState): TeamsState["byId"] => {
  return state.teams.byId;
};

export const teamsReducer = teamsSlice.reducer;
export { selectTeams };
