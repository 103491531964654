import styled from "styled-components";

import { black, darkGreen, white } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H3, H4, H5 } from "../Typography";

const TimerWrapper = styled.div<{ isGameModal: boolean }>`
  display: flex;
  margin: ${(p) => (p.isGameModal ? `${spacings.xs}` : 0)};
`;

const TimerContent = styled.div<{ isGameModal: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: ${(p) => (p.isGameModal ? "row" : "column")};
  align-items: ${(p) => p.isGameModal && "flex-end"};
  margin-left: ${(p) => (p.isGameModal ? spacings.s : spacings.xxs)};
  color: ${(p) => (p.isGameModal ? white : black)};
`;

const Heading = styled(H5)<{ isGameModal: boolean }>`
  text-transform: uppercase;
  font-size: ${(p) => p.isGameModal && "12px"};
  margin-right: ${(p) => p.isGameModal && spacings.xxs};
  line-height: ${(p) => p.isGameModal && "14px"};
`;

const Time = styled(H4)<{ isGameModal: boolean }>`
  text-transform: ${(p) => (p.isGameModal ? "uppercase" : "none")};
  font-size: ${(p) => p.isGameModal && "12px"};
`;

const RefreshWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RefreshHeading = styled(H3)<{ isGameModal: boolean }>`
  text-transform: none;
  color: ${(p) => (p.isGameModal ? white : darkGreen)};
  font-weight: 400;
  margin-left: ${(p) => (p.isGameModal ? spacings.s : 0)};

  &::first-letter {
    text-transform: uppercase;
  }
`;

export {
  TimerWrapper,
  TimerContent,
  Heading,
  Time,
  RefreshWrapper,
  RefreshHeading,
};
