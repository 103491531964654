import { User } from "@auth0/auth0-react";

interface CustomWindow extends Window {
  paysafe?: {
    checkout: {
      setup: (
        apiKey: string,
        options,
        resultCallback: (
          instance: {
            showFailureScreen: () => void;
            showSuccessScreen: () => void;
            isOpen: () => boolean;
            close: () => void;
          },
          error,
          result: { paymentHandleToken: string }
        ) => void,
        closeCallback: (stage: string) => void
      ) => void;
    };
  };
}

declare const window: CustomWindow;

const API_KEY_MERCHANT_TEST = import.meta.env.VITE_API_KEY_MERCHANT_TEST;
const PAYSAFE_ACCOUNT_ID = import.meta.env.VITE_PAYSAFE_ACCOUNT_ID;

interface OpenCheckoutProps {
  amountInCents: number;
  merchantRefNumber: string;
  onSuccess: (paymentHandleToken: string) => void;
  onError: () => void;
  singleUseCustomerToken: string | null;
  user?: User;
}

export const openCheckout = ({
  amountInCents,
  merchantRefNumber,
  onSuccess,
  onError,
  singleUseCustomerToken,
  user,
}: OpenCheckoutProps): void => {
  if (window.paysafe) {
    const options = {
      currency: "USD",
      amount: amountInCents,
      payout: false,
      locale: "en_US",
      simulator: "EXTERNAL",
      environment: "TEST",
      // TODO hardcoded values
      billingAddress: {
        street: "Queen Street West",
        street2: "Queen Street",
        city: "Cupertino",
        zip: "14236",
        country: "US",
        state: "CA",
      },
      // TODO hardcoded values
      customer: {
        firstName: user?.given_name || "Paysafe",
        lastName: user?.family_name || "Test Customer",
        email: user?.email || "test@paysafe.com",
      },
      singleUseCustomerToken: singleUseCustomerToken,
      merchantRefNum: merchantRefNumber,
      canEditAmount: false,
      // TODO add proper values
      merchantDescriptor: {
        dynamicDescriptor: "SquareShare",
        phone: "3126120233",
      },
      displayPaymentMethods: ["card"],
      paymentMethodDetails: {
        card: {
          accountId: PAYSAFE_ACCOUNT_ID,
        },
      },
    };

    window.paysafe.checkout.setup(
      API_KEY_MERCHANT_TEST,
      options,
      (instance, error, result) => {
        if (error) {
          console.log(error);
        } else if (result?.paymentHandleToken) {
          onSuccess(result.paymentHandleToken);
          instance.close();
        }
      },
      (stage) => {
        if (stage === "PAYMENT_HANDLE_NOT_CREATED") {
          onError();
        }
      }
    );
  }
};
