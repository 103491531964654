import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../store";
import { revealBet } from "../../../store/betsSlice";
import { BetWithId } from "../../../types/bets";
import { TeamAttributes } from "../../../types/teams";
import {
  calculateQuarterPotentialWinning,
  quartersWithPercentage,
} from "../../../utils/winningsCalculation";
import {
  PrizeLabel,
  SquarePrizesSection,
} from "../PreDrawSquare/PreDrawSquare.style";
import { TeamsWithScoreLabel } from "../RevealedSquare";
import * as S from "./PreGameSquare.style";

interface PreGameSquare {
  id: string;
  teamAway: TeamAttributes;
  teamHome: TeamAttributes;
  bet: BetWithId;
}

const PreGameSquare = ({ id, teamAway, teamHome, bet }: PreGameSquare) => {
  const [revealingTriggered, setRevealingTriggered] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation("game");

  const handleReveal = () => {
    if (!bet.revealed) {
      setRevealingTriggered(true);
      dispatch(revealBet({ id }));
    }
  };

  return (
    <S.SquareInfoWrapper>
      <S.WrapperToReveal onClick={handleReveal} id="reveal-square">
        <S.SquareSection>
          <S.SquareLabel>{t("square")}</S.SquareLabel>
          <TeamsWithScoreLabel
            teamHome={teamHome}
            teamAway={teamAway}
            homeScore={bet.homeScore}
            awayScore={bet.awayScore}
          />
        </S.SquareSection>
        {!bet.revealed && (
          <S.SquareNotRevealed
            triggerReveal={revealingTriggered}
            id="reveal-square-triggered"
          >
            <S.SemiCircle />
            <S.SquareNotRevealedLabel>{t("reveal")}</S.SquareNotRevealedLabel>
          </S.SquareNotRevealed>
        )}
      </S.WrapperToReveal>
      <S.SquareValueSection>
        <S.SquareValue>{bet.amountFormatted}</S.SquareValue>
      </S.SquareValueSection>
      <S.SquareValueSection>
        <S.SquareValue>
          {bet.quarter1PotentialWinningFormatted &&
            bet.totalPotentialWinningsFormatted}
        </S.SquareValue>
      </S.SquareValueSection>
      <SquarePrizesSection>
        {Object.keys(quartersWithPercentage).map((quarter) => {
          return (
            <PrizeLabel key={quarter}>
              {quarter !== "final" ? t(`q${quarter}`) : t("final")}:{" "}
              {bet.quarter1PotentialWinningFormatted
                ? quarter === "final"
                  ? bet[`${quarter}QuarterPotentialWinningFormatted`]
                  : bet[`quarter${quarter}PotentialWinningFormatted`]
                : calculateQuarterPotentialWinning(bet.amount, quarter)
                    .potentialWinningFormated}
            </PrizeLabel>
          );
        })}
      </SquarePrizesSection>
    </S.SquareInfoWrapper>
  );
};

export { PreGameSquare };
