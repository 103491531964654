import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  spacings,
} from "../../../assets/styles/sizes";

const GameTabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: ${borderRadiuses.l};
  margin-bottom: ${spacings.xs};
`;

const Tab = styled.button<{ active: boolean }>`
  padding: ${spacings.s};
  font-family: "Space Grotesk";
  font-size: ${fontSizes.sm};
  border: 1px solid transparent;
  color: ${(p) => (p.active ? palette.black : palette.grey600)};
  background-color: ${(p) => (p.active ? palette.gold : palette.white)};
  flex-grow: 1;

  &:first-child {
    border-radius: ${borderRadiuses.l} 0 0 ${borderRadiuses.l};
  }

  &:nth-child(2) {
    border-radius: 0 ${borderRadiuses.l} ${borderRadiuses.l} 0;
  }

  &:first-letter {
    text-transform: capitalize;
  }
`;

export { GameTabWrapper, Tab };
