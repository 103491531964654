import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H3, H4Decor, H5Decor } from "../../atoms/Typography";
import { OutlinedButton } from "../../atoms/Button/Button.style";

const ModalWrapper = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

const PaymentMethodWrapper = styled.div`
  border-top: 1px solid ${palette.grey400};
  margin-top: ${spacings.xl};
  padding-top: ${spacings.l};
`;

const PaymentMethodLabel = styled(H3)`
  margin-bottom: ${spacings.xxs};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const MethodWrapper = styled(OutlinedButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MethodName = styled(H4Decor)`
  &::first-letter {
    text-transform: uppercase;
  }
`;

const MethodDetailsWrapper = styled.div`
  display: flex;
`;

const MethodDetailsLabel = styled(H5Decor)`
  color: ${palette.lightBlue60};
  margin-right: ${spacings.xs};
  text-transform: none;
`;

export {
  ModalWrapper,
  PaymentMethodWrapper,
  PaymentMethodLabel,
  MethodWrapper,
  MethodName,
  MethodDetailsWrapper,
  MethodDetailsLabel,
};
