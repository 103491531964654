import styled from "styled-components";

import { red, white } from "../../../assets/styles/colors";
import { fontSizes, spacings } from "../../../assets/styles/sizes";
import { TransparentButton } from "../../atoms/Button/Button.style";

const CartStatusButton = styled(TransparentButton)`
  position: relative;
  margin-right: ${spacings.m};
`;

const SquareNumberWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: -3px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: ${red};
`;

const SquareNumber = styled.span`
  font-size: ${fontSizes.xs};
  color: ${white};
`;

export { CartStatusButton, SquareNumberWrapper, SquareNumber };
