import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";

import peopleImg from "../../../assets/images/slide-friends.jpg";
import phoneFormImg from "../../../assets/images/slide-phone-form.jpg";
import surveyImg from "../../../assets/images/slide-survey.png";
import { CarouselSlide } from "../../atoms/CarouselSlide";
import { InvitationModal } from "../InvitationModal";
import { FULL_WIDTH_CENTER, Modal } from "../Modal";
import * as S from "./Carousel.style";

const Carousel = () => {
  const { t } = useTranslation("carousel");
  const [invitationModalVisible, setInvitationModalVisible] = useState(false);
  const isPhoneSaved = !!Cookies.get("phone");

  interface CarouselSlidesProps {
    headerText?: string;
    buttonText?: string;
    buttonUrl?: string;
    imgSrc?: string;
    backgroundColor?: string;
    isLink?: boolean;
    buttonCTA?: () => void;
    isForm?: boolean;
  }

  const carouselSlides: CarouselSlidesProps[] = [
    {
      headerText: t("inviteFriendsHeader"),
      buttonText: t("inviteFriendButton"),
      imgSrc: peopleImg,
      buttonCTA: () => setInvitationModalVisible(true),
    },
    {
      headerText: t("surveyHeader"),
      buttonText: t("surveyButton"),
      buttonUrl:
        "https://docs.google.com/forms/d/e/1FAIpQLSf7akguCf9XyH2favlSy-kso1Aw5vkyrtDwSXKH4DUdLZH2fQ/viewform",
      imgSrc: surveyImg,
      isLink: true,
    },
    {
      headerText: t("phoneFormHeader"),
      isForm: true,
      imgSrc: phoneFormImg,
    },
  ];

  const filteredSlides = carouselSlides.filter((slide) => {
    if (slide.isForm) {
      return !isPhoneSaved;
    }
    return true;
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 20000,
    appendDots: (dots) => (
      <S.Dots>
        <S.DotsList>{dots}</S.DotsList>
      </S.Dots>
    ),
  };

  return (
    <>
      <S.SliderContainer>
        <Slider {...settings}>
          {filteredSlides.map((slide, id) => {
            return (
              <CarouselSlide
                key={id}
                headerText={slide.headerText}
                buttonText={slide.buttonText}
                buttonUrl={slide.buttonUrl}
                backgroundColor={slide.backgroundColor}
                imgSrc={slide.imgSrc}
                isLink={slide.isLink}
                buttonCTA={slide.buttonCTA}
                isForm={slide.isForm}
                id={`carousel-slide-${id}`}
              />
            );
          })}
        </Slider>
      </S.SliderContainer>
      <Modal
        contentPlacing={FULL_WIDTH_CENTER}
        modalVisible={invitationModalVisible}
        onModalClose={() => setInvitationModalVisible(false)}
        $transparentBackground
      >
        <InvitationModal
          handleCloseModal={() => setInvitationModalVisible(false)}
        />
      </Modal>
    </>
  );
};

export { Carousel };
