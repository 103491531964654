import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { RootState } from ".";
import { apiClient } from "../api/apiClient";
import { ENDPOINTS } from "../api/apiEndpoints";
import {
  DeserializedJsonApiResource,
  SingleResourcePayloadSerialized,
  serializeSingleResource,
} from "../api/apiSerializer";
import { getAuthHeader } from "../api/auth";
import { StatusEnum } from "../types/api";
import { NotificationPosition, NotificationType } from "../types/notification";
import { triggerNotification } from "./notificationSlice";

interface UserState {
  firstName: string;
  phone: string;
  fetchUserStatus: StatusEnum;
  updateUserNameStatus: StatusEnum;
  updateUserPhoneStatus: StatusEnum;
}

const initialState: UserState = {
  firstName: "",
  phone: "",
  fetchUserStatus: StatusEnum.Idle,
  updateUserNameStatus: StatusEnum.Idle,
  updateUserPhoneStatus: StatusEnum.Idle,
};

interface UserPayloadAttributes {
  phone?: string;
  firstName?: string;
}

const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const headers = await getAuthHeader();

  const response = await apiClient.get<{
    entities: {
      [id: string]: DeserializedJsonApiResource<{ firstName: string }>;
    };
  }>(ENDPOINTS.user, { headers });

  return response.data;
});

const updateUserName = createAsyncThunk(
  "user/updateUserName",
  async (
    { firstName }: UserPayloadAttributes,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const headers = await getAuthHeader();
      const payload = serializeSingleResource({
        type: "user",
        attributes: {
          firstName,
        },
      });
      const response = await apiClient.patch<
        SingleResourcePayloadSerialized<UserPayloadAttributes>
      >(ENDPOINTS.user, payload, { headers });
      if (response.status === 204) {
        dispatch(
          triggerNotification({
            type: NotificationType.SUCCESS,
            message: "changeNameSuccess",
            position: NotificationPosition.BOTTOM,
          })
        );
      }
      return { firstName };
    } catch (error) {
      dispatch(
        triggerNotification({
          type: NotificationType.ERROR,
          message: "changeNameError",
          position: NotificationPosition.BOTTOM,
        })
      );

      return rejectWithValue(error);
    }
  }
);

const updateUserPhone = createAsyncThunk(
  "user/updateUserPhone",
  async ({ firstName, phone }: UserPayloadAttributes, { rejectWithValue }) => {
    try {
      const headers = await getAuthHeader();
      const payload = serializeSingleResource({
        type: "user",
        attributes: {
          firstName,
          phone,
        },
      });
      const response = await apiClient.patch<
        SingleResourcePayloadSerialized<UserPayloadAttributes>
      >(ENDPOINTS.user, payload, { headers });
      if (response.status === 204) {
        Cookies.set("phone", true);
      }
      return { firstName, phone };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => {
      state.fetchUserStatus = StatusEnum.Pending;
    });
    builder.addCase(
      fetchUser.fulfilled,
      (
        state,
        action: PayloadAction<{
          entities: {
            [id: string]: DeserializedJsonApiResource<{ firstName: string }>;
          };
        }>
      ) => {
        state.fetchUserStatus = StatusEnum.Fulfilled;
        const user = Object.values(action.payload.entities.users)[0]
          .attributes as { firstName: string; phoneNumber: string };
        state.firstName = user.firstName;
      }
    );
    builder.addCase(fetchUser.rejected, (state) => {
      state.fetchUserStatus = StatusEnum.Rejected;
    });
    builder.addCase(updateUserName.pending, (state) => {
      state.updateUserNameStatus = StatusEnum.Pending;
    });
    builder.addCase(updateUserName.fulfilled, (state, { payload }) => {
      state.updateUserNameStatus = StatusEnum.Fulfilled;
      state.firstName = payload.firstName;
    });
    builder.addCase(updateUserName.rejected, (state) => {
      state.updateUserNameStatus = StatusEnum.Rejected;
    });
    builder.addCase(updateUserPhone.pending, (state) => {
      state.updateUserPhoneStatus = StatusEnum.Pending;
    });
    builder.addCase(updateUserPhone.fulfilled, (state, { payload }) => {
      state.updateUserPhoneStatus = StatusEnum.Fulfilled;
      state.firstName = payload.firstName;
      state.phone = payload.phone;
    });
    builder.addCase(updateUserPhone.rejected, (state) => {
      state.updateUserPhoneStatus = StatusEnum.Rejected;
    });
  },
});

const selectUserName = (state: RootState): string => state.user.firstName;
const selectIsFetchingUserPending = (state: RootState): boolean =>
  state.user.fetchUserStatus === StatusEnum.Pending;
const selectIsFetchingUserFulfilled = (state: RootState): boolean =>
  state.user.fetchUserStatus === StatusEnum.Fulfilled;
const selectIsUpdateUserNamePending = (state: RootState): boolean =>
  state.user.updateUserNameStatus === StatusEnum.Pending;
const selectIsUpdateUserNameFulfilled = (state: RootState): boolean =>
  state.user.updateUserNameStatus === StatusEnum.Fulfilled;
const selectIsUpdateUserPhonePending = (state: RootState): boolean =>
  state.user.updateUserPhoneStatus === StatusEnum.Pending;
const selectIsUpdateUserPhoneFulfilled = (state: RootState): boolean =>
  state.user.updateUserPhoneStatus === StatusEnum.Fulfilled;

export const userReducer = userSlice.reducer;

export {
  fetchUser,
  updateUserName,
  updateUserPhone,
  selectUserName,
  selectIsFetchingUserPending,
  selectIsFetchingUserFulfilled,
  selectIsUpdateUserNamePending,
  selectIsUpdateUserNameFulfilled,
  selectIsUpdateUserPhonePending,
  selectIsUpdateUserPhoneFulfilled,
};
