import * as S from "./SlideForm.style";
import { Form } from "../../atoms/Form/Form";


const SlideForm = () => {
  return (
    <Form
      FormWrapper={S.FormWrapper}
      InputWrapper={S.InputWrapper}
      InputLabel={S.InputLabel}
      Input={S.Input}
      ErrorMessage={S.ErrorMessage}
      SubscribeButton={S.SubscribeButton}
      CheckMarkIcon={S.CheckMarkIcon}
    />
  );
};

export { SlideForm }