import styled from "styled-components";

import {
  blue100,
  blue400,
  boardSquareColors,
  grey300,
  white,
} from "../../../assets/styles/colors";
import { fontSizes, spacings } from "../../../assets/styles/sizes";

// Utility functions
const cellBackgroundColorLogic = (
  winnerSquareQuarters: string[],
  isMySquare: boolean
) => {
  if (!isMySquare) {
    return "transparent";
  }
  if (winnerSquareQuarters.length === 0) {
    return blue400;
  }
  if (winnerSquareQuarters.length === 1) {
    return boardSquareColors[winnerSquareQuarters[0]];
  }
  if (winnerSquareQuarters.length > 1 && isMySquare) {
    const gradientColors = winnerSquareQuarters.map(
      (quarter) => boardSquareColors[quarter]
    );
    return `linear-gradient(45deg, ${gradientColors.join(", ")})`;
  }
};

const cellBorderColorLogic = (winnerSquareQuarters: string[]) => {
  if (winnerSquareQuarters.length === 1) {
    return `border: 4px solid ${boardSquareColors[winnerSquareQuarters[0]]}`;
  } else if (winnerSquareQuarters.length > 1) {
    const gradientColors = winnerSquareQuarters.map(
      (quarter) => boardSquareColors[quarter]
    );
    return `border-image: linear-gradient(45deg, ${gradientColors.join(
      ", "
    )});  border-width: 4px; border-style: solid;`;
  }
};

// Styled components
const BoardWrapper = styled.div`
  margin: ${spacings.m} 0;
`;

const Board = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
`;

const Row = styled.tr`
  height: 3rem;
`;

const BoardCell = styled.td<{
  isMySquare: boolean;
  winnerSquareQuarters?: string[];
}>`
  vertical-align: middle;
  text-align: center;
  color: ${white};
  border: 1px solid ${blue100};
  background: ${(p) =>
    cellBackgroundColorLogic(p.winnerSquareQuarters, p.isMySquare)};
  position: relative;
  height: 3rem;
  width: 3rem;

  // "hack" to get border-image working in table with border-collapse: collapse
  ::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -1.5px;
    ${(p) => cellBorderColorLogic(p.winnerSquareQuarters)};
    border-image-slice: 1;
    width: calc(100% - 4.5px);
    height: calc(100% - 3.5px);
    z-index: 1;
  }
`;

const HeadCell = styled.th<{ order?: number }>`
  border: 1px solid ${blue100};
  position: relative;
  font-size: ${fontSizes.sm};
  vertical-align: middle;
  text-align: center;
  width: 3rem;
  background-color: ${grey300};

  -webkit-animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }
  animation-delay: ${(p) => `${p.order / 2}s`};
`;

const Teams = styled.span<{ teamHome: string; teamAway: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
  transform-origin: right bottom;
  top: 0;
  left: 0;
  background: linear-gradient(
    to top right,
    ${grey300} calc(50% - 1px),
    ${blue100},
    ${grey300} calc(50% + 1px)
  );

  &::before {
    width: 50%;
    height: 50%;
    z-index: 20;
    position: absolute;
    top: 3%;
    right: 3%;
    content: "";
    background: url(${(p) => p.teamHome}) no-repeat center center/cover;
  }

  &::after {
    width: 50%;
    height: 50%;
    z-index: 20;
    position: absolute;
    bottom: 3%;
    left: 3%;
    content: "";
    background: url(${(p) => p.teamAway}) no-repeat center center/cover;
  }
`;

export { BoardWrapper, BoardCell, HeadCell, Board, Row, Teams };
