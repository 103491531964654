import { useAuth0 } from "@auth0/auth0-react";
import { differenceInMilliseconds, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import arrow from "../../../assets/icons/arrow-right.svg";
import check from "../../../assets/icons/check.svg";
import plus from "../../../assets/icons/plus.svg";
import cart from "../../../assets/icons/shopping-cart.svg";
import ticket from "../../../assets/icons/ticket.svg";
import { black } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { RootState, useAppDispatch } from "../../../store";
import {
  addCartItem,
  removeGameFromCart,
  selectIsGameInCart,
} from "../../../store/cartSlice";
import { selectGameDetails } from "../../../store/gamesSlice";
import { CartItem } from "../../../types/cart";
import {
  GameAttrsWithTeamsAndBets,
  GameDetails,
  GameStatus,
} from "../../../types/games";
import { Button } from "../../atoms/Button";
import { FlexWrapper } from "../../atoms/Containers";
import { GameStatusLabel } from "../../atoms/GameStatusLabel";
import { Icon } from "../../atoms/Icon";
import { IconColor } from "../../atoms/IconColor";
import { TeamLabel } from "../../atoms/Team";
import { Timer } from "../../atoms/Timer";
import { TvStationIcon } from "../../atoms/TvStationIcon";
import * as S from "./GameTile.style";

interface GameTileProps
  extends GameAttrsWithTeamsAndBets<{
    totalPoolValueFormatted: string;
    displayBoardDrawing: boolean;
    displayTimer: boolean;
  }> {
  id: string;
  onViewGameDetails: (gameId: string) => void;
}

const GameTile = ({ onViewGameDetails, ...game }: GameTileProps) => {
  const { t } = useTranslation("game");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAuth0();

  const [scoreChanged, setScoreChanged] = useState(false);
  const [timeDifference, setTimeDifference] = useState(
    differenceInMilliseconds(new Date(game.nextPhaseStartTime), new Date())
  );
  const isMounted = useRef(false);

  const {
    hasWinningInGame,
    totalWinningInGameFormatted,
    isBeforeGame,
    isOpenForBets,
  } = useSelector<RootState>((state) =>
    selectGameDetails(state, game.id)
  ) as GameDetails;

  const addedToCart = useSelector<RootState>((state) =>
    selectIsGameInCart(state, game.id)
  ) as boolean;

  const winningsInfoDisplayed =
    game.status === GameStatus.Finished && game.hasBets;

  const timerEnded = isBeforeGame && timeDifference < 5;

  const handleOnCartClick = (cartItem: CartItem) => {
    addedToCart
      ? dispatch(removeGameFromCart(cartItem))
      : dispatch(addCartItem(cartItem));
  };

  useEffect(() => {
    if (isMounted.current) {
      setScoreChanged(true);
      setTimeout(() => setScoreChanged(false), 10000);
    } else {
      isMounted.current = true;
    }
  }, [game.homeScore, game.awayScore]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDifference(
        differenceInMilliseconds(new Date(game.nextPhaseStartTime), new Date())
      );
      return () => clearInterval(interval);
    }, 1000);
  }, []);

  return (
    <>
      <S.GameWrapper
        finished={game.status === GameStatus.Finished}
        addedToCart={addedToCart}
        scoreChanged={scoreChanged}
        id={`game-tile-${game.id}`}
      >
        <S.GameHeader>
          <S.GameHeaderDetailWrapper>
            <S.GameHeaderDetail>
              {format(new Date(game.startTime), "d LLL")}
            </S.GameHeaderDetail>
            <S.GameHeaderDetail>
              {format(new Date(game.startTime), "p")}
            </S.GameHeaderDetail>
            {game.tvStation &&
              (<TvStationIcon tvStation={game.tvStation} /> || (
                <S.GameHeaderDetail>{game.tvStation}</S.GameHeaderDetail>
              ))}
          </S.GameHeaderDetailWrapper>
          <FlexWrapper>
            {game.hasBets && (
              <S.BetsCountInfoWrapper>
                <S.BetsCountValue>{game.betsCount}</S.BetsCountValue>
                <IconColor src={ticket} color={black} $size="16px" />
              </S.BetsCountInfoWrapper>
            )}
            {!timerEnded && (
              <GameStatusLabel status={game.status}></GameStatusLabel>
            )}
          </FlexWrapper>
        </S.GameHeader>
        {game.status === GameStatus.Live ||
        game.status === GameStatus.Finished ? (
          <S.GameContent isLiveGame>
            <TeamLabel team={game.teamHome} isLiveGame />
            <S.ScoresWrapper>
              <S.ScoreLabel
                scoreChanged={scoreChanged}
              >{`${game.homeScore} - ${game.awayScore}`}</S.ScoreLabel>
            </S.ScoresWrapper>
            <TeamLabel team={game.teamAway} isLiveGame />
          </S.GameContent>
        ) : (
          <S.GameContent>
            <TeamLabel team={game.teamHome} />
            <TeamLabel team={game.teamAway} />
            <S.PoolWrapper>
              <S.PoolValue>{game.totalPoolValueFormatted}</S.PoolValue>
              <S.PoolValueLabel>{t("totalPoolLabel")}</S.PoolValueLabel>
            </S.PoolWrapper>
          </S.GameContent>
        )}
        <S.BottomSectionWrapper
          isAdditionalInfo={game.displayTimer || winningsInfoDisplayed}
        >
          {game.displayTimer && (
            <Timer
              gameStatus={game.status}
              time={game.nextPhaseStartTime}
              timeLeft={game.timeLeft}
              currentQuarter={game.quarter}
              timerEnded={timerEnded}
            />
          )}
          {winningsInfoDisplayed && (
            <S.TotalWinningsWrapper>
              <S.TotalWinningsLabel>{t("myWinnings")}</S.TotalWinningsLabel>
              <S.WinningsAmount hasWinnings={hasWinningInGame}>
                {totalWinningInGameFormatted}
              </S.WinningsAmount>
            </S.TotalWinningsWrapper>
          )}
          {timerEnded ? (
            <S.RefreshButton onClick={() => navigate(0)}>
              {t("timer:refresh")}
            </S.RefreshButton>
          ) : isOpenForBets && isAuthenticated ? (
            <S.IconButtonsWrapper>
              <S.CartButton
                onClick={() =>
                  handleOnCartClick({ gameId: game.id, wagerAmount: 0 })
                }
                selected={addedToCart}
                id="add-to-cart-button"
              >
                <Icon src={cart} $width={"1.8rem"} id="cart-icon" />
                {addedToCart ? (
                  <Icon
                    src={check}
                    $width={"1rem"}
                    $marginLeft={spacings.xxxs}
                    id="check-icon"
                  />
                ) : (
                  <Icon
                    src={plus}
                    $width={"1rem"}
                    $marginLeft={spacings.xxxs}
                    id="plus-icon"
                  />
                )}
              </S.CartButton>
              <S.IconButton
                onClick={() => onViewGameDetails(game.id)}
                id="view-game-details-button-small"
              >
                <Icon
                  src={arrow}
                  $width={"1.5rem"}
                  id="view-game-details-icon"
                />
              </S.IconButton>
            </S.IconButtonsWrapper>
          ) : (
            <Button
              onClick={() => onViewGameDetails(game.id)}
              id="view-game-details-button"
            >
              {t("viewGame")}
              <Icon
                src={arrow}
                $width={"1.5rem"}
                $marginLeft={spacings.xxs}
                id="view-game-details-icon"
              />
            </Button>
          )}
        </S.BottomSectionWrapper>
      </S.GameWrapper>
    </>
  );
};

export { GameTile };
