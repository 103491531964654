import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../store";
import {
  selectSeasonPhaseFilter,
  selectWeekFilter,
  setLastSelectedWeek,
} from "../../../store/gameFiltersSlice";
import { FlexWrapper } from "../../atoms/Containers";
import { WeekFilterModal } from "../../molecules/WeekFilterModal";
import * as S from "./WeekFilter.style";

const WeekFilter = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation("games");
  const dispatch = useAppDispatch();
  const selectedSeasonPhase = useSelector(selectSeasonPhaseFilter);
  const selectedWeek = useSelector(selectWeekFilter);
  const weekNumber = selectedWeek;

  const handleWeekSelection = (week: number) => {
    dispatch(setLastSelectedWeek(week));
  };

  return (
    <>
      <S.FilterButton
        onClick={() => setModalVisible(true)}
        id="week-filter-button"
      >
        <S.FilterCalendarIcon id="week-filter-calendar-icon" />
        <S.ButtonContent id="week-filter-button-content">
          <FlexWrapper column alignItems="flex-start">
            <S.ButtonLabel id="week-filer-button-label">
              {t("season", {
                seasonPhase:
                  selectedSeasonPhase === "unset" ? "" : selectedSeasonPhase,
              })}
            </S.ButtonLabel>
            <S.ButtonValue id="week-filter-button-value">
              {t("week", { weekNumber: weekNumber })}
            </S.ButtonValue>
          </FlexWrapper>
          <S.FilterChevronIcon id="week-filter-chevron-icon" />
        </S.ButtonContent>
      </S.FilterButton>
      <WeekFilterModal
        modalVisible={modalVisible}
        setModalVisible={(val) => setModalVisible(val)}
        selectedSeasonPhase={selectedSeasonPhase}
        selectedWeek={selectedWeek}
        onWeekSelect={handleWeekSelection}
      />
    </>
  );
};

export { WeekFilter };
