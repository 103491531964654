import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { openCheckout } from "../../../services/paysafe";
import { useAppDispatch } from "../../../store";
import {
  cancelPayment,
  confirmPayment,
  selectConfirmPaymentPending,
  selectInitiatePaymentPending,
  selectPaymentDetails,
} from "../../../store/walletSlice";
import { FooterButtonSmall } from "../../atoms/Button/Button.style";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { H2Decor, H3Decor } from "../../atoms/Typography";
import { WarningTile } from "../../atoms/WarningTile";
import {
  CloseModalButton,
  Footer,
  ModalHeader,
  ModalHeading,
} from "../../molecules/Modal/Modal.style";
import * as S from "./CheckoutSummaryModal.style";

interface CheckoutSummaryModalProps {
  onModalClose: () => void;
}

const CheckoutSummaryModal = ({ onModalClose }: CheckoutSummaryModalProps) => {
  const [iframeSpinnerVisible, setIframeSpinnerVisible] = useState(false);
  const { t } = useTranslation("wallet");
  const dispatch = useAppDispatch();

  const { user } = useAuth0();
  const isInitiatePaymentPending = useSelector(selectInitiatePaymentPending);
  const isConfirmPaymentPending = useSelector(selectConfirmPaymentPending);

  const {
    paymentSessionId,
    paymentAmountFormatted,
    paymentAmountInDollars,
    paymentTotalAmountFormatted,
    paymentTotalAmountCents,
    paymentsProviderFeeFormatted,
    paymentsProviderFee,
    singleUseCustomerToken,
  } = useSelector(selectPaymentDetails);

  const handleProceedToPayment = (ev) => {
    ev.preventDefault();

    setIframeSpinnerVisible(true);

    openCheckout({
      amountInCents: paymentTotalAmountCents,
      merchantRefNumber: paymentSessionId,
      onError: () => {
        onModalClose();
        setIframeSpinnerVisible(false);
        dispatch(cancelPayment({ paymentSessionId }));
      },
      onSuccess: (paymentHandleToken) => {
        onModalClose();
        setIframeSpinnerVisible(false);
        dispatch(
          confirmPayment({
            paymentSessionId,
            handleToken: paymentHandleToken,
            amount: paymentAmountInDollars,
            paymentsProviderFee: paymentsProviderFee,
          })
        );
      },
      singleUseCustomerToken: singleUseCustomerToken,
      user,
    });
  };

  return (
    <>
      <CloseModalButton
        aria-label={t("general:closeModal")}
        onClick={onModalClose}
        goldBackground
        id="close-checkout-summary-modal-button"
      />
      <ModalHeader withBackground>
        <ModalHeading>{t("paymentSummary")}</ModalHeading>
      </ModalHeader>
      <S.ModalContentWrapper>
        {isInitiatePaymentPending || iframeSpinnerVisible ? (
          <LoadingAnimation label={t("general:loading")} fullScreen />
        ) : (
          <>
            <S.PaymentSummary>
              <S.PaymentInfo>
                <S.PaymentInfoLabel>{t("amount")}:</S.PaymentInfoLabel>
                <H3Decor>{paymentAmountFormatted}</H3Decor>
              </S.PaymentInfo>
              <S.PaymentInfo>
                <S.PaymentInfoLabel>{t("transactionFee")}:</S.PaymentInfoLabel>
                <H3Decor>{paymentsProviderFeeFormatted}</H3Decor>
              </S.PaymentInfo>
              <S.PaymentInfo>
                <S.TotalAmountLabel>
                  {t("totalTransactionAmount")}:
                </S.TotalAmountLabel>
                <H2Decor>{paymentTotalAmountFormatted}</H2Decor>
              </S.PaymentInfo>
            </S.PaymentSummary>
            <WarningTile
              title={t("proceedToPayment")}
              infoText={t("paymentProcessInfo")}
            />
          </>
        )}
      </S.ModalContentWrapper>
      <Footer>
        <FooterButtonSmall
          secondary
          onClick={onModalClose}
          id="close-payment-modal-button"
        >
          {t("general:close")}
        </FooterButtonSmall>
        <FooterButtonSmall
          disabled={isConfirmPaymentPending}
          pending={isConfirmPaymentPending}
          onClick={(ev) => handleProceedToPayment(ev)}
          id="proceed-to-payment-button"
        >
          {isConfirmPaymentPending ? (
            <>
              <span>{t("general:processing")}</span>
              <LoadingAnimation spinner />
            </>
          ) : (
            <span>{t("proceedToPayment")}</span>
          )}
        </FooterButtonSmall>
      </Footer>
    </>
  );
};

export { CheckoutSummaryModal };
