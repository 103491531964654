import { AnyAction, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { useDispatch } from "react-redux";

import { apiClient } from "../api/apiClient";
import { betsReducer } from "./betsSlice";
import { bucketBettersReducer } from "./bucketBettersSlice";
import { bucketsReducer } from "./bucketsSlice";
import { cartReducer } from "./cartSlice";
import { gameFiltersReducer } from "./gameFiltersSlice";
import { gamesReducer } from "./gamesSlice";
import { notificationReducer } from "./notificationSlice";
import { teamsReducer } from "./teamsSlice";
import { transactionsReducer } from "./transactionsSlice";
import { userReducer } from "./userSlice";
import { walletReducer } from "./walletSlice";

const store = configureStore({
  reducer: {
    games: gamesReducer,
    teams: teamsReducer,
    bets: betsReducer,
    wallet: walletReducer,
    gameFilters: gameFiltersReducer,
    notification: notificationReducer,
    transactions: transactionsReducer,
    user: userReducer,
    buckets: bucketsReducer,
    filteredBucket: bucketsReducer,
    bucketBetters: bucketBettersReducer,
    cart: cartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: { extraArgument: { apiClient: apiClient } },
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  { apiClient: AxiosInstance },
  AnyAction
>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
