import { format } from "date-fns";

import { ResourceDeserialized } from "../types/api";
import { TransactionAttributes } from "../types/transactions";
import { formatCurrency } from "../utils/currency";

const serializeTransaction = (
  transaction: ResourceDeserialized<TransactionAttributes>
): ResourceDeserialized<TransactionAttributes> => {
  const {
    attributes: { createdAt, event, walletBalanceChange },
    type,
    id,
  } = transaction;
  return {
    type,
    id,
    attributes: {
      createdAt: format(new Date(createdAt), "hh:mm aaa"),
      event,
      walletBalanceChange: formatCurrency(parseFloat(walletBalanceChange)),
    },
  };
};

export { serializeTransaction };
