import styled from "styled-components";

import {
  black,
  gold,
  grey300,
  grey400,
  grey700,
  white,
} from "../../../assets/styles/colors";
import { H3 } from "../Typography";

const DayFilterTabWrapper = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 5rem;
  min-width: 7rem;
  border-bottom: 2px solid ${(props) => (props.active ? gold : grey400)};
  background-color: ${(props) => (props.active ? white : grey300)};
  color: ${(props) => (props.active ? black : grey700)};
`;

const Input = styled.input`
  position: absolute;
  opacity: 0.00001;
  height: 5rem;
  width: 5.5rem;
  margin: 0;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DayLabel = styled(H3)`
  text-align: center;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const DateLabel = styled(H3)`
  text-transform: capitalize;
`;

export { DayFilterTabWrapper, Input, Label, DayLabel, DateLabel };
