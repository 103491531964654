import { KeyboardEvent, ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import minus from "../../../assets/icons/minus.svg";
import plus from "../../../assets/icons/plus.svg";
import { formatCurrency } from "../../../utils/currency";
import { FooterButtonSmall } from "../../atoms/Button/Button.style";
import { Icon } from "../../atoms/Icon";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { Footer } from "../../molecules/Modal/Modal.style";
import * as S from "./TransactionForm.style";

const validateAmount = (value: number): boolean => {
  return value % 5 === 0;
};

const validateFundsAvailable = (
  value: number,
  validateFunds: boolean,
  balance?: number
): boolean => {
  if (!validateFunds) return true;
  else {
    return balance && value <= balance;
  }
};

interface FormFields {
  amount: number;
}

interface TransactionFormProps {
  onSubmit: (amount: string) => void;
  onCloseForm: () => void;
  inputLabel: string;
  submitButtonLabel: string;
  isFormProcessing: boolean;
  onInputChange?: (amount: string) => void;
  validateFunds?: boolean;
  balance?: number;
  children?: (amount?: number) => ReactNode | ReactNode[];
  externalValue?: string;
}

const DEFAULT_AMOUNT = 0;
const MIN_AMOUNT = 5;
const STEP = 5;
const TILES_AMOUNTS = [5, 10, 20, 50, 100, 1000];

const TransactionForm = ({
  onSubmit,
  onInputChange,
  onCloseForm,
  inputLabel,
  submitButtonLabel,
  isFormProcessing,
  validateFunds = false,
  balance,
  children,
  externalValue,
}: TransactionFormProps) => {
  const { t } = useTranslation("general");

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm<FormFields>({
    mode: "onChange",
    defaultValues: {
      amount: DEFAULT_AMOUNT,
    },
  });

  const amount = getValues("amount");

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("amount").blur();
    }
  };

  useEffect(() => {
    if (externalValue !== undefined && externalValue !== "0") {
      setValue("amount", parseFloat(externalValue), {
        shouldValidate: true,
      });
    }
  }, [externalValue]);

  return (
    <S.PaddingWrapper>
      <form onSubmit={handleSubmit((values) => onSubmit(`${values.amount}`))}>
        <S.InputLabel htmlFor="amount">{inputLabel}</S.InputLabel>
        <S.FormWrapper>
          <S.ControlButton
            id="decrease-amount-button"
            onClick={(e) => {
              e.preventDefault();
              setValue("amount", amount - STEP, {
                shouldValidate: true,
              });
              onInputChange && onInputChange(`${amount - STEP}`);
            }}
            disabled={amount <= 0}
          >
            <S.ControlButtonIcon id="decrease-amount-button-icon">
              <Icon src={minus} id="decrease-amount-icon" />
            </S.ControlButtonIcon>
          </S.ControlButton>
          <S.InputWrapper>
            <S.CurrencyLabel>$</S.CurrencyLabel>
            <S.Input
              type="number"
              id="amount"
              {...register("amount", {
                required: true,
                min: MIN_AMOUNT,
                validate: {
                  multiplicationOfFive: (value) => validateAmount(value),
                  fundsAvailable: (value) =>
                    validateFundsAvailable(value, validateFunds, balance),
                },
                valueAsNumber: true,
              })}
              onChange={(e) => {
                e.preventDefault();
                setValue("amount", e.target.valueAsNumber, {
                  shouldValidate: true,
                });
                onInputChange && onInputChange(e.target.value);
              }}
              onKeyDown={handleKeyPress}
            />
          </S.InputWrapper>
          <S.ControlButton
            id="increase-amount-button"
            onClick={(e) => {
              e.preventDefault();
              setValue("amount", amount + STEP, {
                shouldValidate: true,
              });
              onInputChange && onInputChange(`${amount + STEP}`);
            }}
          >
            <S.ControlButtonIcon id="add-amount-button-icon">
              <Icon src={plus} id="add-amount-icon" />
            </S.ControlButtonIcon>
          </S.ControlButton>
          <S.ResetButton
            id="reset-button"
            onClick={() => {
              reset();
              onInputChange && onInputChange("0");
            }}
          >
            {t("reset")}
          </S.ResetButton>
        </S.FormWrapper>
        <S.ErrorMessage>
          {errors.amount && t(`formAmountError-${errors.amount?.type}`)}
        </S.ErrorMessage>
        <S.MinAmount>{t("minValue5")}</S.MinAmount>
        <S.TilesWrapper>
          {TILES_AMOUNTS.map((tileAmount) => {
            const amountFormatted = formatCurrency(tileAmount);
            return (
              <S.AmountPresetTile
                key={tileAmount}
                id={`amount-preset-tile${tileAmount}`}
                onClick={(e) => {
                  e.preventDefault();
                  setValue("amount", amount + tileAmount, {
                    shouldValidate: true,
                  });
                  onInputChange && onInputChange(`${amount + tileAmount}`);
                }}
              >
                {`+ ${amountFormatted}`}
              </S.AmountPresetTile>
            );
          })}
        </S.TilesWrapper>
        {children(amount)}
        <Footer>
          <FooterButtonSmall
            secondary
            onClick={onCloseForm}
            id="cancel-transaction-button"
          >
            {t("general:cancel")}
          </FooterButtonSmall>
          <FooterButtonSmall
            disabled={isFormProcessing}
            pending={isFormProcessing}
            type="submit"
            id="submit-transaction-button"
          >
            {isFormProcessing ? (
              <>
                <span>{t("general:processing")}</span>
                <LoadingAnimation spinner />
              </>
            ) : (
              <span>{submitButtonLabel}</span>
            )}
          </FooterButtonSmall>
        </Footer>
      </form>
    </S.PaddingWrapper>
  );
};
export { TransactionForm };
export type { FormFields };
