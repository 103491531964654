import styled from "styled-components";

import { grey500, grey600, white } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { FooterButton } from "../../atoms/Button/Button.style";
import { H1Decor, H4Decor } from "../../atoms/Typography";

const Wrapper = styled.div`
  background-color: ${white};
  width: 100%;
  padding: ${spacings.s} 0;
  border-top: 1px solid ${grey600};
`;

const SquaresAmountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacings.s};
`;

const Heading = styled(H4Decor)`
  width: 50%;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const SquaresAmountController = styled.div`
  display: flex;
  align-items: center;
`;

const Amount = styled(H1Decor)`
  width: 30px;
  text-align: center;
  margin: 0 ${spacings.l};
`;

const ButtonsWrapper = styled.div`
  padding: ${spacings.s};
  display: flex;
`;

const Button = styled(FooterButton)`
  width: 50%;
  padding: ${spacings.s} 0;
`;

const CartButton = styled(Button)`
  background-color: ${grey500};
  margin-right: ${spacings.s};
`;

const PurchaseButton = styled(Button)`
  width: 100%;
  height: 42px;
`;

export {
  Wrapper,
  SquaresAmountWrapper,
  Heading,
  SquaresAmountController,
  Amount,
  ButtonsWrapper,
  Button,
  CartButton,
  PurchaseButton,
};
