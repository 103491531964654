import abc from "../../../assets/icons/abc.svg";
import amazon from "../../../assets/icons/amazon-prime.svg";
import cbs from "../../../assets/icons/cbs.svg";
import espn from "../../../assets/icons/espn.svg";
import fox from "../../../assets/icons/fox.svg";
import nbc from "../../../assets/icons/nbc.svg";
import nfln from "../../../assets/icons/nfln.svg";
import { TvStation } from "../../../types/games";
import { Icon } from "../Icon";
import * as S from "./TvStationIcon.style";

interface TvStationIconProps {
  tvStation: TvStation;
}

const renderIcon = (tvStation: TvStation) => {
  switch (tvStation) {
    case TvStation.Fox:
      return fox;
    case TvStation.Nfln:
      return nfln;
    case TvStation.Amazon:
      return amazon;
    case TvStation.Cbs:
      return cbs;
    case TvStation.Espn:
      return espn;
    case TvStation.Nbc:
      return nbc;
    case TvStation.Abc:
      return abc;
  }
};

const TvStationIcon = ({ tvStation }: TvStationIconProps) => {
  return (
    <S.Wrapper>
      <Icon src={renderIcon(tvStation)} />
    </S.Wrapper>
  );
};

export { TvStationIcon };
