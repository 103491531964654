import styled from "styled-components";

import * as palette from "../../assets/styles/colors";
import { elementSizes, fontSizes, spacings } from "../../assets/styles/sizes";

const GamesWrapper = styled.div`
  background-color: ${palette.grey300};
  min-height: calc(100vh - ${elementSizes.topMenu});
`;

const TopContainer = styled.div`
  background: linear-gradient(200deg, rgba(70, 87, 119, 0.8) 1%, #282828 65%);
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 ${spacings.xs} ${spacings.l} ${spacings.xs};
  height: 50px;
`;

const Title = styled.h1`
  font-family: "Space Grotesk";
  font-weight: 500;
  text-transform: uppercase;
  font-size: ${fontSizes.xxxxl};
  line-height: ${fontSizes.xxxl};
  color: ${palette.white};
`;

const GamesListContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(
    100vh - ${elementSizes.topMenu} - ${elementSizes.bottomArea}
  );
`;

const GamesErrorMessage = styled.div`
  padding: ${spacings.l} ${spacings.xs};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GamesErrorMessageTitle = styled.h2`
  font-family: "Space Grotesk";
  font-size: ${fontSizes.l};
  margin-bottom: ${spacings.xs};
`;

const GamesErrorMessageText = styled.p`
  font-family: "Roboto";
  font-size: ${fontSizes.sm};
  line-height: ${spacings.l};
`;

export {
  GamesWrapper,
  TopContainer,
  HeadingWrapper,
  Title,
  GamesListContainer,
  GamesErrorMessage,
  GamesErrorMessageTitle,
  GamesErrorMessageText,
};
