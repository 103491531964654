import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import minus from "../../../assets/icons/minus.svg";
import plus from "../../../assets/icons/plus.svg";
import cart from "../../../assets/icons/shopping-cart.svg";
import { blue400, grey600 } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { ROUTES } from "../../../routes";
import { useAppDispatch } from "../../../store";
import { addCartItem, removeCartItem } from "../../../store/cartSlice";
import { TransparentButton } from "../../atoms/Button/Button.style";
import { Icon } from "../../atoms/Icon";
import { IconColor } from "../../atoms/IconColor";
import * as S from "./BuySquareForGame.style";

interface BuySquareForGameProps {
  gameId: string;
}

const BuySquareForGame = ({ gameId }: BuySquareForGameProps) => {
  const [amount, setAmount] = useState(1);
  const [addedToCart, setAddedToCart] = useState(false);
  const { t } = useTranslation("buySquare");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnMinusClick = () => {
    setAmount(amount - 1);
    dispatch(removeCartItem({ gameId: gameId, wagerAmount: 0 }));
  };

  const handleOnPlusClick = () => {
    setAmount(amount + 1);
    addedToCart && dispatch(addCartItem({ gameId: gameId, wagerAmount: 0 }));
  };

  const handleAddToCart = () => {
    setAddedToCart(true);
    for (let i = 0; i < amount; i++) {
      dispatch(addCartItem({ gameId: gameId, wagerAmount: 0 }));
    }
  };

  const handleBuyNow = () => {
    dispatch(addCartItem({ gameId: gameId, wagerAmount: 0 }));
    navigate(ROUTES.buySquare);
  };

  const handlePurchase = () => {
    navigate(ROUTES.buySquare);
  };

  return (
    <S.Wrapper>
      <S.SquaresAmountWrapper>
        <S.Heading>{t("buySquaresForGame")}</S.Heading>
        <S.SquaresAmountController>
          <TransparentButton
            disabled={amount <= 1}
            onClick={() => handleOnMinusClick()}
          >
            <IconColor
              src={minus}
              color={amount <= 1 ? grey600 : blue400}
              $size="17px"
            />
          </TransparentButton>
          <S.Amount>{amount}</S.Amount>
          <TransparentButton onClick={() => handleOnPlusClick()}>
            <IconColor src={plus} color={blue400} $size="17px" />
          </TransparentButton>
        </S.SquaresAmountController>
      </S.SquaresAmountWrapper>
      <S.ButtonsWrapper>
        {addedToCart ? (
          <S.PurchaseButton onClick={() => handlePurchase()}>
            {t("purchase")}
          </S.PurchaseButton>
        ) : (
          <>
            <S.CartButton onClick={() => handleAddToCart()}>
              {t("addToCart")}
              <Icon src={cart} $marginLeft={spacings.xxs} $size="20px" />
            </S.CartButton>
            <S.Button onClick={() => handleBuyNow()}>
              {t("buySquareNow")}
            </S.Button>
          </>
        )}
      </S.ButtonsWrapper>
    </S.Wrapper>
  );
};

export { BuySquareForGame };
