import styled from "styled-components";

import calendar from "../../../assets/icons/calendar.svg";
import chevron from "../../../assets/icons/chevron-right.svg";
import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { FlexWrapper } from "../../atoms/Containers";
import { H3 } from "../../atoms/Typography";

const FilterButton = styled.button`
  background-color: ${palette.greyDark02};
  border: none;
  border-radius: ${borderRadiuses.s};
  padding: 0;
  display: flex;
  align-items: center;
`;

// TODO no typograph element for that, same as in TopMenu
const ButtonLabel = styled.span`
  color: ${palette.white};
  text-transform: uppercase;
  font-size: ${fontSizes.s};
  line-height: ${lineHeights.xs};
`;

const ButtonValue = styled(H3)`
  color: ${palette.white};
  text-transform: uppercase;
  font-weight: 600;
`;

const FilterCalendarIcon = styled.img.attrs({ src: calendar })`
  width: 20px;
  height: 20px;
  background-color: ${palette.gold};
  border-radius: ${borderRadiuses.s};
  padding: ${spacings.sm};
  margin-right: ${spacings.xxs};
`;

const FilterChevronIcon = styled.img.attrs({ src: chevron })`
  padding-left: ${spacings.m};
  padding-right: ${spacings.l};
`;

const ButtonContent = styled(FlexWrapper)`
  min-width: 12rem;
`;

export {
  FilterButton,
  ButtonLabel,
  ButtonValue,
  FilterChevronIcon,
  FilterCalendarIcon,
  ButtonContent,
};
