import * as S from "./WarningTile.styles";

const WarningTile = ({
  title,
  infoText,
}: {
  title: string;
  infoText: string;
}) => {
  return (
    <S.WarningTileWrapper>
      <S.WarningTileDecor />
      <S.WarningTileContent>
        <S.WarningTileTitle>{title}</S.WarningTileTitle>
        <div>
          <S.WarningTileText>{infoText}</S.WarningTileText>
          {/* TODO Calculate and display that value basing on data fetched from API. Display commented out warning text once that value is calculated */}
          {/* <S.WarningTileTextBold>2 days 10h</S.WarningTileTextBold> */}
          {/* <S.WarningTileText>buySquare:warningText2</S.WarningTileText> */}
        </div>
      </S.WarningTileContent>
    </S.WarningTileWrapper>
  );
};

export { WarningTile };
