import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { LoadingAnimation } from "../../components/atoms/LoadingAnimation";
import { BuySquareSummary } from "../../components/organisms/BuySquareSummary";
import { BuySquareWager } from "../../components/organisms/BuySquareWager";
import { useAppDispatch } from "../../store";
import { selectIsPlacingBetStatusFulfilled } from "../../store/betsSlice";
import {
  selectCartItemsCount,
  selectGamesSelectedForBetting,
  updateGamesSelectedForBetting,
  updateWagerAmount,
} from "../../store/cartSlice";
import { selectBalance } from "../../store/walletSlice";
import * as S from "./BuySquareWizard.style";

const BuySquareWizard = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [wagerAmount, setWagerAmount] = useState("0");
  const [totalWagerAmount, setTotalWagerAmount] = useState("0");
  const gamesIds = useSelector(selectGamesSelectedForBetting);
  const gamesIdsCount = useSelector(selectCartItemsCount);
  const balance = useSelector(selectBalance);
  const isFulfilled = useSelector(selectIsPlacingBetStatusFulfilled);

  const dispatch = useAppDispatch();
  const { t } = useTranslation("buySquare");

  const handleWagerAmountChange = (newAmount: string) => {
    setWagerAmount(newAmount);
  };

  const handleStepClick = (step: number) => {
    if (currentStep === 2) {
      setCurrentStep(1);
    }
  };

  const handleSummaryOpen = (amount: string) => {
    const totalAmount = parseInt(amount) * gamesIdsCount;
    setTotalWagerAmount(totalAmount.toString());
    dispatch(updateWagerAmount({ wagerAmount: parseInt(amount) }));
    dispatch(updateGamesSelectedForBetting());
    totalAmount > 0 && totalAmount < balance && setCurrentStep(2);
  };

  const handleStepBack = (totalAmount: string) => {
    setCurrentStep(1);
    setTotalWagerAmount(totalAmount);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.BuySquareWrapper>
      <S.TopContainer>
        <S.HeadingWrapper>
          <S.Title>{t("buySquares")}</S.Title>
        </S.HeadingWrapper>
      </S.TopContainer>
      <S.StepHeader>
        <S.StepSelector>
          {[1, 2].map((stepNumber) => (
            <S.StepTile
              key={stepNumber}
              isActive={currentStep === stepNumber}
              onClick={() => handleStepClick(stepNumber)}
            >
              <S.StepNumber>{t(`step${stepNumber}Heading`)}</S.StepNumber>
              <S.StepText>{t(`step${stepNumber}Name`)}</S.StepText>
            </S.StepTile>
          ))}
        </S.StepSelector>
      </S.StepHeader>
      {(gamesIds == undefined || gamesIds.length === 0) && !isFulfilled ? (
        <LoadingAnimation label={t("loadingData")} />
      ) : (
        <>
          {currentStep === 1 && (
            <BuySquareWager
              gamesIds={gamesIds}
              singleWagerAmount={wagerAmount}
              onSummaryOpen={handleSummaryOpen}
              onWagerAmountChange={handleWagerAmountChange}
              totalWagerAmount={totalWagerAmount}
            />
          )}
          {currentStep === 2 && (
            <BuySquareSummary
              totalWagerAmount={totalWagerAmount.toString()}
              wagerAmount={wagerAmount}
              handleStepBack={handleStepBack}
            />
          )}
        </>
      )}
    </S.BuySquareWrapper>
  );
};

export { BuySquareWizard };
