import { useAuth0 } from "@auth0/auth0-react";
import { differenceInMilliseconds } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RootState } from "../../../store";
import { selectGameDetails } from "../../../store/gamesSlice";
import { GameDetails, GameStatus } from "../../../types/games";
import { Timer } from "../../atoms/Timer";
import { BuySquareForGame } from "../../molecules/BuySquaresForGame";
import { FinishedGameBalance } from "../../molecules/FinishedGameBalance";
import { FULL_WIDTH_BOTTOM_FULL_HEIGHT, Modal } from "../../molecules/Modal";
import { CloseModalButton } from "../../molecules/Modal/Modal.style";
import { RegisterButton } from "../../molecules/RegisterButton";
import { BoardDrawingModal } from "../BoardDrawingModal";
import { BeforeGameDetailsModal } from "./BeforeGameDetailsModal";
import { CanceledGameDetailsModal } from "./CanceledGameDetailsModal";
import { FinishedGameDetailsModal } from "./FinishedGameDetailsModal";
import * as S from "./GameDetailsModal.style";
import { LiveGameDetailsModal } from "./LiveGameDetailsModal";

interface GameDetailsModalProps {
  onModalClose: () => void;
  gameId: string;
}

const GameDetailsModal = ({ onModalClose, gameId }: GameDetailsModalProps) => {
  const { t } = useTranslation(["game", "general", "buySquare"]);
  const { isAuthenticated } = useAuth0();

  const [drawingBoardModalVisible, setDrawingBoardModalVisible] =
    useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const modalRef = useRef(null);

  const game = useSelector<RootState>((state) =>
    selectGameDetails(state, gameId)
  ) as GameDetails;

  const [timeDifference, setTimeDifference] = useState(
    differenceInMilliseconds(new Date(game.nextPhaseStartTime), new Date())
  );

  const handleDrawBoard = () => setDrawingBoardModalVisible(true);

  const totalInvestment = game.bets.reduce((acc, bet) => {
    const betAmount = parseFloat(bet.amount);
    return acc + betAmount;
  }, 0);
  const totalWinnings = game.totalWinningInGame;

  const timerEnded = timeDifference < 5;
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeDifference(
        differenceInMilliseconds(new Date(game.nextPhaseStartTime), new Date())
      );
      return () => clearInterval(interval);
    }, 1000);
  }, []);

  // Extracted Footer logic for clarity
  const renderFooter = () => {
    const closeButton = (
      <S.CloseModalFooterButton onClick={onModalClose} id="close-modal-button">
        {t("general:close")}
      </S.CloseModalFooterButton>
    );

    const drawingButton = (
      <S.DrawingButton onClick={handleDrawBoard} id="draw-board-button">
        {t("boardDrawing")}
      </S.DrawingButton>
    );

    if (game.displayTimer && game.isOpenForBets && timerEnded) {
      if (game.displayBoardDrawing || !isAuthenticated) {
        return (
          <S.ButtonWrapper>
            {closeButton}
            {drawingButton}
          </S.ButtonWrapper>
        );
      }

      return <BuySquareForGame gameId={game.id} />;
    }

    if (game.displayTimer && game.isOpenForBets && !timerEnded) {
      if (!isAuthenticated) {
        return closeButton;
      }

      return <BuySquareForGame gameId={game.id} />;
    }

    if (game.displayBoardDrawing) {
      return (
        <S.ButtonWrapper>
          {closeButton}
          {drawingButton}
        </S.ButtonWrapper>
      );
    }

    if (game.hasBets && game.status === GameStatus.PreDraw) {
      return (
        <S.ButtonWrapper>
          {closeButton}
          {drawingButton}
        </S.ButtonWrapper>
      );
    }

    return closeButton;
  };

  // TODO for debugging, let's keep it here for now
  useEffect(() => {
    console.log("game id: ", game.id);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (modalRef.current.scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    modalRef.current.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <S.ModalWrapper ref={modalRef} hasBets={game.hasBets}>
      <CloseModalButton
        aria-label={t("general:closeModal")}
        onClick={onModalClose}
        id="close-game-details-modal-button"
      />
      {game.isLive && (
        <LiveGameDetailsModal game={game} isScrolled={isScrolled} />
      )}
      {game.isBeforeGame && <BeforeGameDetailsModal game={game} />}
      {game.status === GameStatus.Finished && (
        <FinishedGameDetailsModal game={game} isScrolled={isScrolled} />
      )}
      {game.status === GameStatus.Canceled && (
        <CanceledGameDetailsModal game={game} isScrolled={isScrolled} />
      )}
      {game.hasBets && game.status === GameStatus.Finished && (
        <FinishedGameBalance
          totalInvestment={totalInvestment}
          totalWinnings={totalWinnings}
        />
      )}

      <S.FooterWrapper>
        {!isAuthenticated && (
          <S.RegisterButtonWrapper>
            <RegisterButton wide />
          </S.RegisterButtonWrapper>
        )}

        {renderFooter()}

        {game.displayTimer && (
          <Timer
            isGameModal
            gameStatus={game.status}
            time={game.nextPhaseStartTime}
            timeLeft={game.timeLeft}
            currentQuarter={game.quarter}
            timerEnded={timerEnded}
          />
        )}
      </S.FooterWrapper>
      <Modal
        contentPlacing={FULL_WIDTH_BOTTOM_FULL_HEIGHT}
        modalVisible={drawingBoardModalVisible}
        onModalClose={() => setDrawingBoardModalVisible(false)}
      >
        <BoardDrawingModal
          revealed={game.scoresRevealedByCurrentUser}
          gameId={game.id}
          onModalClose={() => setDrawingBoardModalVisible(false)}
        />
      </Modal>
    </S.ModalWrapper>
  );
};

export { GameDetailsModal };
