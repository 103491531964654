import { Link } from "react-router-dom";
import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  spacings,
} from "../../../assets/styles/sizes";
import { H1, buttonText } from "../Typography";

const SlideWrapper = styled.div<{ imgSrc?: string; backgroundColor?: string }>`
  width: 80vw;
  height: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-image: ${(p) => `url(${p.imgSrc})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(p) => p.backgroundColor};
  border-radius: ${borderRadiuses.m};
  position: relative;

  &::after {
    content: "";
    background-color: ${palette.blue400};
    opacity: 0.8;
    top: 0;
    left: -1px;
    bottom: 0;
    right: 0;
    position: absolute;
    clip-path: polygon(0% 0%, 60% 0%, 80% 100%, 0% 100%);
    display: ${(p) => (p.imgSrc ? "block" : "none")};
    border-radius: ${borderRadiuses.m};
  }
`;

const SlideContent = styled.div`
  padding: ${spacings.sm};
  z-index: 2;
`;

const Header = styled(H1)`
  color: ${palette.white};
  font-weight: 400;
  inline-size: 70%;
  margin-bottom: ${spacings.s};
  z-index: 3;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const HeaderSmallFont = styled(Header)`
  font-size: ${fontSizes.sm};
`;

const SlideButton = styled(Link)`
  ${buttonText}
  display: block;
  padding: ${spacings.sm} ${spacings.sm};
  border-radius: ${borderRadiuses.sm};
  text-transform: uppercase;
  font-weight: 600;
  width: fit-content;
  text-transform: uppercase;
  color: ${palette.grey900};
  border: 1px solid ${palette.greyDark02};
  border-radius: ${borderRadiuses.s};
  background-color: ${palette.gold};
  z-index: 3;

  &::before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export { SlideWrapper, SlideContent, Header, SlideButton, HeaderSmallFont };
