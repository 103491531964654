import { GameBalanceTile } from "../../atoms/GameBalanceTile";
import { formatCurrency } from "../../../utils/currency";
import * as S from "./FinishedGameBalance.style";

interface FinishedGameBalanceProps {
  totalWinnings: number;
  totalInvestment: number;
}

const FinishedGameBalance = ({
  totalWinnings,
  totalInvestment,
}: FinishedGameBalanceProps) => {
  const totalWinningsFormatted = formatCurrency(totalWinnings);
  const totalInvestmentFormatted = formatCurrency(totalInvestment);

  const netGainLoss = totalWinnings - totalInvestment;
  const netGainLossFormatted = formatCurrency(netGainLoss);

  const netGainLossHeader = netGainLoss > 0 ? "netGain" : "netLoss";

  return (
    <S.GameBalanceSection>
      <GameBalanceTile
        headerKey="totalWinnings"
        value={totalWinningsFormatted}
      />
      <GameBalanceTile
        headerKey="totalInvestment"
        value={totalInvestmentFormatted}
      />
      <GameBalanceTile
        headerKey={netGainLossHeader}
        value={netGainLossFormatted}
      />
    </S.GameBalanceSection>
  );
};

export { FinishedGameBalance };
