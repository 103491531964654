import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import closeIcon from "../../../assets/icons/close.svg";
import { Form } from "../../atoms/Form/Form";
import { Icon } from "../../atoms/Icon";
import { FULL_WIDTH_CENTER, Modal } from "../Modal";
import * as S from "./PhoneModal.style";

const PhoneModal = () => {
  const [mounted, setMounted] = useState(false);
  const [phoneModalVisible, setPhoneModalVisible] = useState(true);
  const { t } = useTranslation("phoneModal");

  const onClose = () => {
    localStorage.setItem("phoneModalClosed", "true");
    setPhoneModalVisible(false);
  };

  useEffect(() => {
    setTimeout(() => setMounted(true), 5000);
  }, []);

  return (
    mounted && (
      <Modal
        contentPlacing={FULL_WIDTH_CENTER}
        modalVisible={phoneModalVisible}
        onModalClose={onClose}
        $transparentBackground
      >
        <S.PhoneModalWrapper>
          <S.CloseModalButton onClick={onClose} id="close-phone-modal-button">
            <Icon src={closeIcon} />
          </S.CloseModalButton>
          <S.ModalHeading>{t("heading")}</S.ModalHeading>
          <S.ModalText>{t("modalText")}</S.ModalText>
          <Form
            FormWrapper={S.FormWrapper}
            InputLabel={S.InputLabel}
            Input={S.Input}
            ErrorMessage={S.ErrorMessage}
            SubscribeButton={S.SubscribeButton}
            CheckMarkIcon={S.CheckMarkIcon}
          />
        </S.PhoneModalWrapper>
      </Modal>
    )
  );
};

export { PhoneModal };
