const spacings = {
  xxxs: "0.2rem",
  xxs: "0.5rem",
  xs: "0.8rem",
  s: "1rem",
  sm: "1.3rem",
  m: "1.6rem",
  l: "2rem",
  xl: "3.8rem",
  xxl: "5rem",
};

const fontSizes = {
  xs: "0.8rem",
  s: "1rem",
  sm: "1.4rem",
  m: "1.6rem",
  l: "2rem",
  xl: "2.2rem",
  xxl: "2.4rem",
  xxxl: "3rem",
  xxxxl: "3.7rem",
};

const lineHeights = {
  xs: "1rem",
  s: "1.2rem",
  sm: "1.6rem",
  m: "2rem",
  l: "2.4rem",
  xxl: "2.8rem",
  xxxl: "4.7rem",
};

const borderRadiuses = {
  xs: "0.2rem",
  s: "0.3rem",
  sm: "0.5rem",
  m: "1rem",
  l: "2rem",
};

const elementSizes = {
  topMenu: "90px",
  bottomArea: "95px",
};

export { spacings, fontSizes, lineHeights, borderRadiuses, elementSizes };
