import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import check from "../../../assets/icons/check-circle.svg";
import { useAppDispatch } from "../../../store";
import {
  initiatePayment,
  selectConfirmPaymentPending,
  selectInitiatePaymentPending,
} from "../../../store/walletSlice";
import { Icon } from "../../atoms/Icon";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { FULL_WIDTH_BOTTOM_FULL_HEIGHT, Modal } from "../../molecules/Modal";
import {
  CloseModalButton,
  ModalHeader,
  ModalHeading,
} from "../../molecules/Modal/Modal.style";
import { WalletFunds } from "../../molecules/WalletFunds";
import { CheckoutSummaryModal } from "../CheckoutSummaryModal";
import { TransactionForm } from "../TransactionForm";
import * as S from "./TopOffWalletModal.style";

const FormChildrenContent = () => {
  const { t } = useTranslation("wallet");

  return (
    <S.PaymentMethodWrapper>
      <S.PaymentMethodLabel>{t("paymentMethod")}</S.PaymentMethodLabel>
      <S.MethodWrapper as="div" active>
        <S.MethodName>{t("creditCard")}</S.MethodName>
        <S.MethodDetailsWrapper>
          <S.MethodDetailsLabel>{t("general:fee")}</S.MethodDetailsLabel>
          <Icon src={check} />
        </S.MethodDetailsWrapper>
      </S.MethodWrapper>
    </S.PaymentMethodWrapper>
  );
};
interface TopOffWalletModalProps {
  onModalClose: () => void;
}

const TopOffWalletModal = ({ onModalClose }: TopOffWalletModalProps) => {
  const [feeModalVisible, setFeeModalVisible] = useState(false);
  const { t } = useTranslation("wallet");
  const dispatch = useAppDispatch();

  const isInitiatePaymentPending = useSelector(selectInitiatePaymentPending);
  const isConfirmPaymentPending = useSelector(selectConfirmPaymentPending);

  const handleInitiatePayment = (amount: string): void => {
    dispatch(initiatePayment({ amount }));
    setFeeModalVisible(true);
  };

  return (
    <>
      <S.ModalWrapper>
        <CloseModalButton
          aria-label={t("general:closeModal")}
          onClick={onModalClose}
          goldBackground
          id="close-top-off-wallet-modal-button"
        />
        <ModalHeader withBackground>
          <ModalHeading>{t("addFunds")}</ModalHeading>
        </ModalHeader>
        {isConfirmPaymentPending ? (
          <LoadingAnimation label={t("general:loading")} fullScreen />
        ) : (
          <>
            <WalletFunds />
            <TransactionForm
              onSubmit={handleInitiatePayment}
              onCloseForm={onModalClose}
              isFormProcessing={isInitiatePaymentPending}
              inputLabel={t("amount")}
              submitButtonLabel={t("topOffWallet")}
            >
              {() => <FormChildrenContent />}
            </TransactionForm>
          </>
        )}
      </S.ModalWrapper>
      <Modal
        contentPlacing={FULL_WIDTH_BOTTOM_FULL_HEIGHT}
        modalVisible={feeModalVisible}
        onModalClose={() => setFeeModalVisible(false)}
      >
        <CheckoutSummaryModal onModalClose={() => setFeeModalVisible(false)} />
      </Modal>
    </>
  );
};

export { TopOffWalletModal };
