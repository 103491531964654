import { format, formatDistanceToNowStrict } from "date-fns";
import { useTranslation } from "react-i18next";

import timer from "../../../assets/icons/timer.svg";
import { black, white } from "../../../assets/styles/colors";
import { GameStatus } from "../../../types/games";
import { IconColor } from "../IconColor";
import { H4 } from "../Typography";
import * as S from "./Timer.style";

interface TimerProps {
  time: string;
  gameStatus: GameStatus;
  isGameModal?: boolean;
  isLive?: boolean;
  timeLeft?: string;
  currentQuarter?: string;
  timerEnded?: boolean;
}

const Timer = ({
  gameStatus,
  time,
  isGameModal,
  timeLeft,
  currentQuarter,
  isLive = gameStatus === GameStatus.Live,
  timerEnded,
}: TimerProps) => {
  const { t } = useTranslation("timer");

  const dateFormatted = format(new Date(time), "E M/dd, h:mm aaa");
  const labelText =
    (currentQuarter === "Half" && t("halftime")) ||
    (currentQuarter === "OT" &&
      t("timeLeftInOvertime", {
        timeLeft: timeLeft,
      })) ||
    t("timeLeftInQuarter", {
      quarter: currentQuarter,
      timeLeft: timeLeft,
    });

  return (
    <S.TimerWrapper isGameModal={isGameModal}>
      {!isLive && timerEnded ? (
        <S.RefreshWrapper>
          <S.RefreshHeading isGameModal={isGameModal}>
            {t("statusChanged")}
          </S.RefreshHeading>
        </S.RefreshWrapper>
      ) : (
        <>
          <IconColor
            src={timer}
            color={isGameModal ? white : black}
            $size={isGameModal ? "16px" : "23px"}
          />
          <S.TimerContent isGameModal={isGameModal}>
            <S.Heading isGameModal={isGameModal}>{t(gameStatus)}</S.Heading>
            {isLive && timeLeft && <H4>{labelText}</H4>}
            {!isLive && (
              <S.Time isGameModal={isGameModal}>
                {formatDistanceToNowStrict(new Date(time))} - {dateFormatted}
              </S.Time>
            )}
          </S.TimerContent>
        </>
      )}
    </S.TimerWrapper>
  );
};

export { Timer };
