import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { GameStatusFiltersValues } from "../../../types/filters";

const Input = styled.input`
  position: absolute;
  opacity: 0.00001;
  height: 5.5rem;
  width: 5.5rem;
  margin: 0;
`;

const statusStylesMap: { [key in GameStatusFiltersValues]: string } = {
  all: palette.gold,
  pre_draw: palette.green,
  pre_game: palette.blue300,
  finished: palette.grey700,
  live: palette.goldDark,
  open_for_bets: palette.purple,
};

const StatusFilterTab = styled.div<{
  status: GameStatusFiltersValues;
  active: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0px;
  width: 65px;
  text-transform: uppercase;
  opacity: ${(props) => (props.active ? "1" : "0.5")};
  color: ${palette.white};
  border-bottom: 2px solid
    ${(props) => (props.active ? statusStylesMap[props.status] : "transparent")};
`;

const InputLabel = styled.label`
  margin-top: ${spacings.xxs};
`;

export { StatusFilterTab, Input, InputLabel };
