import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import bell from "../../../assets/icons/bell.svg";
import logo from "../../../assets/icons/logo.svg";
import { white } from "../../../assets/styles/colors";
import { useGameContext } from "../../../context/GameContext";
import { ROUTES } from "../../../routes";
import { selectCartItemsCount } from "../../../store/cartSlice";
import { pushNotification } from "../../../utils/pushNotification";
import { Banner } from "../../atoms/Banner";
import { Icon } from "../../atoms/Icon";
import { IconColor } from "../../atoms/IconColor";
import { AuthenticatedUserModal } from "../../molecules/AuthenticatedUserModal";
import { CartStatus } from "../../molecules/CartStatus";
import { FULL_WIDTH_BOTTOM_FULL_HEIGHT, Modal } from "../../molecules/Modal";
import { RegisterButton } from "../../molecules/RegisterButton";
import { WalletTopOffButton } from "../../molecules/WalletTopOffButton";
import { TopOffWalletModal } from "../TopOffWalletModal";
import * as S from "./TopMenu.style";

const TopMenu = () => {
  const [topOffModalVisible, setTopOffModalVisible] = useState(false);

  const { isAuthenticated, isLoading, user } = useAuth0();
  const { handleGameDetailsClose } = useGameContext();
  const navigate = useNavigate();
  const cartItemsCount = useSelector(selectCartItemsCount);

  const handleOnLogoClick = () => {
    navigate(ROUTES.games);
    handleGameDetailsClose();
  };

  return (
    <>
      <S.Wrapper>
        <Banner />
        <S.Content threeSections={!isLoading && !!user}>
          <S.LogoAndNotificationSection>
            <S.LogoButton onClick={handleOnLogoClick}>
              <Icon src={logo} $height="40px" $width="32px" />
            </S.LogoButton>
            <S.NotificationButton onClick={() => pushNotification()}>
              <IconColor src={bell} color={white} $size="28px" />
            </S.NotificationButton>
          </S.LogoAndNotificationSection>
          {!isLoading && !isAuthenticated && <RegisterButton />}
          {!isLoading && user && (
            <>
              <WalletTopOffButton onClick={() => setTopOffModalVisible(true)} />
              <S.CartAndUserSection>
                <CartStatus cartSquareCount={cartItemsCount} />
                <AuthenticatedUserModal user={user} />
              </S.CartAndUserSection>
            </>
          )}
        </S.Content>
      </S.Wrapper>
      <Modal
        contentPlacing={FULL_WIDTH_BOTTOM_FULL_HEIGHT}
        modalVisible={topOffModalVisible}
        onModalClose={() => setTopOffModalVisible(false)}
      >
        <TopOffWalletModal onModalClose={() => setTopOffModalVisible(false)} />
      </Modal>
    </>
  );
};

export { TopMenu };
