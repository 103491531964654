import { RootState } from "../store";
import { selectGameTeams } from "../store/gamesSlice";
import { ResourceDeserialized } from "../types/api";
import {
  GameAttributes,
  GameAttrsWithTeamsAndBets,
  GameDetails,
  GameStatus,
} from "../types/games";
import { formatCurrency } from "../utils/currency";
import { totalMyPoolValue } from "../utils/winningsCalculation";

const isDisplayingBoardDrawing = (status: GameStatus): boolean => {
  return (
    status === GameStatus.PreDraw ||
    status === GameStatus.PreGame ||
    status === GameStatus.Live ||
    status === GameStatus.Finished
  );
};

const isDisplayingBucketBetters = (
  isDisplayingBoardDrawing: boolean
): boolean => {
  return isDisplayingBoardDrawing;
};

const serializeGame = (
  game: ResourceDeserialized<GameAttributes>,
  state: RootState
): GameAttrsWithTeamsAndBets<{
  totalPoolValueFormatted: string;
  displayBoardDrawing: boolean;
  displayBucketBetters: boolean;
  displayTimer: boolean;
}> => {
  const { totalPoolValue, ...rest } = game.attributes;
  const teams = selectGameTeams(state, game.id);
  const betsCount = state.games.byId[game.id]?.relationships?.bets?.length;
  const hasBets = betsCount > 0;
  const displayBoardDrawing = isDisplayingBoardDrawing(game.attributes.status);
  const displayBucketBetters = isDisplayingBucketBetters(displayBoardDrawing);
  const displayTimer =
    game.attributes.status !== GameStatus.Finished &&
    game.attributes.status !== GameStatus.Canceled;

  return {
    id: game.id,
    ...rest,
    ...teams,
    totalPoolValue,
    totalPoolValueFormatted: formatCurrency(parseFloat(totalPoolValue)),
    hasBets,
    betsCount,
    displayBoardDrawing,
    displayBucketBetters,
    displayTimer,
  };
};

const serializeGameBet = (betId: string, state: RootState) => {
  const {
    amount,
    quarter1PotentialWinning,
    quarter2PotentialWinning,
    quarter3PotentialWinning,
    finalQuarterPotentialWinning,
    totalWinnings,
    preGameProbability,
    quarter1Winning,
    quarter2Winning,
    quarter3Winning,
    finalQuarterWinning,
    quarter1Won,
    quarter2Won,
    quarter3Won,
    finalQuarterWon,
    ...rest
  } = { ...state.bets.byId[betId]?.attributes };

  const allPotentialWinnings =
    (quarter1Winning !== "0" && parseFloat(quarter1PotentialWinning)) +
    (quarter2Winning !== "0" && parseFloat(quarter2PotentialWinning)) +
    (quarter3Winning !== "0" && parseFloat(quarter3PotentialWinning)) +
    (finalQuarterWinning !== "0" && parseFloat(finalQuarterPotentialWinning));

  const winningQuarters = [
    (quarter1Won && "q1") ||
      (quarter2Won && "q2") ||
      (quarter3Won && "q3") ||
      (finalQuarterWon && "final"),
  ];

  return {
    id: betId,
    ...rest,
    amount,
    preGameProbability: `${preGameProbability} %`,
    totalWinnings,
    hasWinnings: parseFloat(totalWinnings) > 0,
    totalWinningsFormatted:
      totalWinnings && formatCurrency(parseFloat(totalWinnings)),
    amountFormatted: amount && formatCurrency(parseFloat(amount)),
    quarter1PotentialWinningFormatted:
      quarter1PotentialWinning &&
      formatCurrency(parseFloat(quarter1PotentialWinning)),
    quarter2PotentialWinningFormatted:
      quarter2PotentialWinning &&
      formatCurrency(parseFloat(quarter2PotentialWinning)),
    quarter3PotentialWinningFormatted:
      quarter3PotentialWinning &&
      formatCurrency(parseFloat(quarter3PotentialWinning)),
    finalQuarterPotentialWinningFormatted:
      finalQuarterPotentialWinning &&
      formatCurrency(parseFloat(finalQuarterPotentialWinning)),
    totalPotentialWinnings: allPotentialWinnings,
    totalPotentialWinningsFormatted: formatCurrency(allPotentialWinnings),
    quarter1WinningFormatted:
      quarter1Winning && formatCurrency(parseFloat(quarter1Winning)),
    quarter2WinningFormatted:
      quarter2Winning && formatCurrency(parseFloat(quarter2Winning)),
    quarter3WinningFormatted:
      quarter3Winning && formatCurrency(parseFloat(quarter3Winning)),
    finalQuarterWinningFormatted:
      finalQuarterWinning && formatCurrency(parseFloat(finalQuarterWinning)),
    winningQuarters: winningQuarters,
  };
};

const serializeGameDetails = (
  game: ResourceDeserialized<GameAttributes>,
  state: RootState
): GameDetails | undefined => {
  if (!game) {
    return undefined;
  }

  const gameTeams = selectGameTeams(state, game.id);

  const betsIds = state.games.byId[game.id].relationships?.bets || [];
  const bets = betsIds.map((betId) => serializeGameBet(betId, state));

  const totalWinningInGame = bets.reduce(
    (acc, bet) => acc + parseInt(bet.totalWinnings),
    0
  );

  const totalInvestment = bets.reduce((acc, bet) => {
    const betAmount = parseInt(bet.amount);
    return acc + betAmount;
  }, 0);

  const { status, averageBucketSize, totalPoolValue, ...rest } =
    game.attributes;

  const isBeforeGame =
    status === GameStatus.OpenForBets ||
    status === GameStatus.PreDraw ||
    status === GameStatus.PreGame ||
    status === GameStatus.Scheduled;

  const isOpenForBets = status === GameStatus.OpenForBets;
  const hasBets = bets.length > 0;
  const displayBoardDrawing = isDisplayingBoardDrawing(status);
  const displayBucketBetters = isDisplayingBucketBetters(displayBoardDrawing);
  const displayTimer =
    status !== GameStatus.Finished && status !== GameStatus.Canceled;
  const isLive = status === GameStatus.Live;

  const potentialPayouts = bets.map((bet) =>
    bet.quarter1PotentialWinningFormatted
      ? bet.totalPotentialWinnings
      : totalMyPoolValue(bet.amount)
  );
  const maxPotentialPayout = Math.max(...potentialPayouts);
  const maxPotentialPayoutFormatted = formatCurrency(maxPotentialPayout);

  const betsWon = bets
    .filter((bet) => bet.hasWinnings)
    .sort((a, b) => parseInt(b.totalWinnings) - parseInt(a.totalWinnings));
  const betsNotWon = bets
    .filter((bet) => !bet.hasWinnings)
    .sort((a, b) => parseInt(b.amount) - parseInt(a.amount));
  const betsSorted = [...betsWon, ...betsNotWon];

  return {
    id: game.id,
    status,
    totalPoolValue,
    averageBucketSize,
    averageBucketSizeFormatted: formatCurrency(parseFloat(averageBucketSize)),
    totalPoolValueFormatted: formatCurrency(parseFloat(totalPoolValue)),
    bets,
    hasBets,
    isBeforeGame,
    isOpenForBets,
    hasWinningInGame: totalWinningInGame > 0,
    totalWinningInGameFormatted: formatCurrency(totalWinningInGame),
    totalWinningInGame,
    totalInvestment,
    displayBoardDrawing,
    displayBucketBetters,
    displayTimer,
    isLive,
    betsSorted,
    maxPotentialPayoutFormatted,
    ...rest,
    ...gameTeams,
  };
};

export { serializeGame, serializeGameDetails };
