const enum StatusEnum {
  Idle = "IDLE",
  Pending = "PENDING",
  Fulfilled = "FULFILLED",
  Rejected = "REJECTED",
}

interface Error {
  message?: string;
  stack?: string;
  code?: string;
}

interface GenericState<T> {
  byId: { [id: string]: T };
  allIds: string[];
  status?: StatusEnum;
  error?: Error;
}

interface ResourceDeserialized<ResourceAttributes> {
  id: string;
  type: string;
  attributes: ResourceAttributes;
  relationships?: { [relatedObjectName: string]: string[] };
}

export { StatusEnum };
export type { GenericState, ResourceDeserialized };
