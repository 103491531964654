import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { borderRadiuses, spacings } from "../../../assets/styles/sizes";
import { H4Decor, H6Decor } from "../../atoms/Typography";
import { SquareSection } from "../RevealedSquare/RevealedSquare.style";

const SquareInfoWrapper = styled.div`
  min-height: 66px;
  background-color: ${palette.white};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: ${borderRadiuses.sm};

  &:not(:first-child) {
    margin-top: ${spacings.xxs};
  }
`;

const SquareNotRevealed = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  background: ${palette.doveGrey};
  padding: ${spacings.xxs};
  border-top: 1px solid ${palette.grey400};
  border-bottom: 1px solid ${palette.grey400};
  border-left: 1px solid ${palette.grey400};
  border-bottom-left-radius: ${borderRadiuses.sm};
  border-top-left-radius: ${borderRadiuses.sm};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SquareNotRevealedLabel = styled(H4Decor)`
  color: ${palette.blue400};
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const SquareValueSection = styled(SquareSection)`
  grid-column-start: 2;
  grid-column-end: 3;
  padding-left: 0;
  padding-right: 0;
  border-top: 1px solid ${palette.grey400};
  border-bottom: 1px solid ${palette.grey400};
`;

const SquarePoolSection = styled(SquareValueSection)`
  grid-column-start: 3;
  grid-column-end: 4;
`;

const SquarePrizesSection = styled(SquareValueSection)`
  padding-left: ${spacings.xxxs};
  grid-column-start: 4;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-top-right-radius: ${borderRadiuses.sm};
  border-bottom-right-radius: ${borderRadiuses.sm};
`;

const PrizeLabel = styled(H6Decor)`
  &::first-letter {
    text-transform: uppercase;
  }
`;

export {
  SquareInfoWrapper,
  SquareNotRevealed,
  SquareNotRevealedLabel,
  SquareValueSection,
  SquarePoolSection,
  SquarePrizesSection,
  PrizeLabel,
};
