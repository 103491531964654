import styled from "styled-components";

import {
  gold,
  grey400,
  grey600,
  grey700,
  grey800,
  greyDark02,
  white,
} from "../../assets/styles/colors";
import { elementSizes, spacings } from "../../assets/styles/sizes";
import { FooterButtonSmall } from "../../components/atoms/Button/Button.style";
import { H3, H4, H4Decor } from "../../components/atoms/Typography";

const PersonalInformationWrapper = styled.div`
  background-color: ${grey800};
  min-height: calc(100vh - ${elementSizes.topMenu});
  padding-bottom: ${spacings.l};
  color: ${white};
`;

const HeaderWrapper = styled.div`
  padding: 0 ${spacings.s};
  display: flex;
  align-items: center;
  height: 50px;
`;

const HeaderLabel = styled(H3)`
  margin-left: ${spacings.s};
  text-transform: uppercase;
  color: ${grey700};
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-bottom: ${spacings.xxl};
`;

const BackgroundImage = styled.img`
  display: block;
  width: 100%;
  height: 220px;
  object-fit: cover;
  object-position: 100% 10%;
  filter: brightness(0.95);
`;

const PersonalImage = styled.img`
  object-fit: cover;
  border-radius: 50%;
`;

const AvatarWrapper = styled.div`
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -50px;
  width: fit-content;
`;

const Content = styled.div`
  padding: 0 ${spacings.s};
`;

const Label = styled(H4)`
  margin-bottom: ${spacings.xxs};
  color: ${grey600};
`;

const Name = styled(H4Decor)`
  margin-bottom: ${spacings.m};
  min-height: 20px;
`;

const Email = styled(Name)`
  &::first-letter {
    text-transform: none;
  }
`;

const Divider = styled.div`
  width: 80%;
  height: 1px;
  background-color: ${grey700};
  margin: ${spacings.m} auto;
  display: block;
`;

const AccountSettingsWrapper = styled.div`
  padding: 0 ${spacings.s};
`;

const AccountSettingsButton = styled.button`
  background-color: ${greyDark02};
  color: ${gold};
  border: 1px solid ${gold};
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
  margin: ${spacings.xs} 0;
`;

const FooterWrapper = styled.div`
  display: flex;
`;

const Button = styled(FooterButtonSmall)`
  background-color: ${grey400};
  flex-grow: 1;
  margin-left: ${spacings.xs};
`;

export {
  PersonalInformationWrapper,
  HeaderWrapper,
  HeaderLabel,
  BackgroundImage,
  ImageWrapper,
  PersonalImage,
  AvatarWrapper,
  Content,
  Label,
  Name,
  Email,
  Divider,
  AccountSettingsWrapper,
  AccountSettingsButton,
  FooterWrapper,
  Button,
};
