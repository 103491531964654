import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { fontSizes } from "../../../assets/styles/sizes";
import { H2Decor, H4 } from "../Typography";

const bg = palette.grey300;

const TicketWrapper = styled.div`
  position: relative;
  width: 100%;
  /* TODO if needed make this based on a variable, therefore heights of other elements will be dynamic e.g. Rip */
  height: 60px;
  margin: 5px 2px;
`;

const Shape = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  overflow: hidden;
`;

const Top = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${bg};
  border-radius: 3px 3px 0 0;
`;

const Rip = styled.div`
  position: relative;
  height: 16px;
  flex-shrink: 0;
  margin: 0 8px;
  background-color: ${bg};

  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    border: 16px solid transparent;
    border-top-color: ${bg};
    border-right-color: ${bg};
    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 50%;
    top: 50%;
  }

  &::before {
    left: -8px;
  }

  &::after {
    right: -56px;
    transform: translate(-50%, -50%) rotate(225deg);
  }
`;

const Bottom = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${bg};
  border-radius: 0 0 3px 3px;
`;

const Content = styled.div<{ $horizontal: boolean }>`
  position: relative;
  z-index: 1;
  padding: ${(props) => (props.$horizontal ? "1.5rem" : "1rem")};
  display: flex;
  flex-direction: ${(props) => (props.$horizontal ? "row-reverse" : "column")};
  justify-content: ${(props) => (props.$horizontal ? "space-around" : "")};
  align-items: center;
`;

const Amount = styled(H2Decor)<{ $small?: boolean }>`
  font-size: ${(props) => props.$small && fontSizes.sm};
`;

const Label = styled(H4)<{ $horizontal?: boolean }>`
  color: ${palette.grey900};
  text-transform: ${(props) => props.$horizontal && "uppercase"};
  font-size: ${(props) => props.$horizontal && fontSizes.sm};
`;

export { TicketWrapper, Shape, Content, Rip, Top, Bottom, Amount, Label };
