import { BetWithId } from "../../../types/bets";
import { GameStatus, GamePart } from "../../../types/games";
import { TeamAttributes } from "../../../types/teams";
import { PreDrawSquare } from "../PreDrawSquare";
import { PreGameSquare } from "../PreGameSquare";
import { RevealedSquare } from "../RevealedSquare";
import { SquareWithDetailsTable } from "../SquareWithDetailsTable";

interface SquareProps {
  id: string;
  gameStatus: GameStatus;
  teamHome: TeamAttributes;
  teamAway: TeamAttributes;
  bet: BetWithId;
  quarter?: GamePart;
}

const Square = ({
  id,
  gameStatus,
  teamAway,
  teamHome,
  bet,
  quarter,
}: SquareProps) => {
  switch (gameStatus) {
    case GameStatus.Live:
      return (
        <SquareWithDetailsTable
          key={id}
          teamHome={teamHome}
          teamAway={teamAway}
          bet={bet}
          quarter={quarter}
        />
      );
    case GameStatus.Finished:
      return (
        <RevealedSquare
          teamAway={teamAway}
          teamHome={teamHome}
          finishedGame={true}
          totalWinningsFormatted={bet.totalWinningsFormatted}
          amountFormatted={bet.amountFormatted}
          homeScore={bet.homeScore}
          awayScore={bet.awayScore}
          hasWinnings={bet.hasWinnings}
          winningQuarters={bet.winningQuarters}
        />
      );
    case GameStatus.OpenForBets:
      return <PreDrawSquare bet={bet} />;
    case GameStatus.PreDraw:
      return <PreDrawSquare bet={bet} />;
    case GameStatus.PreGame:
      return (
        <PreGameSquare
          teamAway={teamAway}
          teamHome={teamHome}
          id={id}
          bet={bet}
        />
      );

    default:
      return (
        <RevealedSquare
          teamAway={teamAway}
          teamHome={teamHome}
          amountFormatted={bet.amountFormatted}
          potentialPayoutFormatted={bet.totalPotentialWinningsFormatted}
        />
      );
  }
};
export { Square };
