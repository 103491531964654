import styled, { css } from "styled-components";

const H1Decor = styled.h1`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-transform: uppercase;
`;

const H1 = styled.h1`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
`;

const H2Decor = styled.h2`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
`;

const H2 = styled.h2`
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

const H3Decor = styled.h3`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const H3 = styled.h3`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const H4Decor = styled.h4`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const H4 = styled.h4`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
`;

const H5Decor = styled.h4`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const H5 = styled.h5`
  font-family: "Roboto";
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
`;

const H6Decor = styled.h5`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

const buttonText = css`
  font-family: "Roboto";
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
`;

export {
  buttonText,
  H1,
  H2,
  H3,
  H4,
  H5,
  H1Decor,
  H2Decor,
  H3Decor,
  H4Decor,
  H5Decor,
  H6Decor,
};
