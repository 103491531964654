import { ResourceDeserialized } from "../types/api";
import { BetAttributes } from "../types/bets";
import { GAME_PARTS, GameLiveUpdate } from "../types/games";
import { formatCurrency } from "./currency";

const calculateWinningsForGameParts = (
  liveUpdate: GameLiveUpdate,
  bet: ResourceDeserialized<BetAttributes>
): { [gamePartWinnigns: string]: string } => {
  const { quarter: currentQuarter } = liveUpdate;

  const winningForGameParts: { [gamePartKey: string]: string } = {};

  GAME_PARTS.every((quarter) => {
    if (currentQuarter === quarter) {
      return false;
    } else {
      const gamePartKey =
        quarter === "4" || quarter === "OT"
          ? "finalQuarter"
          : `quarter${quarter}`;

      const homeScoreMatch =
        liveUpdate[`${gamePartKey}HomeScore`] % 10 === bet.attributes.homeScore;

      const awayScoreMatch =
        liveUpdate[`${gamePartKey}AwayScore`] % 10 === bet.attributes.awayScore;

      const winningAmount =
        homeScoreMatch && awayScoreMatch
          ? bet.attributes[`${gamePartKey}PotentialWinning`]
          : "0";

      winningForGameParts[`${gamePartKey}Winning`] = winningAmount;

      return true;
    }
  });

  return winningForGameParts;
};

const quartersWithPercentage = {
  "1": 0.1,
  "2": 0.2,
  "3": 0.1,
  final: 0.5,
};

const calculateQuarterPotentialWinning = (
  betValue: string,
  quarter: string
) => {
  const potentialWinning =
    parseInt(betValue) * 100 * quartersWithPercentage[quarter];
  return {
    potentialWinning: potentialWinning,
    potentialWinningFormated: formatCurrency(potentialWinning),
  };
};

const totalMyPoolValue = (betValue: string) =>
  Object.keys(quartersWithPercentage).reduce(
    (acc, curr) =>
      acc + calculateQuarterPotentialWinning(betValue, curr).potentialWinning,
    0
  );

export {
  calculateWinningsForGameParts,
  quartersWithPercentage,
  calculateQuarterPotentialWinning,
  totalMyPoolValue,
};
