import styled from "styled-components";

import { fontSizes } from "../../../assets/styles/sizes";
import { buttonGeneralStyles } from "../Button/Button.style";

const AvatarWrapper = styled.div<{ size: "s" | "m" | "l" }>`
  ${buttonGeneralStyles}
  border-radius: 50%;
  min-height: ${(props) =>
    props.size === "l" ? "98px" : props.size === "m" ? "58px" : "38px"};
  min-width: ${(props) =>
    props.size === "l" ? "98px" : props.size === "m" ? "58px" : "38px"};
  max-height: ${(props) =>
    props.size === "l" ? "100px" : props.size === "m" ? "60px" : "40px"};
  max-width: ${(props) =>
    props.size === "l" ? "100px" : props.size === "m" ? "60px" : "40px"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarText = styled.span<{ size?: "s" | "m" | "l" }>`
  font-size: ${(props) =>
    props.size === "l"
      ? fontSizes.xxxl
      : props.size === "m"
      ? fontSizes.xxl
      : fontSizes.m};
`;

export { AvatarWrapper, AvatarText };
