import { useTranslation } from "react-i18next";

import * as S from "./GameBalanceTile.style";

interface GameBalanceTileProps {
  headerKey: string;
  value: string;
}

const GameBalanceTile = ({ value, headerKey }: GameBalanceTileProps) => {
  const { t } = useTranslation(["game"]);
  return (
    <S.GameBalanceTileWrapper>
      <S.TileHeader header={headerKey} id={`game-balance-header-${headerKey}`}>
        {t(headerKey)}
      </S.TileHeader>
      <S.TileValueWrapper
        as="div"
        header={headerKey}
        id={`game-balance-wrapper-${headerKey}`}
      >
        <S.TileValue>{value}</S.TileValue>
      </S.TileValueWrapper>
    </S.GameBalanceTileWrapper>
  );
};

export { GameBalanceTile };
