const green = "#8FEFB6";
const darkGreen = "#7FBC84";
const paleBlue = "#AADAFE";
const blue50 = "#F2F6FF";
const blue75 = "#E6F0FF";
const blue100 = "#D7E1F3";
const blue200 = "#c8e5ed";
const blue300 = "#8FB5FF";
const blue400 = "#146AB9";
const blue500 = "#10204A";
const yellowPale = "#FFEFAF";
const gold = "#FFCB13";
const goldDark = "#ECBE1C";
const red = "#D74848";
const redPale = "#E06E6E";
const white = "#FFFFFF";
const grey200 = "#F7F9FA";
const grey300 = "#F0F2F5";
const grey400 = "#D9D9D9";
const grey500 = "#CAD0D2";
const grey600 = "#C5C1C1";
const grey700 = "#9F9F9F";
const grey800 = "#282828";
const grey900 = "#282626";
const doveGrey = "#D7E1F3";
const gradientBlue =
  "linear-gradient(262.58deg, #52B6FF 15.77%, #406AFF 66.93%, #596AFF 92.76%)";
const gradientYellow =
  "linear-gradient(262.58deg, #FDB813 15.77%, #FFCB13 50.65%, #FFBD13 92.76%)";
const gradientGold =
  "linear-gradient(263deg, #FFB504 15.77%, #FFCB13 50.65%, #F9D16C 92.76%);";
const gradientRed =
  "linear-gradient(263deg, #FD1313 15.77%, #FF3E13 50.65%, #FF6813 92.76%)";
const gradientGreen =
  "linear-gradient(263deg, #088F36 15.77%, #1BAC4D 56.27%, #7CD452 92.76%)";
const gradientGrey =
  "linear-gradient(263deg, #2C3327 15.77%, #343023 50.65%, #645F52 92.76%);";
const greyLight07 = "rgba(224, 224, 224, 0.7)";
const greyLight05 = "rgba(255, 255, 255, 0.05)";
const greyDark05 = "rgba(0, 0, 0, 0.5)";
const greyDark02 = "rgba(121, 150, 159, 0.2)";
const lightBlue05 = "rgba(20, 106, 185, 0.05)";
const lightBlue60 = "rgba(20, 106, 185, 0.5)";
const lightPurple02 = "rgba(210, 154, 237, 0.20)";
const purple = "#D29AED";
const black = "#000000";

const boardSquareColors = {
  q1: "#5FC77C",
  q2: "#48B8E8",
  q3: "#11546A",
  final: "#8D85D6",
};

const teamColors = {
  cardinals: "#A43E55",
  falcons: "#A71930",
  ravens: "#241774",
  bills: "#1E4C9C",
  panthers: "#0185CA",
  bears: "#0B1629",
  bengals: "#FB4F14",
  browns: "#FF3C01",
  cowboys: "#012244",
  broncos: "#012244",
  lions: "#0086C5",
  packers: "#203731",
  texans: "#032130",
  colts: "#012D60",
  jaguars: "#292929",
  chiefs: "#E31836",
  raiders: "#000000",
  chargers: "#0080C7",
  rams: "#003595",
  dolphins: "#008E97",
  vikings: "#4F2683",
  patriots: "#012244",
  giants: "#0B2265",
  jets: "#003F2C",
  saints: "#DCC9A4",
  eagles: "#004C55",
  steelers: "#292929",
  "49ers": "#B82929",
  seahawks: "#1E3E5C",
  buccaneers: "#A71930",
  titans: "#002244",
  commanders: "#5A1414",
};

export {
  teamColors,
  boardSquareColors,
  darkGreen,
  white,
  yellowPale,
  gold,
  goldDark,
  blue50,
  blue75,
  green,
  blue100,
  blue200,
  blue300,
  blue400,
  blue500,
  paleBlue,
  red,
  redPale,
  grey200,
  grey300,
  grey400,
  grey500,
  grey600,
  grey700,
  grey800,
  grey900,
  greyLight07,
  greyLight05,
  greyDark05,
  greyDark02,
  lightBlue05,
  lightBlue60,
  doveGrey,
  gradientBlue,
  gradientYellow,
  gradientRed,
  gradientGreen,
  gradientGrey,
  gradientGold,
  purple,
  black,
  lightPurple02,
};
