import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { Camelized } from "humps";

import { AppThunk, RootState } from ".";
import { apiClient } from "../api/apiClient";
import { ENDPOINTS } from "../api/apiEndpoints";
import {
  DeserializedJsonApiResource,
  DeserializedJsonApiResponse,
  SingleResourcePayloadSerialized,
  serializeSingleResource,
} from "../api/apiSerializer";
import { getAuthHeader } from "../api/auth";
import { serializeGameDetails } from "../serializers/gameSerializer";
import { GenericState, ResourceDeserialized, StatusEnum } from "../types/api";
import { BetAttributes } from "../types/bets";
import {
  GAME_PARTS,
  GameAttributes,
  GameDetails,
  GameLiveUpdate,
  GameTeams,
  SeasonPhase,
} from "../types/games";
import { TeamAttributes } from "../types/teams";
import {
  fetchBets,
  placeBet,
  updateBetsProbabilityAndWinning,
} from "./betsSlice";
import { applyWeekFilter, setLastSelectedWeek } from "./gameFiltersSlice";

interface GamesScheduleState
  extends Exclude<
    GenericState<ResourceDeserialized<GameAttributes>>,
    "status"
  > {
  currentWeek: number | null;
  currentSeasonPhase: SeasonPhase;
  fetchCurrentWeekGamesStatus: StatusEnum;
  fetchCurrentSeasonGamesStatus: StatusEnum;
  liveUpdateStatus: StatusEnum;
  errorMessage: string | null;
}

interface RevealScoresBoardPayload {
  id: string;
}

const initialState: GamesScheduleState = {
  currentWeek: null,
  currentSeasonPhase: "unset",
  byId: {},
  allIds: [],
  fetchCurrentWeekGamesStatus: StatusEnum.Idle,
  fetchCurrentSeasonGamesStatus: StatusEnum.Idle,
  liveUpdateStatus: StatusEnum.Idle,
  errorMessage: null,
};

const fetchCurrentWeekGames = createAsyncThunk(
  "games/fetchCurrentWeek",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const headers = await getAuthHeader();
      const response = (await apiClient.get(ENDPOINTS.getGames, {
        headers,
        params: {
          data: {
            attributes: {
              for_current_week: true,
            },
          },
        },
      })) as AxiosResponse<
        DeserializedJsonApiResponse<
          GameAttributes,
          TeamAttributes | BetAttributes
        >
      >;

      const bets = response.data.entities.bets as {
        [id: string]: DeserializedJsonApiResource<BetAttributes>;
      };

      const hasBets = typeof bets === "object";
      if (hasBets) {
        dispatch(fetchBets(bets));
      }

      const weekFilterPayload = {
        week: response.data.meta?.currentWeek || 0,
        seasonPhase: response.data.meta?.currentSeasonPhase || "regular",
      };

      dispatch(applyWeekFilter(weekFilterPayload));
      dispatch(setLastSelectedWeek(weekFilterPayload.week));
      dispatch(fetchCurrentSeasonGames());

      return response.data;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      return rejectWithValue(error);
    }
  }
);

const fetchCurrentSeasonGames = createAsyncThunk(
  "games/fetchCurrentSeason",
  async (undefined, { dispatch, rejectWithValue }) => {
    try {
      const headers = await getAuthHeader();
      const response = (await apiClient.get(ENDPOINTS.getGames, {
        headers,
      })) as AxiosResponse<
        DeserializedJsonApiResponse<
          GameAttributes,
          TeamAttributes | BetAttributes
        >
      >;

      const bets = response.data.entities.bets as {
        [id: string]: DeserializedJsonApiResource<BetAttributes>;
      };

      const hasBets = typeof bets === "object";
      if (hasBets) {
        dispatch(fetchBets(bets));
      }
      return response.data;
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      return rejectWithValue(error);
    }
  }
);

const revealScoresBoard = createAsyncThunk(
  "/bets/revealScoresBoard",
  async ({ id }: RevealScoresBoardPayload, { rejectWithValue }) => {
    try {
      const headers = await getAuthHeader();
      const payload = serializeSingleResource({
        type: "users",
        attributes: { revealed_game_id: id },
      });

      await apiClient.patch<SingleResourcePayloadSerialized<GameAttributes>>(
        ENDPOINTS.updateRevealedGames,
        payload,
        { headers }
      );

      return { id };
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

const liveGamesUpdateReceived =
  ({
    games,
    liveUpdateState,
  }: {
    games: Camelized<GameLiveUpdate>[];
    liveUpdateState: number;
  }): AppThunk =>
  (dispatch, getState) => {
    games.map((gameUpdate) => {
      const state = getState();
      const { games } = state;
      const gameToUpdate = Object.values(games.byId).find(
        (game) => game.attributes?.gameKey === gameUpdate.gameKey
      );

      if (gameToUpdate && gameToUpdate?.relationships?.bets) {
        const { teamHome, teamAway } = selectGameTeams(state, gameToUpdate.id);

        dispatch(
          updateBetsProbabilityAndWinning({
            betsIds: gameToUpdate.relationships.bets,
            scoringScenariosMap: gameUpdate.scoringScenarios,
            teamHome: teamHome.teamName,
            teamAway: teamAway.teamName,
            liveUpdate: gameUpdate,
          })
        );

        dispatch(
          liveDetailsUpdated({
            gameToUpdateId: gameToUpdate.id,
            liveUpdate: gameUpdate,
            liveUpdateState: liveUpdateState,
          })
        );
      }
    });
  };

const gamesSlice = createSlice({
  name: "games",
  initialState: initialState,
  reducers: {
    liveDetailsUpdated: (
      state,
      {
        payload: { gameToUpdateId, liveUpdate, liveUpdateState },
      }: {
        payload: {
          gameToUpdateId: string;
          liveUpdate: GameLiveUpdate;
          liveUpdateState: number;
        };
      }
    ) => {
      state.byId[gameToUpdateId].attributes.homeScore = liveUpdate.homeScore;
      state.byId[gameToUpdateId].attributes.awayScore = liveUpdate.awayScore;
      state.byId[gameToUpdateId].attributes.timeLeft = liveUpdate.timeLeft;
      state.byId[gameToUpdateId].attributes.quarter = liveUpdate.quarter;

      GAME_PARTS.forEach((quarter) => {
        const gamePartKey =
          quarter === "4" || quarter === "OT"
            ? "finalQuarter"
            : `quarter${quarter}`;
        {
          state.byId[gameToUpdateId].attributes[`${gamePartKey}HomeScore`] =
            liveUpdate[`${gamePartKey}HomeScore`];
          state.byId[gameToUpdateId].attributes[`${gamePartKey}AwayScore`] =
            liveUpdate[`${gamePartKey}AwayScore`];
        }
      });
      state.liveUpdateStatus = liveUpdateState === 1 && StatusEnum.Fulfilled;
    },
    liveUpdateState: (
      state,
      {
        payload: { liveUpdateState },
      }: {
        payload: {
          liveUpdateState: number;
        };
      }
    ) => {
      state.liveUpdateStatus = liveUpdateState === 0 && StatusEnum.Pending;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCurrentWeekGames.pending, (state) => {
      state.fetchCurrentWeekGamesStatus = StatusEnum.Pending;
    });
    builder.addCase(fetchCurrentWeekGames.fulfilled, (state, { payload }) => {
      state.fetchCurrentWeekGamesStatus = StatusEnum.Fulfilled;
      const games = payload.entities.games as {
        [id: string]: DeserializedJsonApiResource<GameAttributes>;
      };

      state.byId = games;
      state.allIds = payload.order;
      state.currentWeek = payload.meta?.currentWeek;
      state.currentSeasonPhase = payload.meta?.currentSeasonPhase;
    });

    builder.addCase(fetchCurrentSeasonGames.pending, (state) => {
      state.fetchCurrentSeasonGamesStatus = StatusEnum.Pending;
    });
    builder.addCase(fetchCurrentSeasonGames.fulfilled, (state, { payload }) => {
      state.fetchCurrentSeasonGamesStatus = StatusEnum.Fulfilled;
      const games = payload.entities.games as {
        [id: string]: DeserializedJsonApiResource<GameAttributes>;
      };

      state.byId = games;
      state.allIds = payload.order;
    });
    builder.addCase(placeBet.fulfilled, (state, { payload }) => {
      const newBets = Object.entries(
        payload.entities.bets as {
          [id: string]: DeserializedJsonApiResource<BetAttributes>;
        }
      );

      newBets.forEach((bet) => {
        const [betId, betData] = bet;
        const gameId = betData.relationships?.game[0];

        if (gameId) {
          const bets = state.byId[gameId].relationships?.bets || [];
          bets.push(betId);

          state.byId[gameId].relationships = {
            ...state.byId[gameId].relationships,
            bets,
          };
        }
      });
    });
    builder.addCase(revealScoresBoard.fulfilled, (state, { payload }) => {
      state.byId[payload.id].attributes.scoresRevealedByCurrentUser = true;
    });
    builder.addCase(fetchCurrentWeekGames.rejected, (state, action) => {
      state.fetchCurrentWeekGamesStatus = StatusEnum.Rejected;
      state.errorMessage = action.error.message;
    });
  },
});

const selectAllGames = (
  state: RootState
): ResourceDeserialized<GameAttributes>[] => {
  return state.games.allIds.map((gameId) => state.games.byId[gameId]);
};

const selectGameTeams = (state: RootState, gameId: string): GameTeams => {
  const teamHomeId = state.games.byId[gameId].relationships?.teamHome[0];
  const teamAwayId = state.games.byId[gameId].relationships?.teamAway[0];

  const teamHome = teamHomeId && state.teams.byId[teamHomeId];
  const teamAway = teamAwayId && state.teams.byId[teamAwayId];

  return {
    teamHome: teamHome?.attributes,
    teamAway: teamAway?.attributes,
  };
};

const selectGameDetails = (state: RootState, gameId: string): GameDetails => {
  const game = state.games.byId[gameId];

  return serializeGameDetails(game, state);
};

const selectIsCurrentWeekGamesFetchingPending = (state: RootState): boolean =>
  state.games.fetchCurrentWeekGamesStatus === StatusEnum.Pending;

const selectCurrentWeek = (state: RootState): number => state.games.currentWeek;

const selectIsCurrentWeekGamesFetchingFulfilled = (state: RootState): boolean =>
  state.games.fetchCurrentWeekGamesStatus === StatusEnum.Fulfilled;

const selectCurrentSeasonPhase = (state: RootState): SeasonPhase =>
  state.games.currentSeasonPhase;

const selectIsLiveGameUpdatePending = (state: RootState): boolean =>
  state.games.liveUpdateStatus === StatusEnum.Pending;

const selectIsCurrentWeekGamesFetchingRejected = (state: RootState): boolean =>
  state.games.fetchCurrentWeekGamesStatus === StatusEnum.Rejected;

const selectIsCurrentSeasonGamesFetchingPending = (state: RootState): boolean =>
  state.games.fetchCurrentSeasonGamesStatus === StatusEnum.Pending;

const selectIsCurrentSeasonGamesFetchingFulfilled = (
  state: RootState
): boolean =>
  state.games.fetchCurrentSeasonGamesStatus === StatusEnum.Fulfilled;

const { liveDetailsUpdated, liveUpdateState } = gamesSlice.actions;
export const gamesReducer = gamesSlice.reducer;
export {
  liveGamesUpdateReceived,
  fetchCurrentWeekGames,
  fetchCurrentSeasonGames,
  revealScoresBoard,
  selectAllGames,
  selectGameTeams,
  selectGameDetails,
  selectIsCurrentWeekGamesFetchingPending,
  selectCurrentWeek,
  selectCurrentSeasonPhase,
  selectIsLiveGameUpdatePending,
  liveUpdateState,
  selectIsCurrentWeekGamesFetchingFulfilled,
  selectIsCurrentWeekGamesFetchingRejected,
  selectIsCurrentSeasonGamesFetchingPending,
  selectIsCurrentSeasonGamesFetchingFulfilled,
};
