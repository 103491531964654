import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { changePassword } from "../../api/auth0Client";
import chevron from "../../assets/icons/chevron-right-grey.svg";
import home from "../../assets/icons/home.svg";
import stadium from "../../assets/images/stadium.png";
import { spacings } from "../../assets/styles/sizes";
import { Avatar } from "../../components/atoms/Avatar";
import { TransparentButton } from "../../components/atoms/Button/Button.style";
import { Icon } from "../../components/atoms/Icon";
import {
  FULL_WIDTH_BOTTOM_FULL_HEIGHT,
  Modal,
} from "../../components/molecules/Modal";
import { Footer } from "../../components/molecules/Modal/Modal.style";
import { PersonalDataModal } from "../../components/organisms/PersonalDataModal";
import { ROUTES } from "../../routes";
import { useAppDispatch } from "../../store";
import { triggerNotification } from "../../store/notificationSlice";
import { selectUserName } from "../../store/userSlice";
import { NotificationType } from "../../types/notification";
import * as S from "./MyProfile.style";

const MyProfile = () => {
  const { t } = useTranslation("myProfile");
  const { user } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [modalVisible, setModalVisible] = useState(false);

  const firstName = useSelector(selectUserName);

  const isUserAuthenticatedWithPassword = user.sub.startsWith("auth0");
  const userName =
    firstName || (isUserAuthenticatedWithPassword ? "" : user.name);

  const handleResetPassword = async () => {
    try {
      await changePassword(user.email);
      dispatch(
        triggerNotification({
          type: NotificationType.SUCCESS,
          message: t("passwordResetSuccess"),
        })
      );
    } catch (error) {
      console.error("Error sending password reset email:", error);
      dispatch(
        triggerNotification({
          type: NotificationType.ERROR,
          message: t("passwordResetFailure"),
        })
      );
    }
  };

  return (
    <>
      <S.PersonalInformationWrapper>
        <S.HeaderWrapper>
          <TransparentButton onClick={() => navigate(ROUTES.games)}>
            <Icon src={home} />
          </TransparentButton>
          <Icon src={chevron} $marginLeft={spacings.s} />
          <S.HeaderLabel> {t("myProfile")}</S.HeaderLabel>
        </S.HeaderWrapper>
        <S.ImageWrapper>
          <S.BackgroundImage src={stadium} />
          <S.AvatarWrapper>
            {user.family_name && user.picture ? (
              <S.PersonalImage src={user.picture} />
            ) : (
              <Avatar size="l" user={user} />
            )}
          </S.AvatarWrapper>
        </S.ImageWrapper>
        <S.Content>
          <div>
            <S.Label>{t("name")}</S.Label>
            <S.Name>{firstName}</S.Name>
          </div>
          <S.Label>{t("email")}</S.Label>
          <S.Email>{user.email}</S.Email>
        </S.Content>
        <S.Divider />
        <S.AccountSettingsWrapper>
          <S.Label>{t("updateAccountHeader")}</S.Label>
          <S.AccountSettingsButton
            onClick={() => setModalVisible(true)}
            id="change-username-button"
          >
            {t("changeName")}
          </S.AccountSettingsButton>
          {isUserAuthenticatedWithPassword && (
            <S.AccountSettingsButton
              onClick={handleResetPassword}
              id="change-password-button"
            >
              {t("changePassword")}
            </S.AccountSettingsButton>
          )}
        </S.AccountSettingsWrapper>
        <Footer>
          <S.Button
            onClick={() => navigate(ROUTES.games)}
            id="go-to-games-button"
          >
            {t("games:allGames")}
          </S.Button>
          <S.Button
            onClick={() => navigate(ROUTES.transactions)}
            id="go-to-transactions-button"
          >
            {t("transactions:transactionsHistory")}
          </S.Button>
        </Footer>
      </S.PersonalInformationWrapper>
      <Modal
        contentPlacing={FULL_WIDTH_BOTTOM_FULL_HEIGHT}
        modalVisible={modalVisible}
        onModalClose={() => setModalVisible(false)}
      >
        <PersonalDataModal
          onModalClose={() => setModalVisible(false)}
          userName={userName}
        />
      </Modal>
    </>
  );
};

export { MyProfile };
