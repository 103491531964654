import styled from "styled-components";

import { spacings } from "../../../assets/styles/sizes";
import * as palette from "../../../assets/styles/colors";

const WalletFunds = styled.div<{ dark: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${spacings.xs} 2.2rem;
  border-top: 1px solid ${(p) => (p.dark ? palette.grey800 : palette.grey500)};
  border-bottom: 1px solid ${palette.grey500};
  background-color: ${(p) => p.dark && palette.grey800};
  color: ${(p) => p.dark && palette.white};
`;

const WalletLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const WalletLabel = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  padding: 0 ${spacings.s};
`;

export { WalletFunds, WalletLabelWrapper, WalletLabel };
