import { z } from "zod";

export const phoneFormSchema = z.object({
  firstName: z
    .string()
    .min(1, "nameFormErrors:nameErrorRequired")
    .max(50, "nameFormErrors:nameErrorMaxLength"),
  phone: z
    .string()
    .min(1, "phoneErrorRequired")
    .length(12, "phoneErrorTooShort"),
});
