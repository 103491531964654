import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  spacings,
} from "../../../assets/styles/sizes";
import { Icon } from "../../atoms/Icon";
import { H3Decor, H4, H4Decor, H5, H5Decor } from "../../atoms/Typography";

const SquareInfoWrapper = styled.div<{
  expandable: boolean;
  isPotentialPayout?: boolean;
}>`
  min-height: 66px;
  background-color: ${palette.white};
  display: grid;
  grid-template-columns: ${(p) =>
    p.isPotentialPayout ? "repeat(8, 1fr)" : "repeat(3, 1fr)"};
  border: 1px solid ${palette.grey400};
  border-top-left-radius: ${borderRadiuses.sm};
  border-top-right-radius: ${borderRadiuses.sm};

  border-bottom: ${(p) =>
    p.expandable ? "none" : "1px solid ${palette.grey400}"};
  border-bottom-left-radius: ${(p) =>
    p.expandable ? "none" : borderRadiuses.sm};
  border-bottom-right-radius: ${(p) =>
    p.expandable ? "none" : borderRadiuses.sm};

  &:not(:first-child) {
    margin-top: ${spacings.xxs};
  }
`;

const SquareSection = styled.div<{ small?: boolean; span?: number }>`
  display: grid;
  grid-column: ${(p) => `span ${p.span}` || "span 1"};
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
  padding: ${(p) => (p.small ? spacings.xxs : spacings.s)};
  border-right: 1px solid ${palette.grey400};
  justify-content: center;
  align-items: center;

  &:nth-child(4) {
    border-right: none;
  }
`;

const SquareLabel = styled(H5)`
  color: ${palette.grey700};
  text-transform: uppercase;
  font-weight: 400;
  margin: ${spacings.xxxs};
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 0;
  grid-row-end: 1;
`;

const SingleScoreWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0, 1fr);
  grid-row-start: 1;
  grid-row-end: 3;
`;

const SingleScoreWrapperHome = styled(SingleScoreWrapper)`
  grid-column-start: 2;
  grid-column-end: 3;
`;

const SingleScoreWrapperAway = styled(SingleScoreWrapper)`
  grid-column-start: 4;
  grid-column-end: 5;
`;

const ScoreDash = styled(H3Decor)`
  grid-column-start: 3;
  grid-column-end: 4;
`;

const SquareValue = styled(H3Decor)`
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 3;
  min-height: 30px;
`;

const ProbabilityIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${spacings.xs};
  grid-column-start: 1;
  grid-column-end: 6;
  align-items: center;
`;

const ProbabilityIcon = styled(Icon)`
  position: relative;
`;

const ProbabiltyLabel = styled.span<{
  probabilityLevel: "low" | "medium" | "high";
}>`
  color: ${(p) =>
    (p.probabilityLevel === "low" && palette.blue400) ||
    (p.probabilityLevel === "medium" && palette.black) ||
    (p.probabilityLevel === "high" && palette.red)};
  text-transform: uppercase;
  font-size: ${fontSizes.s};
  position: relative;
  top: ${(p) => (p.probabilityLevel === "medium" ? "2px" : "-5px")};
`;

const WinningsSummary = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PriceBadge = styled.div`
  text-align: center;
  padding: ${spacings.xxxs} ${spacings.l};
  background: ${palette.gradientYellow};
  border-radius: ${borderRadiuses.m};
`;

const PriceBadgeAmount = styled(H4Decor)`
  color: ${palette.white};
`;

const PriceBadgeQuarter = styled(H5Decor)`
  color: ${palette.white};
  text-transform: capitalize;
`;

const WinningsText = styled(H4)`
  text-transform: uppercase;
  max-width: 40%;
`;

export {
  SquareInfoWrapper,
  SquareSection,
  SquareLabel,
  SingleScoreWrapper,
  SingleScoreWrapperHome,
  SingleScoreWrapperAway,
  ScoreDash,
  SquareValue,
  ProbabilityIcon,
  ProbabiltyLabel,
  ProbabilityIconWrapper,
  WinningsSummary,
  PriceBadge,
  PriceBadgeAmount,
  PriceBadgeQuarter,
  WinningsText,
};
