import * as S from "./IconColor.style";

interface IconColorProps {
  src: string;
  color: string;
  $size?: string;
  $width?: string;
  $height?: string;
  $marginLeft?: string;
  $marginRight?: string;
}

const IconColor = ({ ...rest }: IconColorProps) => {
  return <S.Icon {...rest} />;
};

export { IconColor };
export type { IconColorProps };
