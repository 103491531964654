import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../store";
import { selectGamesWeekdaysUnique } from "../../../store/gameFiltersSlice";
import {
  applyWeekDayFilter,
  selectWeekdDayFilter,
} from "../../../store/gameFiltersSlice";
import { ALL_DAYS } from "../../../types/filters";
import { DayFilterTab } from "../../atoms/DayFIlterTab";
import * as S from "./DayFilter.styles";

const DayFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("general");

  const weekDayFilterOptions = useSelector(selectGamesWeekdaysUnique);
  const weekDayFilterValue = useSelector(selectWeekdDayFilter);

  const handleSelectWeekDayFilter = (value: string) => {
    dispatch(applyWeekDayFilter(value));
  };

  const dateDisplayed = (weekDay: string) => {
    return (
      weekDay !== ALL_DAYS &&
      weekDay !== "today" &&
      weekDay !== "tomorrow" &&
      weekDay !== "yesterday"
    );
  };

  return (
    <S.DayFilterWrapper>
      {weekDayFilterOptions.map((day, id) => {
        return (
          <DayFilterTab
            key={id}
            weekDay={dateDisplayed(day.weekDay) ? day.weekDay : t(day.weekDay)}
            date={dateDisplayed(day.weekDay) ? day.date : ""}
            onChange={() => handleSelectWeekDayFilter(day.date)}
            checked={weekDayFilterValue === day.date}
            onClick={() => handleSelectWeekDayFilter(day.date)}
          />
        );
      })}
    </S.DayFilterWrapper>
  );
};

export { DayFilter };
