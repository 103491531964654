import styled from "styled-components";

import { grey800, white } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import {
  TransparentButton,
  buttonGeneralStyles,
} from "../../atoms/Button/Button.style";
import { H2 } from "../../atoms/Typography";

const ModalWrapper = styled.div`
  height: fit-content;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${white};
  z-index: 3;
`;

const HeadingWrapper = styled.div`
  background-color: ${grey800};
  padding: ${spacings.xxs} ${spacings.xxs} ${spacings.xxs} ${spacings.s};
`;

const ButtonWrapper = styled(TransparentButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: none;
  width: 100%;
`;

const HeadingText = styled(H2)`
  color: ${white};
  font-weight: 400;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const ModalButton = styled.button`
  ${buttonGeneralStyles}
  padding: 0 ${spacings.xl};
  height: 100%;
  justify-self: end;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 50px;
  padding: ${spacings.xxs} ${spacings.xxs} ${spacings.xxs} ${spacings.s};
`;

export {
  ModalWrapper,
  HeadingWrapper,
  ButtonWrapper,
  HeadingText,
  ModalButton,
  ModalFooter,
};
