import styled, { css } from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { borderRadiuses, spacings } from "../../../assets/styles/sizes";
import { buttonGeneralStyles } from "../../atoms/Button/Button.style";
import { H2Decor, H4, H4Decor } from "../../atoms/Typography";

const SquareWrapper = styled.div<{ collapsed: boolean }>`
  width: 100%;
  margin-bottom: ${spacings.l};
  box-shadow: ${(props) =>
    props.collapsed ? "none" : "0px 1px 2px 1px rgba(224, 224, 224, 0.7)"};
`;

const ExpandableBar = styled.button<{ collapsed: boolean }>`
  ${buttonGeneralStyles}
  width: 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: ${spacings.xxs};
  background-color: ${(props) =>
    props.collapsed ? palette.gold : palette.grey400};
  border-top: none;
  transition: all 0.3s ease-out;
`;

const QuartersDetails = styled.div<{ collapsed: boolean }>`
  border-bottom: none;
  ${(props) =>
    props.collapsed
      ? css`
           {
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            border: none;
          }
        `
      : css`
           {
            max-height: 100%;
            opacity: 1;
            border: 1px solid ${palette.grey400};
            transition: all 0.3s ease-in-out;
          }
        `}
`;

const QuartersTable = styled.div<{ collapsed: boolean }>`
  display: grid;
  align-items: center;
  transition: all 0.2s ease-out;
  margin-top: ${spacings.s};
`;

const QuartersTableRow = styled.div<{
  isCurrentQuarter?: boolean;
}>`
  color: ${palette.grey900};
  background-color: ${(props) => props.isCurrentQuarter && palette.green};
  text-transform: uppercase;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: ${spacings.xxs};

  &:nth-child(even) {
    background-color: ${(props) =>
      props.isCurrentQuarter ? palette.green : palette.grey200};
  }
`;

const QuartersTableHeading = styled(H4)<{ $align?: "end" | "center" }>`
  text-transform: uppercase;
  color: ${palette.black};
  text-align: ${(props) => props.$align};
`;

const QuartersTableCell = styled(H4Decor)<{
  $align?: "start";
  isWinningIcon?: boolean;
  liveIconUrl?: string;
}>`
  text-align: ${(props) => props.$align || "end"};
  padding-right: ${(props) => (props.isWinningIcon ? spacings.l : spacings.xs)};
  padding-left: ${(props) => (props.$align ? spacings.l : 0)};
  position: relative;

  &:before {
    content: "";
    background: url(${(p) => p.liveIconUrl}) no-repeat center center/cover;
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    animation: pulse 2s infinite;
    display: ${(p) => (p.liveIconUrl ? "block" : "none")};

    @keyframes pulse {
      0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      }

      70% {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
  }
`;

const ScoringScenarioWrapper = styled.div`
  background-color: ${palette.green};
  padding: ${spacings.m};
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const IconWrapper = styled.div<{
  iconUrl?: string;
}>`
  width: 18px;
  height: 18px;
  background-color: ${palette.goldDark};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  display: ${(p) => (p.iconUrl ? "block" : "none")};

  &:before {
    content: "";
    background: url(${(p) => p.iconUrl}) no-repeat center center/cover;
    width: 10px;
    height: 8px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

export {
  SquareWrapper,
  ExpandableBar,
  QuartersDetails,
  QuartersTable,
  QuartersTableRow,
  QuartersTableCell,
  QuartersTableHeading,
  ScoringScenarioWrapper,
  SpinnerWrapper,
  IconWrapper,
};
