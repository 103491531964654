import styled from "styled-components";

import { greyDark02, white } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H4 } from "../Typography";

const Banner = styled.div`
  padding: ${spacings.xxs} 0;
  background-color: ${greyDark02};
`;

const BannerText = styled(H4)`
  text-align: center;
  text-transform: none;
  color: ${white};
`;
export { Banner, BannerText };
