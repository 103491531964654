export const en = {
  buySquare: {
    myWallet: "my wallet",
    available: "available funds",
    wager: "my wager amount",
    warningTitle: "Warning: Your bet exceeds the current Max Bet amount",
    warningText1:
      "However, there is still time before the pool closes and your full bet amount may still qualify. In the event that your bet exceeds the Max Bet Amount, your bet will be adjusted and the difference will be returned to your wallet.",
    warningText2:
      "left before the pool closes and your full bet amount may still qualify. In the event that your bet exceeds the Max Bet Amount, your bet will be adjusted and the difference will be returned to your wallet.",
    proceed: "proceed",
    buySquareSummary: "buy square summary",
    myWager: "my wager",
    myPoolValue: "my pool value",
    loadingData: "loading data...",
    buySquaresForGame: "buy squares for this game",
    buySquareNow: "buy square now",
    addToCart: "add to cart",
    purchase: "purchase",
    modalHeadingSingleBet: "in cart: {{cartSquareCount}} square",
    modalHeadingMultipleBet: "in cart: {{cartSquareCount}} squares",
    continue: "continue",
    transactionFormLabel: "buy squares for selected games for:",
    payButtonTotal: "pay {{totalAmount}} total",
    buySquares: "buy squares",
    step1Heading: "step 1",
    step2Heading: "step 2",
    step1Name: "wager",
    step2Name: "summary",
    squaresDetails: "squares details:",
    wagerPerSquare: "wager amount per square",
    totalSquaresNumber: "total number of squares",
  },
  games: {
    title: "games",
    allGames: "all games",
    myGames: "my games",
    filterWeekHeading: "choose game season",
    offseason: "offseason",
    season: "{{seasonPhase}} season",
    week: "week {{weekNumber}}",
    apply: "apply",
    loadingGames: "loading games...",
    gamesFetchingRejectedTitle: "Oops! We can't fetch the games",
    gamesFetchingRejectedText:
      "We're sorry, but we're currently experiencing some technical difficulties fetching games. Our team is working hard to fix the issue. Please check back later.",
    noGamesMessage: "No games scheduled for the current week.",
    noGamesSubmessage: "Please select another week or season phase.",
  },
  game: {
    status: "status",
    totalPoolLabel: "total pool value",
    maxBet: "max bet value",
    viewGame: "view game",
    pre_game: "pre-game",
    pre_draw: "pre-draw",
    open_for_bets: "bets open",
    quarter: "quarter",
    potentialPayout: "potential payout",
    probability: "probability",
    square: "square",
    mySquares: "my squares",
    squareValue: "square value",
    totalWin: "total winnings from this square",
    yourTotalWin: "your total winnings",
    waitForDraw: "waiting for draw",
    reveal: "reveal square",
    payoutLabel: "payout if:",
    scoringScenarioTemporaryLabel:
      "{{teamHome}} need to kick a field goal and a get a safety and {{teamAway}} need to kick a field goal.",
    winnings: "winnings",
    boardDrawing: "view board drawing",
    low: "low",
    medium: "medium",
    high: "high",
    q: "q{{quarter}}",
    final: "final",
    prizes: "prizes",
    myNumbers: "my numbers",
    myPool: "my pool",
    myWinnings: "my winnings",
    maxPayout: "max payout",
    totalWinnings: "total winnings",
    totalInvestment: "total investment",
    netLoss: "net loss",
    netGain: "net gain",
  },
  login: { title: "log in" },
  noMatch: {
    heading: "ooops!",
    subheading: "page not found",
    button: "go to games",
  },
  general: {
    closeModal: "close modal",
    register: "register",
    signIn: "or sign in",
    logout: "logout",
    more: "show more",
    less: "show less",
    close: "close",
    cancel: "cancel",
    allGames: "all games",
    myBets: "my bets",
    fee: "3% fee",
    processing: "processing",
    minValue5: "(min $5)",
    "formAmountError-multiplicationOfFive":
      "Amount must be a multiplication of $5.",
    "formAmountError-fundsAvailable":
      "Too little funds in the wallet. Decrease the amount to continue.",
    "formAmountError-min": "Amount must be at least $5.",
    "formAmountError-required": "Enter amount.",
    wholeWeek: "whole week",
    yesterday: "yesterday",
    today: "today",
    tomorrow: "tomorrow",
    amount: "amount",
    loading: "loading...",
    reset: "reset",
    goBack: "go back",
    save: "save",
    game: "game",
    games: "games",
  },
  notification: {
    // API based notification codes, hence snake_case
    insufficient_funds: "Insufficient funds for this operation.",
    placing_bet_success: "Wager accepted!",
    payment_success: "Your wallet has been topped off successfully!",
    paymentNotCreated: "Transaction iterrupted. Payment not created.",
    authenticationError:
      "You have been logged out due to inactivity, please log back in.",
    changeNameSuccess: "Name has been changed!",
    changeNameError: "Something went wrong, please try again.",
  },
  wallet: {
    myWallet: "my wallet",
    addFunds: "Add funds",
    amount: "amount",
    paymentMethod: "payment method",
    creditCard: "credit card",
    transactionFee: "transaction fee",
    totalTransactionAmount: "total transaction amount",
    proceedToPayment: "Proceed to payment",
    paymentProcessInfo:
      "In the next step you will see PaySafe iframe. Please fill in your debit/credit card details in the PaySafe checkout form.",
    topOffWallet: "top-off wallet",
    paymentSummary: "payment summary",
  },
  transactions: {
    transactionsHistory: "transactions history",
    transactionType: "transaction type",
    buy_bet: "placed wager",
    top_up: "top-off",
    refund_overbet: "Overbet refund",
    full_refund: "Full refund",
    winning_payout: "bet won",
  },
  drawing: { title: "board drawing", wager: "wager", reveal: "reveal all" },
  boardLegend: {
    q1: "Q1",
    q2: "Q2",
    q3: "Q3",
    final: "Final",
  },
  bucketBettersTable: {
    firstName: "first name",
    betAmount: "bet",
    totalWinnings: "won",
    squareBets: "square bets",
    showBoardButton: "back to the board",
    total: "total",
    noBettersDrawn:
      "bet drawing in progress. Please come back to the game in a few minutes to see square bets.",
  },
  timer: {
    timeLeftInQuarter: "Q{{quarter}} ends in {{timeLeft}}",
    halftime: "halftime",
    timeLeftInOvertime: "overtime ends in {{timeLeft}}",
    live: "live now",
    scheduled: "game opens in",
    pre_game: "game starts in",
    pre_draw: "drawing starts in",
    open_for_bets: "betting closes in",
    statusChanged: "status changed!",
    refresh: "refresh games",
  },
  carousel: {
    inviteFriendsHeader: "share the app with your friends",
    inviteFriendButton: "invite friends",
    surveyHeader:
      "Hey MVP! 🏈 Let's huddle up - we'd love to hear your thoughts on your SquareShare experience!",
    surveyButton: "take survey",
    phoneFormHeader: "Get instant updates on your phone",
  },
  banner: {
    bannerText: "Note: This page doesn't use real money.",
  },
  invitationModal: {
    heading: "Share this message with your friends",
    invitationModalText:
      "Hey, football fan! 🏈 Dive into SquareShare, the cool app for buying football squares. Join our beta team - it's a touchdown in the making! Sign up, load your wallet with this test card: 4037 1122 3300 0001, Exp 01/2029, CCV 123. Then, grab some squares and have a blast! 🎉 Share your game-day thoughts with us. Happy testing! Visit https://bta.squareshare.app/ on your mobile.",

    copyToClipboard: "copy to clipboard",
    copied: "copied!",
  },
  phoneModal: {
    heading: "Stay in the Loop! 🚀",
    modalText:
      "Want real-time updates straight to your phone? Share your name and number, and we'll text you the latest app news and updates on your square's status.",
    firstName: "first name:",
    namePlaceholder: "First name",
    providePhone: "phone number:",
    phonePlaceholder: "Phone number",
    subscribe: "subscribe",
    subscribed: "subscribed",
    phoneErrorRequired: "Phone number is required",
    phoneErrorTooShort: "Phone number is too short",
  },
  myProfile: {
    myProfile: "my profile",
    name: "name",
    email: "email",
    updateAccountHeader: "update account settings",
    changePassword: "change password",
    passwordResetSuccess:
      "We have sent you an email with instructions on how to change your password. Please check your inbox and follow the provided steps.",
    passwordResetError:
      "Something went wrong while trying to send the password change email. Please double-check your email address and try again. If the problem persists, please contact our support team for assistance.",
    changeName: "change name",
    newName: "new name",
  },
  nameFormErrors: {
    nameErrorRequired: "First name is required",
    nameErrorMaxLength: "First name cannot exceed 50 characters",
  },
};
