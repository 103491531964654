import styled from "styled-components";

import { grey300 } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${grey300};
  padding: 0 ${spacings.xxs};
  margin-left: ${spacings.s};
  height: 24px;
  mix-blend-mode: luminosity;
`;

export { Wrapper };
