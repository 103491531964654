import { animated, useSpring } from "@react-spring/web";
import { useRef } from "react";

import * as S from "./Modal.style";

const FULL_HEIGHT_RIGHT = "fullHeightRight";
const RELATIVE_BELOW = "relativeBelow";
const FULL_WIDTH_BOTTOM_FULL_HEIGHT = "fullWidthBottomFullHeight";
const FULL_WIDTH_CENTER = "fullWidthCenter";
const FULL_WIDTH_BOTTOM = "fullWidthBottom";

type ContentPlacing =
  | typeof FULL_HEIGHT_RIGHT
  | typeof RELATIVE_BELOW
  | typeof FULL_WIDTH_BOTTOM_FULL_HEIGHT
  | typeof FULL_WIDTH_CENTER
  | typeof FULL_WIDTH_BOTTOM;

const Modal = (props: {
  modalVisible: boolean;
  onModalClose: () => void;
  children: JSX.Element;
  contentPlacing?: ContentPlacing;
  $transparentBackground?: boolean;
  revealHorizontal?: boolean;
}) => {
  const {
    modalVisible,
    onModalClose,
    children,
    contentPlacing = FULL_HEIGHT_RIGHT,
    $transparentBackground,
    revealHorizontal = false,
  } = props;
  const modalRef = useRef();

  const animationConfig = revealHorizontal
    ? {
        transform: modalVisible ? "translateX(0%)" : "translateX(100%)",
        alignSelf: "flex-start",
      }
    : contentPlacing === FULL_WIDTH_BOTTOM_FULL_HEIGHT ||
      contentPlacing === FULL_WIDTH_BOTTOM
    ? {
        transform: modalVisible ? `translateY(0%)` : `translateY(100%)`,
        alignSelf: "flex-end",
      }
    : {
        transform: modalVisible ? `translateY(0%)` : `translateY(-100%)`,
        alignSelf: "flex-start",
      };

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: modalVisible ? 1 : 0,
    height: "100%",
    ...animationConfig,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      onModalClose();
    }
  };

  return modalVisible ? (
    <S.Background onClick={closeModal} ref={modalRef} id="close-modal">
      <animated.div style={animation}>
        <S.ModalContent
          contentPlacing={contentPlacing}
          $transparentBackground={$transparentBackground}
        >
          {children}
        </S.ModalContent>
      </animated.div>
    </S.Background>
  ) : null;
};

export {
  Modal,
  FULL_HEIGHT_RIGHT,
  RELATIVE_BELOW,
  FULL_WIDTH_BOTTOM_FULL_HEIGHT,
  FULL_WIDTH_CENTER,
  FULL_WIDTH_BOTTOM,
};
export type { ContentPlacing };
