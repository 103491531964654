import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../store";
import {
  selectMyGamesFilterValue,
  setMyGamesFilter,
} from "../../../store/gameFiltersSlice";
import * as S from "./MyGamesTabBar.style";

const MyGamesTabBar = () => {
  const { t } = useTranslation("games");
  const dispatch = useAppDispatch();
  const isMyGamesSelected = useSelector(selectMyGamesFilterValue);

  return (
    <S.GameTabWrapper>
      <S.Tab
        onClick={() => dispatch(setMyGamesFilter(false))}
        active={!isMyGamesSelected}
        id="all-games-tab"
      >
        {t("allGames")}
      </S.Tab>
      <S.Tab
        onClick={() => dispatch(setMyGamesFilter(true))}
        active={isMyGamesSelected}
        id="my-games-tab"
      >
        {t("myGames")}
      </S.Tab>
    </S.GameTabWrapper>
  );
};

export { MyGamesTabBar };
