import { FlexWrapper } from "../../atoms/Containers";
import { QuarterScore } from "../../atoms/QuarterScore";
import * as S from "./QuarterScoresTable.style";

interface QuarterScoresProps {
  homeTeamColor: string;
  awayTeamColor: string;
  scoreHome: number;
  scoreAway: number;
  quarterLabel: string;
  id: string;
}

const QuarterScores = ({
  homeTeamColor,
  awayTeamColor,
  scoreHome,
  scoreAway,
  quarterLabel,
  id,
}: QuarterScoresProps) => {
  return (
    <S.ScoresWrapper id={id}>
      <S.Wrapper>
        <S.Label>{quarterLabel}</S.Label>
        <FlexWrapper>
          <QuarterScore background={homeTeamColor}>{scoreHome}</QuarterScore>
          <QuarterScore background={awayTeamColor}>{scoreAway}</QuarterScore>
        </FlexWrapper>
      </S.Wrapper>
    </S.ScoresWrapper>
  );
};

const QuarterScoresTable = ({
  homeTeamColor,
  awayTeamColor,
  quarter1HomeScore,
  quarter1AwayScore,
  quarter2HomeScore,
  quarter2AwayScore,
  quarter3HomeScore,
  quarter3AwayScore,
  finalQuarterHomeScore,
  finalQuarterAwayScore,
}) => {
  return (
    <S.ScoresWrapper>
      <QuarterScores
        homeTeamColor={homeTeamColor}
        awayTeamColor={awayTeamColor}
        scoreHome={quarter1HomeScore}
        scoreAway={quarter1AwayScore}
        quarterLabel="q1"
        id="quarter1-scores"
      />
      <QuarterScores
        homeTeamColor={homeTeamColor}
        awayTeamColor={awayTeamColor}
        scoreHome={quarter2HomeScore}
        scoreAway={quarter2AwayScore}
        quarterLabel="q2"
        id="quarter2-scores"
      />
      <QuarterScores
        homeTeamColor={homeTeamColor}
        awayTeamColor={awayTeamColor}
        scoreHome={quarter3HomeScore}
        scoreAway={quarter3AwayScore}
        quarterLabel="q3"
        id="quarter3-scores"
      />
      <QuarterScores
        homeTeamColor={homeTeamColor}
        awayTeamColor={awayTeamColor}
        scoreHome={finalQuarterHomeScore}
        scoreAway={finalQuarterAwayScore}
        quarterLabel="final"
        id="final-quarter-scores"
      />
    </S.ScoresWrapper>
  );
};

export { QuarterScoresTable };
