import styled from "styled-components";

import { H4Decor } from "../../atoms/Typography";
import { grey300, white } from "../../../assets/styles/colors";
import { borderRadiuses } from "../../../assets/styles/sizes";

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled(H4Decor)`
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
  background-color: ${grey300};
  padding: 0.5rem;
  border-radius: ${borderRadiuses.s};
`;

const ScoresWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export { Wrapper, Label, ScoresWrapper };
