import styled from "styled-components";

import {
  black,
  blue400,
  gold,
  grey500,
  grey600,
  red,
} from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { H4, H4Decor } from "../../atoms/Typography";

const PaddingWrapper = styled.div`
  padding: ${spacings.xs} ${spacings.m};
  margin-top: ${spacings.m};
  overflow: hidden;
`;

const FormWrapper = styled.div`
  display: grid;
  align-items: center;
  justify-content: space-between;
  position: relative;
  column-gap: ${spacings.l};
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: center;
`;

const InputLabel = styled.label`
  display: block;
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.sm};
  margin-bottom: ${spacings.xs};
  margin-top: ${spacings.s};
  text-align: center;

  &:first-letter {
    text-transform: uppercase;
  }
`;

const Input = styled.input`
  border-radius: ${borderRadiuses.sm};
  border: 1px solid ${blue400};
  padding: ${spacings.sm};
  font-family: "Space Grotesk";
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.m};
  font-weight: 400;
  text-align: center;
  width: 80%;
  align-self: center;

  &::placeholder {
    color: ${grey500};
  }
`;

const ControlButton = styled.button<{ disabled?: boolean }>`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: ${(p) => (p.disabled ? grey600 : gold)};
  opacity: ${(p) => (p.disabled ? 0.3 : 1)};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${black};
  position: relative;
`;

const ControlButtonIcon = styled.span`
  max-height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

const MinAmount = styled.span`
  display: block;
  text-align: center;
  font-size: ${fontSizes.sm};
  padding-top: ${spacings.xxs};
`;

const ResetButton = styled.button`
  position: absolute;
  bottom: -50px;
  right: 0;
  padding: ${spacings.s} ${spacings.m};
  border: none;
  border-radius: ${borderRadiuses.s};
  background-color: ${gold};
  text-transform: capitalize;
  color: ${black};
  font-size: ${fontSizes.sm};
`;

const TilesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${spacings.xs};
  grid-template-rows: 1fr 1fr;
  grid-row-gap: ${spacings.xxs};
  margin-top: ${spacings.l};
`;

const AmountPresetTile = styled.button`
  background-color: transparent;
  border-radius: ${borderRadiuses.sm};
  font-size: ${fontSizes.sm};
  color: ${black};
  border: 1px solid ${grey500};
  padding: ${spacings.s} ${spacings.xs};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ErrorMessage = styled(H4)`
  margin-top: ${spacings.xxs};
  align-self: flex-start;
  color: ${red};
  min-height: ${lineHeights.sm};
  text-align: center;
  text-transform: none;
`;

const CurrencyLabel = styled(H4Decor)`
  position: absolute;
  left: ${spacings.l};
  top: ${spacings.sm};
`;

export {
  PaddingWrapper,
  FormWrapper,
  InputLabel,
  Input,
  ControlButton,
  ControlButtonIcon,
  MinAmount,
  AmountPresetTile,
  ResetButton,
  TilesWrapper,
  InputWrapper,
  ErrorMessage,
  CurrencyLabel,
};
