import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../store";
import {
  applyStatusFilter,
  selectStatusFilter,
} from "../../../store/gameFiltersSlice";
import { gameStatusFilters } from "../../../types/filters";
import { StatusFilterTab } from "../../molecules/StatusFilterTab";
import * as S from "./StatusFilter.style";

const StatusFilter = () => {
  const dispatch = useAppDispatch();

  const statusFilterValue = useSelector(selectStatusFilter);

  const handleSelectStatusFilter = (value: string) => {
    dispatch(applyStatusFilter(value));
  };

  return (
    <S.StatusFilterWrapper>
      <S.StatusFilterTabsWrapper>
        {gameStatusFilters.map((status) => (
          <StatusFilterTab
            key={status}
            status={status}
            checked={statusFilterValue === status}
            onChange={() => handleSelectStatusFilter(status)}
          />
        ))}
      </S.StatusFilterTabsWrapper>
    </S.StatusFilterWrapper>
  );
};

export { StatusFilter };
