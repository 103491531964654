import { useTranslation } from "react-i18next";

import highProbabilityIcon from "../../../assets/icons/probability-high.svg";
import lowProbabilityIcon from "../../../assets/icons/probability-low.svg";
import mediumProbabilityIcon from "../../../assets/icons/probability-medium.svg";
import { GameTeams } from "../../../types/games";
import { H3Decor, H5 } from "../../atoms/Typography";
import * as S from "./RevealedSquare.style";

interface TeamsWithScoreLabel extends GameTeams {
  homeScore?: number;
  awayScore?: number;
}

export const TeamsWithScoreLabel = ({
  teamHome,
  teamAway,
  homeScore,
  awayScore,
}: TeamsWithScoreLabel) => {
  return (
    <>
      <S.SingleScoreWrapperHome>
        <H3Decor>{homeScore}</H3Decor>
        <H5>{teamHome.abbreviation}</H5>
      </S.SingleScoreWrapperHome>
      <S.ScoreDash>-</S.ScoreDash>
      <S.SingleScoreWrapperAway>
        <H3Decor>{awayScore}</H3Decor>
        <H5>{teamAway.abbreviation}</H5>
      </S.SingleScoreWrapperAway>
    </>
  );
};

const getProbabilityLevel = (
  scoringScenarioProbability: number
): "low" | "medium" | "high" => {
  if (scoringScenarioProbability <= 3) {
    return "low";
  } else if (
    scoringScenarioProbability > 3 &&
    scoringScenarioProbability <= 7
  ) {
    return "medium";
  } else if (scoringScenarioProbability > 7 && scoringScenarioProbability <= 9)
    return "high";
};

interface ProbabilityIconProps {
  scoringScenarioProbability: number;
}

const ProbabilityIcon = ({
  scoringScenarioProbability,
}: ProbabilityIconProps) => {
  const probabilityLevel = getProbabilityLevel(scoringScenarioProbability);
  const { t } = useTranslation("game");

  return (
    <S.ProbabilityIconWrapper>
      <S.ProbabilityIcon
        src={
          (probabilityLevel === "low" && lowProbabilityIcon) ||
          (probabilityLevel === "medium" && mediumProbabilityIcon) ||
          (probabilityLevel === "high" && highProbabilityIcon)
        }
      />
      <S.ProbabiltyLabel probabilityLevel={probabilityLevel}>
        {t(probabilityLevel)}
      </S.ProbabiltyLabel>
    </S.ProbabilityIconWrapper>
  );
};

interface RevealedSquareProps extends TeamsWithScoreLabel {
  amountFormatted: string;
  finishedGame?: boolean;
  potentialPayoutFormatted?: string;
  short?: boolean;
  expandable?: boolean;
  scoringScenarioProbability?: number;
  totalWinningsFormatted?: string;
  hasWinnings?: boolean;
  winningQuarters?: string[];
}

const RevealedSquare = ({
  teamAway,
  teamHome,
  homeScore,
  awayScore,
  finishedGame = false,
  totalWinningsFormatted,
  scoringScenarioProbability,
  amountFormatted,
  potentialPayoutFormatted,
  expandable,
  short,
  hasWinnings,
  winningQuarters,
}: RevealedSquareProps) => {
  const { t } = useTranslation("game");

  return (
    <S.SquareInfoWrapper
      expandable={expandable}
      isPotentialPayout={!!potentialPayoutFormatted}
    >
      <>
        <S.SquareSection small={short}>
          <S.SquareLabel>{t("square")}</S.SquareLabel>
          <TeamsWithScoreLabel
            teamHome={teamHome}
            teamAway={teamAway}
            homeScore={homeScore}
            awayScore={awayScore}
          />
        </S.SquareSection>
        {finishedGame ? (
          <S.SquareSection small={short}>
            <S.SquareLabel>{t("winnings")}</S.SquareLabel>
            {hasWinnings ? (
              <S.WinningsSummary>
                <S.PriceBadge>
                  <S.PriceBadgeAmount>
                    {totalWinningsFormatted}
                  </S.PriceBadgeAmount>
                  {winningQuarters &&
                    winningQuarters.length > 0 &&
                    winningQuarters.map((quarter) => {
                      return (
                        <S.PriceBadgeQuarter key={quarter}>
                          {quarter}
                        </S.PriceBadgeQuarter>
                      );
                    })}
                </S.PriceBadge>
              </S.WinningsSummary>
            ) : (
              <S.SquareValue>{totalWinningsFormatted}</S.SquareValue>
            )}
          </S.SquareSection>
        ) : (
          <S.SquareSection small={short} span={2}>
            <S.SquareLabel>{t("probability")}</S.SquareLabel>
            <ProbabilityIcon
              scoringScenarioProbability={scoringScenarioProbability}
            />
          </S.SquareSection>
        )}
        <S.SquareSection small={short} span={finishedGame ? 1 : 2}>
          <S.SquareLabel>{t("squareValue")}</S.SquareLabel>
          <S.SquareValue>{amountFormatted}</S.SquareValue>
        </S.SquareSection>
        {potentialPayoutFormatted && (
          <S.SquareSection small={short} span={3}>
            <S.SquareLabel>{t("potentialPayout")}</S.SquareLabel>
            <S.SquareValue>{potentialPayoutFormatted}</S.SquareValue>
          </S.SquareSection>
        )}
      </>
    </S.SquareInfoWrapper>
  );
};

export { RevealedSquare };
