import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  spacings,
} from "../../../assets/styles/sizes";
import { H2, H3Decor, H4, H5 } from "../Typography";

const TeamTileWrapper = styled.div<{
  $bg: string;
  $size?: "large" | "medium" | "small";
}>`
  height: ${(props) =>
    props.$size === "large"
      ? "190px"
      : props.$size === "medium"
      ? "130px"
      : props.$size === "small" && "60px"};
  width: 50%;
  background-color: ${(props) => props.$bg};
  border-radius: ${borderRadiuses.sm};
  &:first-child {
    margin-right: ${spacings.xs};
  }
`;

const TeamGradientRadial = styled.div<{
  $size?: "large" | "medium" | "small";
  $scoresDisplayed?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.$scoresDisplayed ? "flex-start" : "center"};
  width: ${(props) => (props.$scoresDisplayed ? "auto" : "100%")};
  height: ${(props) =>
    props.$size === "large"
      ? "65%"
      : props.$size === "medium"
      ? "55%"
      : props.$size === "small" && "40%"};
  background: radial-gradient(
    circle,
    ${palette.greyLight07} 1px,
    transparent
      ${(props) =>
        props.$size === "large"
          ? "65px"
          : props.$size === "medium"
          ? "35px"
          : props.$size === "small" && "30px"}
  );
`;

const TeamGradientLinear = styled.div<{
  $scoresDisplayed?: boolean;
  $secondTile?: boolean;
}>`
  display: flex;
  align-items: ${(props) =>
    props.$scoresDisplayed
      ? props.$secondTile
        ? "flex-end"
        : "flex-start"
      : "center"};
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: ${(props) => (props.$scoresDisplayed ? `0 ${spacings.l}` : 0)};
  background: repeating-linear-gradient(
    to right,
    ${palette.greyLight05} 0 10px,
    transparent 10px 17px
  );
`;

const TeamLocation = styled(H4)`
  color: ${palette.grey500};
`;

const TeamName = styled(H3Decor)`
  color: ${palette.white};
  text-transform: capitalize;
`;

// TeamLabel
const TeamLabelWrapper = styled.div<{
  abbreviated?: boolean;
  isLiveGame?: boolean;
}>`
  grid-column-start: ${(p) => (p.isLiveGame ? "6" : "4")};
  grid-column-end: ${(p) => (p.isLiveGame ? "9" : "7")};
  display: flex;
  flex-direction: ${(p) => (p.abbreviated ? "column" : "row")};

  &:first-of-type {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  &:nth-of-type(3) {
    justify-content: ${(p) => p.isLiveGame && "flex-end"};
  }
`;

const TeamLabelLogo = styled.img<{ $bg: string }>`
  width: 2.8rem;
  height: 2.8rem;
  padding: ${spacings.xxxs};
  border-radius: 50%;
  border: 1px solid ${palette.grey400};
  background-color: ${(props) => props.$bg};
`;

const TeamLabelLocation = styled.span<{ bigFont?: boolean }>`
  text-transform: uppercase;
  font-size: ${(p) => p.bigFont && fontSizes.m};
`;

const TeamLabelName = styled(H2)`
  font-weight: 400;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${spacings.xs};
`;

const AbbreviatedLabel = styled(H5)`
  text-transform: uppercase;
  text-align: center;
  margin-top: ${spacings.xxs};
`;

export {
  TeamTileWrapper,
  TeamGradientLinear,
  TeamGradientRadial,
  TeamLocation,
  TeamName,
  TeamLabelWrapper,
  TeamLabelLogo,
  TeamLabelName,
  ColumnWrapper,
  TeamLabelLocation,
  AbbreviatedLabel,
};
