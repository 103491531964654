import styled, { FlattenSimpleInterpolation, css } from "styled-components";

import closeIcon from "../../../assets/icons/close.svg";
import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  elementSizes,
  spacings,
} from "../../../assets/styles/sizes";
import { H4Decor } from "../../atoms/Typography";
import { ContentPlacing } from "./Modal";

const Background = styled.div`
  background: ${palette.greyDark05};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  display: flex;
  justify-content: flex-end;
`;

const relativeBelow = css`
  box-sizing: border-box;
  align-items: center;
  height: auto;
  width: 70vw;
  margin: 50px ${spacings.s};
  padding: ${spacings.l};
  border-bottom-left-radius: ${borderRadiuses.sm};
  border-bottom-right-radius: ${borderRadiuses.sm};
`;

const fullHeightRight = css`
  height: 100vh;
  width: 90vw;
`;

const fullWidthBottomFullHeight = css`
  height: 100%;
  width: 100vw;
  border-top-left-radius: ${borderRadiuses.l};
  border-top-right-radius: ${borderRadiuses.l};
  overflow: scroll;
`;

const fullWidthCenter = css`
  height: auto;
  width: 100vw;
`;

const fullWidthBottom = css`
  height: fit-content;
  width: 100vw;
`;

const styleMap: { [key in ContentPlacing]: FlattenSimpleInterpolation } = {
  relativeBelow: relativeBelow,
  fullHeightRight: fullHeightRight,
  fullWidthBottomFullHeight: fullWidthBottomFullHeight,
  fullWidthCenter: fullWidthCenter,
  fullWidthBottom: fullWidthBottom,
};

const ModalHeader = styled.div<{ withBackground?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadiuses.l};
  border-top-right-radius: ${borderRadiuses.l};
  background-color: ${(p) => (p.withBackground ? palette.gold : "transparent")};
`;

const ModalHeading = styled(H4Decor)`
  text-transform: capitalize;
  text-align: center;
  padding-left: ${spacings.xs};
  margin: ${spacings.m} 0;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const ModalContent = styled.div<{
  contentPlacing: ContentPlacing;
  $transparentBackground?: boolean;
}>`
  ${(props) => styleMap[props.contentPlacing]}
  background-color: ${(props) =>
    props.$transparentBackground ? "transparent" : palette.white};
`;

const CloseModalButton = styled.img.attrs({ src: closeIcon })<{
  goldBackground?: boolean;
}>`
  cursor: pointer;
  position: absolute;
  padding: ${spacings.sm};
  top: 3px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: ${(p) => (p.goldBackground ? palette.gold : palette.white)};
  border-radius: 50%;
  z-index: 3;
`;

const Footer = styled.div`
  background-color: ${palette.grey800};
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
`;

export {
  ModalContent,
  Background,
  ModalHeader,
  ModalHeading,
  CloseModalButton,
  Footer,
};
