import { format } from "date-fns";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { ROUTES } from "../../../routes";
import { RootState, useAppDispatch } from "../../../store";
import {
  placeBet,
  resetPlacingBetStatus,
  selectIsPlacingBetStatusFulfilled,
  selectIsPlacingBetStatusPending,
} from "../../../store/betsSlice";
import {
  selectBetsCountForGameId,
  selectCart,
  selectGamesSelectedForBetting,
} from "../../../store/cartSlice";
import { selectGameDetails } from "../../../store/gamesSlice";
import { GameDetails } from "../../../types/games";
import { formatCurrency } from "../../../utils/currency";
import { FooterButtonSmall } from "../../atoms/Button/Button.style";
import { LoadingAnimation } from "../../atoms/LoadingAnimation";
import { TeamLabel } from "../../atoms/Team";
import { Ticket } from "../../atoms/Ticket";
import { H2 } from "../../atoms/Typography";
import { Footer } from "../../molecules/Modal/Modal.style";
import * as S from "./BuySquareSummary.style";

interface BuySquareSummaryProps {
  totalWagerAmount: string;
  wagerAmount: string;
  handleStepBack: (totalAmount: string) => void;
}

const BuySquareSummary = ({
  totalWagerAmount,
  wagerAmount,
  handleStepBack,
}: BuySquareSummaryProps) => {
  const { t } = useTranslation("buySquare");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isPending = useSelector(selectIsPlacingBetStatusPending);
  const isFulfilled = useSelector(selectIsPlacingBetStatusFulfilled);
  const totalWagerAmountFormatted = formatCurrency(parseInt(totalWagerAmount));
  const wagerAmountFormatted = formatCurrency(parseInt(wagerAmount));
  const gamesIds = useSelector(selectGamesSelectedForBetting);

  const cart = useSelector(selectCart);

  const handleBuySquare = () => {
    dispatch(placeBet({ data: cart }));
  };

  useEffect(() => {
    if (isFulfilled) {
      navigate(ROUTES.games);
    }
  }, [isFulfilled]);

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      dispatch(resetPlacingBetStatus());
    };
  }, []);

  return gamesIds.length > 0 ? (
    <>
      <S.SummaryWrapper>
        <S.Wrapper>
          <S.Heading>{t("squaresDetails")}</S.Heading>
          {gamesIds.map((id) => {
            const { startTime, teamAway, teamHome } = useSelector<RootState>(
              (state) => selectGameDetails(state, id)
            ) as GameDetails;

            const squaresNumber = useSelector<RootState>((state) =>
              selectBetsCountForGameId(state, id)
            ) as number;
            return (
              <S.GameWrapper key={id}>
                <S.GameDate>
                  {format(new Date(startTime), "EEEE M/dd")}
                  <S.Separator>|</S.Separator>
                  {format(new Date(startTime), "p")}
                </S.GameDate>
                <S.DetailsWrapper>
                  <S.TeamsWrapper>
                    <TeamLabel abbreviated team={teamHome} />
                    <S.VersusLabel>vs</S.VersusLabel>
                    <TeamLabel abbreviated team={teamAway} />
                  </S.TeamsWrapper>
                  <S.WagerDetails>
                    <S.WagerDetailsRow>
                      <S.Label>
                        <S.LabelText>{t("wagerPerSquare")}</S.LabelText>
                      </S.Label>
                      <H2>{wagerAmountFormatted}</H2>
                    </S.WagerDetailsRow>
                    <S.WagerDetailsRow>
                      <S.Label>
                        <S.LabelText>{t("totalSquaresNumber")}</S.LabelText>
                      </S.Label>
                      <H2>{squaresNumber}</H2>
                    </S.WagerDetailsRow>
                  </S.WagerDetails>
                </S.DetailsWrapper>
              </S.GameWrapper>
            );
          })}
        </S.Wrapper>
        <Ticket
          amountFormatted={totalWagerAmountFormatted}
          label={t("myWager")}
          $horizontal
        />
      </S.SummaryWrapper>
      <Footer>
        <FooterButtonSmall
          secondary
          onClick={() => handleStepBack(totalWagerAmount)}
          id="go-back-button"
        >
          {t("general:goBack")}
        </FooterButtonSmall>
        <FooterButtonSmall
          disabled={isPending}
          pending={isPending}
          onClick={handleBuySquare}
          id="buy-square-button"
        >
          {isPending ? (
            <>
              <span>{t("general:processing")}</span>
              <LoadingAnimation spinner />
            </>
          ) : (
            <span>
              {t("payButtonTotal", {
                totalAmount: totalWagerAmountFormatted,
              })}
            </span>
          )}
        </FooterButtonSmall>
      </Footer>
    </>
  ) : (
    <LoadingAnimation label={t("loadingData")} />
  );
};

export { BuySquareSummary };
