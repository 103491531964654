import { memo } from "react";

import { useAppDispatch } from "../../../store";
import {
  fetchFilteredBucketByIndex,
  fetchFilteredBucketByScore,
} from "../../../store/bucketsSlice";
import { BetWithOrderIndex } from "../../../types/bets";
import { GameDetails, GameStatus } from "../../../types/games";
import { getIcon } from "../../atoms/Team";
import { H5Decor } from "../../atoms/Typography";
import * as S from "./Board.style";

const AWAY_NUMBERS_DELAY_BASE = 11;

const BoardCell = memo(
  ({
    mySquaresIds,
    hasSquares = mySquaresIds.length > 0,
    onClick,
    quarterWinner,
    id,
  }: {
    mySquaresIds: number[];
    hasSquares?: boolean;
    onClick?: () => void;
    quarterWinner?: string[];
    id?: string;
  }) => {
    return (
      <S.BoardCell
        isMySquare={hasSquares}
        onClick={onClick}
        winnerSquareQuarters={quarterWinner || []}
        id={id}
      >
        {hasSquares && <H5Decor>{`#${mySquaresIds}`}</H5Decor>}
      </S.BoardCell>
    );
  }
);

const HomeHead = ({
  game,
  revealed,
}: {
  game: GameDetails;
  revealed: boolean;
}) => {
  return (
    <thead>
      <S.Row>
        <S.HeadCell>
          <S.Teams
            teamHome={getIcon(game.teamHome)}
            teamAway={getIcon(game.teamAway)}
          />
        </S.HeadCell>
        {game.homeScoresOrder
          ? game.homeScoresOrder.map((homeValue, i) => (
              <S.HeadCell key={i} order={revealed ? 1 : i + 1}>
                {homeValue}
              </S.HeadCell>
            ))
          : [...Array(10)].map((e, i) => <S.HeadCell key={i} order={1} />)}
      </S.Row>
    </thead>
  );
};

const BoardRow = memo(
  ({
    awayRowValue,
    awayRowOrder,
    bets,
    game,
    onCellClick,
  }: {
    awayRowValue: number;
    awayRowOrder: number;
    bets: BetWithOrderIndex[];
    game: GameDetails;
    onCellClick?: (homeScore: number, awayScore: number) => void;
  }) => {
    const squaresPositions: {
      homeScore: number;
      awayScore: number;
      id: number;
    }[] = bets.map((bet, index) => {
      return {
        homeScore: bet.homeScore,
        awayScore: bet.awayScore,
        id: index + 1,
      };
    });

    const squaresPositionsIndex: {
      homeIndex: number;
      awayIndex: number;
      id: number;
    }[] = bets.map((bet, index) => {
      return {
        homeIndex: bet.homeIndex,
        awayIndex: bet.awayIndex,
        id: index + 1,
      };
    });

    const extractRightmostDigit = (num: number) => {
      return num % 10;
    };

    return (
      <S.Row>
        <S.HeadCell order={awayRowOrder}>
          {game.homeScoresOrder && awayRowValue}
        </S.HeadCell>
        {game.homeScoresOrder
          ? game.homeScoresOrder.map((homeColumnValue, i) => {
              const mySquaresInCell = squaresPositions.filter(
                (position) =>
                  awayRowValue === position.awayScore &&
                  homeColumnValue === position.homeScore
              );

              const ids = mySquaresInCell.map((square) => square.id);

              const handleCellClick = () => {
                onCellClick(homeColumnValue, awayRowValue);
              };

              const determineQuarterForCell = (
                homeScore: number,
                awayScore: number
              ): string[] => {
                const quartersForCell: string[] = [];
                const isGameFinished = game.status === GameStatus.Finished;

                for (let i = 1; i <= 3; i++) {
                  const homeQuarterScore = game[`quarter${i}HomeScore`];
                  const awayQuarterScore = game[`quarter${i}AwayScore`];

                  if (
                    extractRightmostDigit(homeQuarterScore) === homeScore &&
                    extractRightmostDigit(awayQuarterScore) === awayScore
                  ) {
                    quartersForCell.push(`q${i}`);
                  }
                }

                if (
                  extractRightmostDigit(game.finalQuarterHomeScore) ===
                    homeScore &&
                  extractRightmostDigit(game.finalQuarterAwayScore) ===
                    awayScore
                ) {
                  quartersForCell.push("final");
                }

                return isGameFinished ? quartersForCell : [];
              };

              const quartersForCell = determineQuarterForCell(
                homeColumnValue,
                awayRowValue
              );

              return (
                <BoardCell
                  key={i}
                  mySquaresIds={ids}
                  onClick={handleCellClick}
                  quarterWinner={quartersForCell}
                  id={`game-${game.id}-boardCell-${homeColumnValue}-${awayRowValue}`}
                />
              );
            })
          : [...Array(10)].map((e, i) => {
              const mySquaresInCell = squaresPositionsIndex.filter(
                (position) =>
                  i === position.homeIndex &&
                  awayRowValue === position.awayIndex
              );

              const ids = mySquaresInCell.map((square) => square.id);

              const handleCellClick = () => {
                onCellClick(i, awayRowValue);
              };

              return (
                <BoardCell
                  key={i}
                  mySquaresIds={ids}
                  onClick={handleCellClick}
                />
              );
            })}
      </S.Row>
    );
  }
);

interface BoardProps {
  bets: BetWithOrderIndex[];
  game: GameDetails;
  revealed: boolean;
  onBucketSelect?: (homeScore: number, awayScore: number) => void;
}

const Board = ({ bets, game, revealed, onBucketSelect }: BoardProps) => {
  const dispatch = useAppDispatch();
  const handleScoreCellClick = (homeScore: number, awayScore: number) => {
    dispatch(
      fetchFilteredBucketByScore({ gameId: game.id, homeScore, awayScore })
    );
    onBucketSelect && onBucketSelect(homeScore, awayScore);
  };

  const handleIndexCellClick = (homeIndex: number, awayIndex: number) => {
    dispatch(
      fetchFilteredBucketByIndex({ gameId: game.id, homeIndex, awayIndex })
    );
    onBucketSelect && onBucketSelect(homeIndex, awayIndex);
  };

  return (
    <S.BoardWrapper>
      <S.Board>
        <HomeHead game={game} revealed={revealed} />
        <tbody>
          {game.awayScoresOrder
            ? game.awayScoresOrder.map((awayRowValue, i) => (
                <BoardRow
                  key={i}
                  bets={bets}
                  awayRowValue={awayRowValue}
                  awayRowOrder={revealed ? 1 : i + AWAY_NUMBERS_DELAY_BASE}
                  game={game}
                  onCellClick={handleScoreCellClick}
                />
              ))
            : [...Array(10)].map((e, i) => (
                <BoardRow
                  key={i}
                  bets={bets}
                  awayRowValue={i}
                  awayRowOrder={1}
                  game={game}
                  onCellClick={handleIndexCellClick}
                />
              ))}
        </tbody>
      </S.Board>
    </S.BoardWrapper>
  );
};

export { Board };
