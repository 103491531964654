import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation(["login", "home"]);

  return (
    <>
      <div>{t("title")}</div>
      <button onClick={() => loginWithRedirect()}>
        {t("home:loginLabel")}
      </button>
    </>
  );
};

export { Login };
