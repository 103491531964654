import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { elementSizes, spacings } from "../../../assets/styles/sizes";
import {
  NotificationPosition,
  NotificationType,
} from "../../../types/notification";

interface NotificationWrapperProps {
  type: NotificationType;
  position: NotificationPosition;
}

const NotificationWrapper = styled.div<NotificationWrapperProps>`
  position: fixed;
  animation: movein 7s ease forwards;
  left: 0;
  right: 0;
  bottom: ${(p) =>
    p.position === NotificationPosition.BOTTOM && elementSizes.bottomArea};
  top: ${(p) =>
    p.position === NotificationPosition.TOP && elementSizes.topMenu};
  max-height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) =>
    p.type === NotificationType.ERROR ? palette.red : palette.green};
  color: ${(p) =>
    p.type === NotificationType.ERROR ? palette.white : palette.blue400};
  padding: ${spacings.sm} ${spacings.xs};
  z-index: 4;

  @keyframes movein {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const CloseButton = styled.button`
  border: none;
  background-color: transparent;
`;

export { NotificationWrapper, CloseButton };
