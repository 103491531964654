import styled from "styled-components";
import { Link } from "react-router-dom";

import { elementSizes, spacings } from "../../assets/styles/sizes";
import { H2 } from "../../components/atoms/Typography";
import { Icon } from "../../components/atoms/Icon";
import { grey300, grey400 } from "../../assets/styles/colors";
import { buttonGeneralStyles } from "../../components/atoms/Button/Button.style";

const Wrapper = styled.div`
  background-color: ${grey300};
  height: calc(100vh - ${elementSizes.topMenu});
  padding: ${spacings.xl} ${spacings.xs};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled(H2)`
  margin-bottom: ${spacings.xs};
  text-align: center;

  &::first-letter {
    text-transform: uppercase;
  }
`;

const Button = styled(Link)`
  ${buttonGeneralStyles}
  background-color: ${grey400};
  margin-top: ${spacings.l};
  padding: ${spacings.xs} ${spacings.m};
`;

const ArrowIcon = styled(Icon)`
  margin-left: ${spacings.xs};
`;

export { Wrapper, Heading, Button, ArrowIcon };
