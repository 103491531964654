import { useTranslation } from "react-i18next";

import footballOnStand from "../../../assets/icons/football-on-stand.svg";
import football from "../../../assets/icons/football.svg";
import helmet from "../../../assets/icons/helmet.svg";
import shield from "../../../assets/icons/shield.svg";
import whistle from "../../../assets/icons/whistle.svg";
import { blue500 } from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { GamePart, GameStatus } from "../../../types/games";
import { IconColor } from "../IconColor";
import * as S from "./GameStatusLabel.style";

interface GameStatusLabelProps {
  status: GameStatus;
  isGameModal?: boolean;
  quarter?: GamePart;
  timeLeft?: string;
}

const GameStatusLabel = ({
  status,
  isGameModal = false,
}: GameStatusLabelProps) => {
  const { t } = useTranslation("game");

  const iconDisplayed =
    status !== GameStatus.Canceled && status !== GameStatus.Scheduled;

  const iconMap = () => {
    switch (status) {
      case GameStatus.OpenForBets:
        return shield;
      case GameStatus.PreDraw:
        return whistle;
      case GameStatus.PreGame:
        return helmet;
      case GameStatus.Live:
        return football;
      case GameStatus.Finished:
        return footballOnStand;
    }
  };

  return (
    <S.GameStatusLabelWrapper status={status} isGameModal={isGameModal}>
      <S.LabelText isGameModal={isGameModal}>{t(`${status}`)}</S.LabelText>
      {iconDisplayed && (
        <IconColor
          src={iconMap()}
          color={blue500}
          $size={isGameModal ? "18px" : "16px"}
          $marginLeft={isGameModal ? spacings.s : spacings.xxs}
        />
      )}
    </S.GameStatusLabelWrapper>
  );
};

export { GameStatusLabel };
