import { BetWithId } from "./bets";
import { TeamAttributes } from "./teams";

const quarters = ["1", "2", "3", "4"] as const;
type GameQuarters = (typeof quarters)[number];
type GamePartsForPayout = GameQuarters | "final";
type GamePart = GameQuarters | "Half" | "OT" | "F" | "F/OT" | "NULL";
const GAME_PARTS: GamePart[] = ["1", "2", "3", "4", "OT"];

enum GameStatus {
  Scheduled = "scheduled",
  OpenForBets = "open_for_bets",
  PreDraw = "pre_draw",
  PreGame = "pre_game",
  Live = "live",
  Finished = "finished",
  Canceled = "canceled",
}

type SeasonPhase = "pre" | "regular" | "post" | "unset";

enum TvStation {
  Fox = "FOX",
  Nfln = "NFLN",
  Cbs = "CBS",
  Nbc = "NBC",
  Amazon = "AMZN",
  Abc = "ABC",
  Espn = "ESPN",
}

interface PoolAttributes {
  totalPoolValue: string;
  averageBucketSize: string;
}

interface PoolAttributesFormatted {
  totalPoolValueFormatted: string;
  averageBucketSizeFormatted: string;
}
interface GameAttributes extends PoolAttributes {
  gameKey: string;
  week: number | null;
  seasonPhase: SeasonPhase;
  startTime: string;
  tvStation: TvStation | null;
  stadium: string;
  status: GameStatus;
  nextPhaseStartTime: string;
  scoresRevealedByCurrentUser: boolean;
  homeScore?: number;
  quarter1HomeScore?: number;
  quarter2HomeScore?: number;
  quarter3HomeScore?: number;
  finalQuarterHomeScore?: number;
  quarter1AwayScore?: number;
  quarter2AwayScore?: number;
  quarter3AwayScore?: number;
  finalQuarterAwayScore?: number;
  awayScore?: number;
  awayScoresOrder?: number[];
  homeScoresOrder?: number[];
  timeLeft?: string;
  quarter?: GamePart;
}

interface GameTeams {
  teamHome: TeamAttributes;
  teamAway: TeamAttributes;
}

type GameAttrsWithTeamsAndBets<G> = G &
  GameAttributes & {
    id: string;
    teamHome: TeamAttributes;
    teamAway: TeamAttributes;
    hasBets: boolean;
    betsCount: number;
  };

interface GameMetaInfo {
  id: string;
  isBeforeGame: boolean;
  isOpenForBets: boolean;
  hasBets: boolean;
  bets?: BetWithId[];
  hasWinningInGame: boolean;
  totalWinningInGame?: number;
  totalWinningInGameFormatted?: string;
  totalInvestment?: number;
  displayBoardDrawing?: boolean;
  displayBucketBetters?: boolean;
  displayTimer?: boolean;
  isLive?: boolean;
  betsSorted?: BetWithId[];
  maxPotentialPayoutFormatted?: string;
}

interface GameDetails
  extends GameAttributes,
    GameMetaInfo,
    PoolAttributesFormatted,
    GameTeams {}

interface GameLiveUpdate {
  gameKey: string;
  homeScore: number;
  quarter1HomeScore: number;
  quarter2HomeScore: number;
  quarter3HomeScore: number;
  finalQuarterHomeScore: number;
  awayScore: number;
  quarter1AwayScore: number;
  quarter2AwayScore: number;
  quarter3AwayScore: number;
  finalQuarterAwayScore: number;
  quarter: GamePart;
  timeLeft: string;
  scoringScenarios: {
    [scoreCombination: string]: { description: string; probability: number };
  };
}

export type {
  SeasonPhase,
  GameAttributes,
  GameMetaInfo,
  GamePartsForPayout,
  GameDetails,
  PoolAttributesFormatted,
  GameAttrsWithTeamsAndBets,
  GameTeams,
  GameLiveUpdate,
  GamePart,
};
export { GameStatus, GAME_PARTS, TvStation };
