import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { apiClient } from "./api/apiClient";
import { auth } from "./api/auth";
import NotificationRenderer from "./components/atoms/Notification/NotificationRenderer";
import { PhoneModal } from "./components/molecules/PhoneModal";
import { TopMenu } from "./components/organisms/TopMenu";
import { GameProvider } from "./context/GameContext";
import { BuySquareWizard } from "./pages/BuySquareWizard";
import { Games } from "./pages/Games";
import { Login } from "./pages/Login";
import { MyProfile } from "./pages/MyProfile";
import { NoMatch } from "./pages/NoMatch";
import { PrivateRoute } from "./pages/PrivateRoute";
import { Transactions } from "./pages/Transactions";
import { ROUTES } from "./routes";
import { useAppDispatch } from "./store";
import { fetchCurrentWeekGames } from "./store/gamesSlice";
import { triggerNotification } from "./store/notificationSlice";
import { fetchUser } from "./store/userSlice";
import { fetchBalance } from "./store/walletSlice";
import { NotificationPosition, NotificationType } from "./types/notification";

const AppRoutes = () => {
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0();
  const { t } = useTranslation("notification");

  auth.setAccessTokenSilently(getAccessTokenSilently);
  const dispatch = useAppDispatch();

  const isPhoneSaved = !!Cookies.get("phone");

  const isPhoneModalClosed = !!localStorage.getItem("phoneModalClosed");

  useEffect(() => {
    isAuthenticated &&
      (async () => {
        try {
          const token = await getAccessTokenSilently();
          apiClient.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };
          dispatch(fetchUser());
          dispatch(fetchBalance());
          dispatch(fetchCurrentWeekGames());
        } catch (e) {
          console.error(e);
          handleLogout();
        }
      })();
  }, [isAuthenticated]);

  const handleLogout = () => {
    setTimeout(() => {
      localStorage.removeItem("authToken");
      dispatch(
        triggerNotification({
          type: NotificationType.ERROR,
          message: t("authenticationError"),
          position: NotificationPosition.TOP,
        })
      );
      setTimeout(() => {
        logout();
      }, 3000);
    }, 3000);
  };

  return (
    <>
      <NotificationRenderer />
      {isAuthenticated && !isPhoneSaved && !isPhoneModalClosed && (
        <PhoneModal />
      )}
      <BrowserRouter>
        <GameProvider>
          <TopMenu />
          <Routes>
            <Route path={ROUTES.games} element={<Games />} />
            <Route path={ROUTES.login} element={<Login />} />
            <Route path={ROUTES.buySquare} element={<BuySquareWizard />} />
            <Route
              path={ROUTES.transactions}
              element={<PrivateRoute component={() => <Transactions />} />}
            />
            <Route
              path={ROUTES.myProfile}
              element={<PrivateRoute component={() => <MyProfile />} />}
            />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </GameProvider>
      </BrowserRouter>
    </>
  );
};

export { AppRoutes };
