import styled from "styled-components";

const DayFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
`;

export { DayFilterWrapper };
