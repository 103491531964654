import styled from "styled-components";

import { grey400 } from "../../../assets/styles/colors";
import {
  borderRadiuses,
  elementSizes,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { buttonGeneralStyles } from "../../atoms/Button/Button.style";
import { H1 } from "../../atoms/Typography";

const InvitationWrapper = styled.div`
  position: absolute;
  top: ${elementSizes.topMenu};
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: ${spacings.l};
  border-radius: ${borderRadiuses.m};
  width: 70vw;
  height: 100wh;
  background-color: ${grey400};
  z-index: 4;
`;

const CloseModalButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const Heading = styled(H1)`
  text-align: center;
  margin-top: ${spacings.m};
  &::first-letter {
    text-transform: uppercase;
  }
`;

const InvitationContent = styled.p`
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.l};
  text-align: justify;
  margin: ${spacings.m} 0;
`;

const CopyButton = styled.button`
  ${buttonGeneralStyles}
  width: 200px;
  height: 30px;
`;

export {
  InvitationWrapper,
  CloseModalButton,
  Heading,
  InvitationContent,
  CopyButton,
};
