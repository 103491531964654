import styled from "styled-components";

import {
  black,
  gold,
  grey300,
  grey800,
  white,
} from "../../../assets/styles/colors";
import { spacings } from "../../../assets/styles/sizes";
import { H4Decor } from "../Typography";

const LoadingSpinner = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid black;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin-left: ${spacings.s};
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1.5s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const LoadingAnimationBackground = styled.div<{
  fullScreen?: boolean;
  lightBackground?: boolean;
}>`
  position: ${(p) => (p.fullScreen ? "fixed" : "absolute")};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => (p.lightBackground ? grey300 : grey800)};
  opacity: 0.9;
  z-index: ${(p) => (p.fullScreen ? 2 : 1)};
`;

const LoadingAnimationWrapper = styled.div<{ fullScreen?: boolean }>`
  position: absolute;
  left: 50%;
  top: ${(p) => (p.fullScreen ? "50%" : "30%")};
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoadingAnimation = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: ${spacings.xxs};
  width: 6rem;
  height: 6rem;
`;

const Square = styled.span<{ order: number }>`
  background-color: ${gold};
  animation: whiten 2s infinite;

  @keyframes whiten {
    0% {
      background-color: ${white};
    }
    30% {
      background-color: ${gold};
    }
    100% {
      background-color: ${white};
    }
  }

  animation-delay: ${(p) => `${p.order / 3}s`};
`;

const Label = styled(H4Decor)<{ lightBackground?: boolean }>`
  margin-top: ${spacings.m};
  color: ${(p) => (p.lightBackground ? black : white)};
  font-weight: 400;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export {
  LoadingSpinner,
  LoadingAnimationBackground,
  LoadingAnimationWrapper,
  LoadingAnimation,
  Square,
  Label,
};
