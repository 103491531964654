import styled, { css } from "styled-components";

import * as palette from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { buttonText } from "../Typography";

interface ButtonStyleProps {
  $background?: string;
  $color?: string;
}

const buttonGeneralStyles = css`
  ${buttonText}
  text-transform: uppercase;
  color: ${palette.grey900};
  border: 1px solid ${palette.greyDark02};
  border-radius: ${borderRadiuses.s};
  background-color: ${palette.gold};
`;

const Button = styled.button<ButtonStyleProps>`
  ${buttonGeneralStyles}
  padding: ${spacings.sm};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OutlinedButton = styled.button<{
  active?: boolean;
  topButton?: boolean;
}>`
  font-family: "Space Grotesk";
  font-weight: 500;
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.m};
  text-transform: capitalize;
  padding: ${spacings.sm} ${spacings.s};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${borderRadiuses.sm};
  border: 1px solid
    ${(props) => (props.active ? palette.blue400 : palette.grey400)};
  background-color: ${(props) =>
    props.active ? palette.lightBlue05 : palette.white};
  color: ${(props) => (props.active ? palette.blue400 : palette.grey900)};
  margin-bottom: ${(props) => (props.topButton ? 0 : spacings.xxs)};
`;

const FooterButton = styled.button<{
  pending?: boolean;
}>`
  ${buttonGeneralStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${spacings.sm} 0;
  background-color: ${(props) =>
    props.pending ? palette.goldDark : palette.gold};
  border-radius: ${borderRadiuses.sm};
`;

const FooterButtonWide = styled(FooterButton)`
  margin: ${spacings.m} ${spacings.xl};
  width: 100%;
`;

const FooterButtonSmall = styled(FooterButton)<{ secondary?: boolean }>`
  background-color: ${(props) => props.secondary && palette.grey300};
  margin: ${spacings.s} 0;
  width: fit-content;

  &:first-child {
    flex-grow: 1;
    margin-left: ${spacings.s};
  }
  &:nth-child(2) {
    flex-grow: 2;
    margin-left: ${spacings.s};
    margin-right: ${spacings.s};
  }
`;

const TransparentButton = styled(Button)`
  background-color: transparent;
  border: none;
  padding: ${spacings.sm} 0;
`;

export {
  Button,
  buttonGeneralStyles,
  OutlinedButton,
  FooterButton,
  FooterButtonWide,
  FooterButtonSmall,
  TransparentButton,
};
