const showNotification = () => {
  navigator.serviceWorker.getRegistration().then((reg) => {
    reg.active.postMessage({
      type: "SHOW_NOTIFICATION",
      title: "Hello SquareShare!",
      body: "This is a test notification.",
      icon: "/src/assets/icons/logo.svg",
    });
  });
};

const pushNotification = () => {
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    showNotification();
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((perm) => {
      if (perm === "granted") {
        showNotification();
      } else {
        console.log("Permission to receive notifications has been denied");
      }
    });
  }
};

export { pushNotification };
