import styled from "styled-components";

import { gold, grey400 } from "../../../assets/styles/colors";
import {
  borderRadiuses,
  fontSizes,
  lineHeights,
  spacings,
} from "../../../assets/styles/sizes";
import { H5Decor } from "../Typography";

const WarningTileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacings.l};
`;

const WarningTileDecor = styled.div`
  border-radius: ${borderRadiuses.m} 0 0 ${borderRadiuses.m};
  background-color: ${gold};
  width: 10px;
`;

const WarningTileContent = styled.div`
  width: fit-content;
  padding: ${spacings.sm} ${spacings.xl} ${spacings.sm} ${spacings.l};
  border-radius: 0 ${borderRadiuses.m} ${borderRadiuses.m} 0;
  border: 1px solid ${grey400};
  border-left: none;
`;

const WarningTileTitle = styled(H5Decor)`
  font-weight: 700;
  margin-bottom: ${spacings.xxs};
`;

const WarningTileText = styled.span`
  font-size: ${fontSizes.sm};
  line-height: ${lineHeights.sm};
`;

const WarningTileTextBold = styled(WarningTileText)`
  font-weight: 700;
  margin: 0 ${spacings.xxs};
`;

export {
  WarningTileWrapper,
  WarningTileDecor,
  WarningTileContent,
  WarningTileTitle,
  WarningTileText,
  WarningTileTextBold,
};
