import { useTranslation } from "react-i18next";

import { weekOptions } from "../../../constants/weeks";
import { useAppDispatch } from "../../../store";
import { applyWeekFilter } from "../../../store/gameFiltersSlice";
import { SeasonPhase } from "../../../types/games";
import { OutlinedButton } from "../../atoms/Button/Button";
import { Modal } from "../Modal";
import { CloseModalButton, ModalHeading } from "../Modal/Modal.style";
import * as S from "./WeekFilterModal.style";

interface WeekFilterModalProps {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  selectedWeek: number;
  selectedSeasonPhase: SeasonPhase;
  onWeekSelect: (week: number) => void;
}

const WeekFilterModal = ({
  modalVisible,
  setModalVisible,
  selectedWeek,
  selectedSeasonPhase,
  onWeekSelect,
}: WeekFilterModalProps) => {
  const { t } = useTranslation(["games"]);
  const dispatch = useAppDispatch();

  const handleSelectWeekFilter = ({
    week,
    seasonPhase,
  }: {
    week: number;
    seasonPhase: string;
  }) => {
    dispatch(applyWeekFilter({ week, seasonPhase }));
    setModalVisible(false);
    onWeekSelect(week);
  };

  return (
    <Modal
      modalVisible={modalVisible}
      onModalClose={() => setModalVisible(false)}
      revealHorizontal={true}
    >
      <S.ModalWrapper>
        <S.SectionWrapper>
          <CloseModalButton
            aria-label={t("general:closeModal")}
            onClick={() => setModalVisible(false)}
            id="close-week-filter-modal-button"
          />
          <ModalHeading>{t("filterWeekHeading")}</ModalHeading>
          <S.ModalBody>
            {Object.entries(weekOptions).map(([seasonPhase, weeks]) => {
              return (
                <S.SeasonPhaseWrapper key={seasonPhase}>
                  {seasonPhase !== "unset" && (
                    <S.SeasonTitle>
                      {t("season", { seasonPhase: seasonPhase })}
                    </S.SeasonTitle>
                  )}
                  {weeks.map((week) => (
                    <OutlinedButton
                      active={
                        selectedWeek === week &&
                        selectedSeasonPhase === seasonPhase
                      }
                      id={`week-filter-button-${seasonPhase}-${week}`}
                      key={`${seasonPhase}-${week}`}
                      onClick={() =>
                        handleSelectWeekFilter({ week, seasonPhase })
                      }
                    >
                      {t("week", { weekNumber: week })}
                    </OutlinedButton>
                  ))}
                </S.SeasonPhaseWrapper>
              );
            })}
          </S.ModalBody>
        </S.SectionWrapper>
      </S.ModalWrapper>
    </Modal>
  );
};

export { WeekFilterModal };
