import * as S from "./DayFilterTab.style";

interface DayFilterTabProps {
  checked: boolean;
  weekDay: string;
  date: string;
  onChange: () => void;
  onClick: () => void;
}

const DayFilterTab = ({
  checked,
  weekDay,
  onChange,
  date,
  onClick,
}: DayFilterTabProps) => {
  const formattedWeekDay = weekDay.replace(/\s+/g, "-").toLowerCase();

  const handleWrapperClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <S.DayFilterTabWrapper active={checked} onClick={handleWrapperClick}>
      <S.Input
        type="radio"
        id={formattedWeekDay}
        name="dayFilter"
        checked={checked}
        onChange={onChange}
      />
      <S.Label htmlFor={formattedWeekDay}>
        <S.DayLabel>{weekDay}</S.DayLabel>
        <S.DateLabel>{date}</S.DateLabel>
      </S.Label>
    </S.DayFilterTabWrapper>
  );
};

export { DayFilterTab };
