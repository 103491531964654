import styled from "styled-components";

import {
  gradientYellow,
  gradientGrey,
  gradientRed,
  gradientGreen,
  gradientGold,
  grey800,
  white,
} from "../../../assets/styles/colors";

import { borderRadiuses, spacings } from "../../../assets/styles/sizes";
import { H2, H2Decor } from "../../atoms/Typography";

interface HeaderProps {
  header: string;
}

const GameBalanceTileWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  padding: ${spacings.m} ${spacings.m};
  background-color: ${white};
  align-items: center;
  border-radius: ${borderRadiuses.m};
  height: 52px;

  box-shadow: 0px 1px 2px 1px rgba(224, 224, 224, 0.7);
`;

const TileHeader = styled(H2)<HeaderProps>`
  text-transform: uppercase;
  color: ${grey800};
  font-weight: 500;
  font-weight: ${(props) =>
    props.header === "netGain" || props.header === "netLoss"
      ? "500"
      : "normal"};
`;

const TileValueWrapper = styled.div<HeaderProps>`
  display: grid;
  height: 100%;
  background: ${(props) => {
    switch (props.header) {
      case "totalWinnings":
        return gradientGold;
      case "totalInvestment":
        return gradientGrey;
      case "netLoss":
        return gradientRed;
      case "netGain":
        return gradientGreen;
      default:
        return gradientYellow;
    }
  }};
  border-radius: ${borderRadiuses.m};
  align-items: center;
`;

const TileValue = styled(H2Decor)`
  padding-right: ${spacings.m};
  color: ${white};
  text-align: right;
`;

export { GameBalanceTileWrapper, TileHeader, TileValue, TileValueWrapper };
