import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import chevronBottom from "../../../assets/icons/chevron-bottom.svg";
import { ROUTES } from "../../../routes";
import { RootState, useAppDispatch } from "../../../store";
import {
  selectBetsCountForGameId,
  selectCartItemsCount,
  updateGamesSelectedForBetting,
} from "../../../store/cartSlice";
import { selectGameDetails } from "../../../store/gamesSlice";
import { GameDetails } from "../../../types/games";
import { Icon } from "../../atoms/Icon";
import { GameRow } from "../../molecules/GameRow";
import * as S from "./MultipleSquaresForMultipleGamesModal.style";

interface MultipleSquaresForMultipleGamesModalProps {
  gameIds: string[];
  onModalClose: () => void;
}

const MultipleSquaresForMultipleGamesModal = ({
  gameIds,
  onModalClose,
}: MultipleSquaresForMultipleGamesModalProps) => {
  const { t } = useTranslation("buySquare");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cartSquareCount = useSelector(selectCartItemsCount);
  const gamesCount = gameIds.length;

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    dispatch(updateGamesSelectedForBetting());
    navigate(ROUTES.buySquare);
  };

  return (
    <>
      <S.ModalWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.HeadingWrapper>
            <S.ButtonWrapper onClick={onModalClose}>
              <S.HeadingText>
                {cartSquareCount === 1
                  ? `${t("modalHeadingSingleBet", {
                      cartSquareCount: cartSquareCount,
                    })} /
                  ${gamesCount} ${t("general:game")} `
                  : `${t("modalHeadingMultipleBet", {
                      cartSquareCount: cartSquareCount,
                    })} / ${gamesCount} ${t("general:games")}`}
              </S.HeadingText>
              <Icon src={chevronBottom} width="20px" />
            </S.ButtonWrapper>
          </S.HeadingWrapper>
          {gameIds.map((id) => {
            const { startTime, teamAway, teamHome } = useSelector<RootState>(
              (state) => selectGameDetails(state, id)
            ) as GameDetails;
            const betsCount = useSelector<RootState>((state) =>
              selectBetsCountForGameId(state, id)
            ) as number;
            return (
              <GameRow
                key={id}
                id={id}
                betsCount={betsCount}
                startTime={startTime}
                teamHome={teamHome}
                teamAway={teamAway}
              />
            );
          })}
          <S.ModalFooter>
            <S.ModalButton id="multiple-bets-modal-button" type="submit">
              {t("continue")}
            </S.ModalButton>
          </S.ModalFooter>
        </form>
      </S.ModalWrapper>
    </>
  );
};

export { MultipleSquaresForMultipleGamesModal };
