interface ApiEndpoints {
  readonly getGames: string;
  readonly getWalletBalance: string;
  readonly createPayment: string;
  readonly confirmPayment: string;
  readonly cancelPayment: string;
  readonly createBet: string;
  readonly revealBet: (id: string) => string;
  readonly getFundsTransfers: string;
  readonly updateRevealedGames: string;
  readonly user: string;
  readonly getBuckets: (gameId: string) => string;
  readonly getFilteredBucketByScore: (
    gameId: string,
    awayScore: number,
    homeScore: number
  ) => string;
  readonly getFilteredBucketByIndex: (
    gameId: string,
    awayIndex: number,
    homeIndex: number
  ) => string;
}

export const ENDPOINTS: ApiEndpoints = {
  getGames: "games",
  getWalletBalance: "/wallet",
  createPayment: "/payments",
  confirmPayment: "/payments/confirm",
  cancelPayment: "/payments/cancel",
  createBet: "/bets",
  revealBet: (id) => `/bets/${id}`,
  getFundsTransfers: "/funds_transfers",
  updateRevealedGames: "/users/update_revealed_games",
  user: "/user",
  getBuckets: (gameId) => `/games/${gameId}/buckets`,
  getFilteredBucketByScore: (gameId, homeScore, awayScore) =>
    `/games/${gameId}/buckets?bucket[home_score]=${homeScore}&bucket[away_score]=${awayScore}&include=betters`,
  getFilteredBucketByIndex: (gameId, homeIndex, awayIndex) =>
    `/games/${gameId}/buckets?bucket[home_index]=${homeIndex}&bucket[away_index]=${awayIndex}&include=betters`,
};
