import { GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { AxiosRequestConfig } from "axios";
import { apiClient } from "./apiClient";
import { access } from "fs";

let getAccessTokenSilently: (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string>;

export const auth = {
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func),
};

export const getAuthHeader = async (): Promise<
  { Authorization: string } | undefined
> => {
  try {
    const accessToken = await auth.setAccessTokenSilently(
      getAccessTokenSilently
    )();

    if (accessToken) {
      const authHeader = { Authorization: `Bearer ${accessToken}` };

      // Set the refreshed token in axios defaults
      apiClient.defaults.headers.common = authHeader;
      return authHeader;
    }
  } catch (error) {
    console.log("Login required to get an access token");
  }
};
