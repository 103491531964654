import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";

import App from "./App";
import GlobalStyle from "./assets/styles/global-styles";
import "./i18n";

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
  auth: import.meta.env.VITE_GTM_AUTH,
  preview: import.meta.env.VITE_GTM_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <>
    <GlobalStyle />
    <App />
  </>
);
