import { useTranslation } from "react-i18next";

import arrow from "../../assets/icons/arrow-right.svg";
import { ROUTES } from "../../routes";
import * as S from "./NoMatch.style";

const NoMatch = () => {
  const { t } = useTranslation("noMatch");

  return (
    <S.Wrapper>
      <S.Heading>{t("heading")}</S.Heading>
      <S.Heading>{t("subheading")}</S.Heading>
      <S.Button to={ROUTES.games}>
        {t("button")} <S.ArrowIcon src={arrow} />
      </S.Button>
    </S.Wrapper>
  );
};

export { NoMatch };
