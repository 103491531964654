import styled from "styled-components";

import * as palette from "../../../assets/styles/colors";
import { elementSizes, spacings } from "../../../assets/styles/sizes";
import { TransparentButton } from "../../atoms/Button/Button.style";

const Wrapper = styled.div`
  height: ${elementSizes.topMenu};
  background-color: ${palette.grey800};
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 2px 2px 2px 2px ${palette.greyDark02};
`;

const LogoButton = styled(TransparentButton)`
  justify-content: start;
`;

const Content = styled.div<{ threeSections: boolean }>`
  display: ${(props) => (props.threeSections ? "grid" : "flex")};
  grid-template-columns: ${(props) =>
    props.threeSections ? "1fr auto 1fr" : ""};
  align-items: center;
  justify-content: space-between;
  padding: 0 ${spacings.xs};
`;

const LogoAndNotificationSection = styled.div`
  display: flex;
  align-items: center;
`;

const NotificationButton = styled(TransparentButton)`
  margin-left: ${spacings.m};
`;

const CartAndUserSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export {
  Wrapper,
  LogoButton,
  Content,
  LogoAndNotificationSection,
  NotificationButton,
  CartAndUserSection,
};
