import { camelizeKeys, decamelizeKeys } from "humps";
import pluralize from "pluralize";

export interface JsonApiResponse<
  MainResourceAttributes,
  RelatedResourceAttributes
> {
  data:
    | JsonApiResource<MainResourceAttributes>
    | JsonApiResource<MainResourceAttributes>[];
  included: JsonApiResource<RelatedResourceAttributes>[];
  jsonapi: { version: string };
  meta?: any;
}

interface JsonApiRelationship {
  type: string;
  id: string;
}

interface JsonApiRelationshipsObject {
  data: {
    [objectClassName: string]: JsonApiRelationship;
  };
}

interface JsonApiResource<ResourceAttributes> {
  id: string;
  type: string;
  attributes: ResourceAttributes;
  relationships?: JsonApiRelationshipsObject;
  links?: any;
  meta?: any;
}

export interface DeserializedJsonApiResource<ResourceAttributes>
  extends Omit<
    JsonApiResource<ResourceAttributes>,
    "relationships" | "meta" | "links"
  > {
  relationships?: { [relatedObjectName: string]: string[] };
}

type DeserializedJsonApiResponseEntities<
  MainResourceAttributes,
  RelatedResourceAttributes
> = {
  [objectClassName: string]: {
    [id: string]:
      | DeserializedJsonApiResource<MainResourceAttributes>
      | DeserializedJsonApiResource<RelatedResourceAttributes>;
  };
};

export type DeserializedJsonApiResponse<
  MainResourceAttributes,
  RelatedResourceAttributes
> = {
  entities: {
    [objectClassName in keyof DeserializedJsonApiResponseEntities<
      MainResourceAttributes,
      RelatedResourceAttributes
    >]: DeserializedJsonApiResponseEntities<
      MainResourceAttributes,
      RelatedResourceAttributes
    >[objectClassName];
  };
  order: string[];
  meta?: any;
};

function deserialize<MainResourceAttributes, RelatedResourceAttributes>(
  response: JsonApiResponse<MainResourceAttributes, RelatedResourceAttributes>
): DeserializedJsonApiResponse<
  MainResourceAttributes,
  RelatedResourceAttributes
> {
  if (typeof response.data !== "object") {
    return;
  }
  const mainData = response.data;
  const includedData = response.included || [];
  const deserializedData: DeserializedJsonApiResponse<
    MainResourceAttributes,
    RelatedResourceAttributes
  >["entities"] = {};

  const order: DeserializedJsonApiResponse<
    MainResourceAttributes,
    RelatedResourceAttributes
  >["order"] = [];

  const mainObjectsArray = Array.isArray(mainData) ? mainData : [mainData];

  mainObjectsArray.forEach((mainObject) => {
    const { type, id: mainObjectId, attributes, relationships } = mainObject;
    order.push(mainObjectId);

    deserializedData[type] = deserializedData[type] || {};
    deserializedData[type][mainObjectId] = {
      type,
      attributes,
      id: mainObjectId,
    };

    if (relationships) {
      const relationshipsObject: {
        [type: string]: string[];
      } = {};

      const relationshipsToMainObject = Object.entries(relationships);
      relationshipsToMainObject.map(([relObjectType, relObjectData]) => {
        const relationshipObjectData = relObjectData.data;
        const relationshipObjectType = relObjectType;
        const relationshipObjectDataObjects = Array.isArray(
          relationshipObjectData
        )
          ? relationshipObjectData
          : [relationshipObjectData];
        const relatedObjIds: string[] = relationshipObjectDataObjects.map(
          (relatedObject) => relatedObject.id
        );

        relationshipsObject[relationshipObjectType] = relatedObjIds;
      });

      deserializedData[type][mainObjectId] = {
        ...deserializedData[type][mainObjectId],
        relationships: relationshipsObject,
      };
    }
  });

  includedData.forEach((includedObject) => {
    const { type, id, attributes } = includedObject;
    deserializedData[type] = deserializedData[type] || {};
    deserializedData[type][id] = { attributes, id, type };
  });

  const metaCamelized = response.meta && camelizeKeys(response.meta);

  const camelizedDeserialized = camelizeKeys(
    deserializedData
  ) as DeserializedJsonApiResponse<
    MainResourceAttributes,
    RelatedResourceAttributes
  >["entities"];

  return { entities: camelizedDeserialized, order, meta: metaCamelized };
}

interface SingleResourcePayloadSerialized<ResourceAttributes> {
  data: {
    type: string;
    attributes: ResourceAttributes;
    relationships?: {
      [relatedObjectName: string]: JsonApiRelationshipsObject;
    };
  };
}

const serializeSingleResource = <ResourceAttributes>(data: {
  type: string;
  attributes: ResourceAttributes;
  id?: string;
  relationships?: { [relatedObjectName: string]: string };
}): SingleResourcePayloadSerialized<ResourceAttributes> => {
  const { type, attributes, relationships, id } = data;

  const resource = {
    type: pluralize(type),
    attributes: attributes,
  };
  const resourcePayload = id ? { ...resource, id } : resource;

  let payload;

  if (relationships) {
    const [relatedObjectName, relatedObjectId] =
      Object.entries(relationships)[0];
    const relationshipsData = {
      [relatedObjectName]: {
        data: { type: pluralize(relatedObjectName), id: relatedObjectId },
      },
    };
    payload = {
      data: { ...resourcePayload, relationships: relationshipsData },
    };
  } else {
    payload = { data: resourcePayload };
  }

  return decamelizeKeys(
    payload
  ) as SingleResourcePayloadSerialized<ResourceAttributes>;
};

export { deserialize, serializeSingleResource };
export type { SingleResourcePayloadSerialized };
